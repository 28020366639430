import { SERVICE } from '@src/constants';
import api from './api';

export const createBanner = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/admin/banners',
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getBanner = async (id) => {
  const res = await api({
    method: 'GET',
    url: `/admin/banners/${id}`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const updateBanner = async (id, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/banners/${id}`,
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
