import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Chip,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';

import apis from '@src/apis';
import debounce from '@src/utils/debounce';
import { ALL, DATE_TIME_FORMAT, INIT_PAGINATION } from '@src/constants';
import CustomTable from '@src/components/CustomTable';
import { USER_PACKAGE_STATUS } from '@src/constants/userPackage';

import { StyleListApp } from './index.style';

const ListApp = ({ userId, setApp }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({
    apps: [],
    pagination: { ...INIT_PAGINATION, limit: 5 },
  });
  const [inputText, setInputText] = useState('');
  const [filter, setFilter] = useState({ inputText: '', status: ALL });
  const [loading, setLoading] = useState(true);

  const fetchUserPackages = async (page) => {
    try {
      setLoading(true);
      const params = {
        userId,
        inputText: filter.inputText,
        status: filter.status === ALL ? null : filter.status,
        pageNum: page || data.pagination.page,
        limit: data.pagination.limit,
      };
      const res = await apis.userPackage.getUserPackages(params);
      if (res)
        setData((prevData) => ({
          ...prevData,
          apps: res?.result?.data || [],
          pagination: {
            page: res?.result?.pager?.currentPageNum || 1,
            limit: res?.result?.pager?.limit || 5,
            total: res?.result?.pager?.totalCount || 0,
            totalPages: res?.result?.pager?.lastPageNum || 1,
          },
        }));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    fetchUserPackages(page);
  };

  const handleChangeInputText = (e) => {
    setInputText(e.target.value);
    debounce(() => setFilter({ ...filter, inputText: e.target.value }))();
    setData((prevData) => ({
      ...prevData,
      pagination: {
        page: 1,
        limit: prevData.pagination.limit,
        total: prevData.pagination.total,
        totalPages: prevData.pagination.totalPages,
      },
    }));
  };

  const handleClickRow = (item) => {
    setApp(item.app);
  };

  const heads = [
    {
      label: t('stt'),
      valueName: 'index',
      align: 'center',
    },
    {
      label: t('appName'),
      valueName: 'appName',
      align: 'left',
    },
    {
      label: t('usedPackage'),
      valueName: 'usedPackage',
      align: 'left',
    },
    {
      label: t('latestUpdate'),
      valueName: 'latestUpdate',
      align: 'left',
    },
    {
      label: t('status'),
      valueName: 'status',
      align: 'center',
    },
  ];

  const formatData = data.apps.map((el) => ({
    ...el,
    appName: (
      <>
        {el?.app?.name ? (
          <Box onClick={() => handleClickRow(el)} className="app-name">
            {el?.app?.name}
          </Box>
        ) : (
          '-'
        )}
      </>
    ),
    usedPackage: el?.package?.name || '-',
    latestUpdate: moment(el.updatedAt).format(DATE_TIME_FORMAT),
    status: (
      <>
        {el.status === USER_PACKAGE_STATUS.RUNNING ? (
          <Chip
            className="app-status"
            label={t(el.status.toLowerCase())}
            color="success"
          />
        ) : (
          <Chip className="app-status" label={t(el.status.toLowerCase())} />
        )}
      </>
    ),
  }));

  useEffect(() => {
    fetchUserPackages();
  }, [filter]);

  return (
    <StyleListApp>
      <Box className="filter-app">
        <Typography variant="h3">{t('listApp')}</Typography>
        <Box marginLeft="auto" />
        <TextField
          size="small"
          variant="outlined"
          className="input-search"
          placeholder={t('searchApp')}
          value={inputText}
          onChange={handleChangeInputText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          select
          label={filter.status === null ? t('status') : ''}
          variant="outlined"
          size="small"
          className="select"
          value={filter.status}
          onChange={(e) => setFilter({ ...filter, status: e.target.value })}
          InputLabelProps={{ shrink: false }}
        >
          <MenuItem value={ALL}>{t('all')}</MenuItem>
          {Object.keys(USER_PACKAGE_STATUS).map((key) => (
            <MenuItem key={key} value={USER_PACKAGE_STATUS[key]}>
              {t(USER_PACKAGE_STATUS[key].toLowerCase())}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <CustomTable
        items={formatData}
        heads={heads}
        pagination={data.pagination}
        onChangePagination={handleChangePage}
        onClickRow={handleClickRow}
        loading={loading}
      />
    </StyleListApp>
  );
};

export default ListApp;
