import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import CustomTable from '@src/components/CustomTable';
import apis from '@src/apis';
import { Box, Chip, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLOR } from '@src/styles/color';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { EditRounded } from '@mui/icons-material';
import Popup from '@src/components/Popup';
import ChangePasswordPopup from '@src/components/Popup/ChangePassword';
import { MASTER_ROLE, ROLE_TYPE } from '@src/constants/role';
import CreateAdminPopup from './CreateAdminPopup';

const ListAdmin = ({
  data,
  roles,
  setData,
  pagination,
  handleChangePage,
  loading,
  fetchData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [openDetail, setOpenDetail] = useState(false);
  const [detail, setDetail] = useState({});

  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [adminSelected, setAdminSelected] = useState({});

  const handleOpenConfirmPopup = (e, item) => {
    e.stopPropagation();
    setAdminSelected(item);
    setOpenConfirmPopup(true);
  };

  const handleCloseConfirmPopup = (e) => {
    e.stopPropagation();
    setOpenConfirmPopup(false);
    setAdminSelected({});
  };

  const handleOpenChangePassword = (e, item) => {
    e.stopPropagation();
    setAdminSelected(item);
    setOpenChangePassword(true);
  };

  const handleClose = () => {
    setOpenChangePassword(false);
    setAdminSelected({});
  };

  const heads = [
    {
      label: t('name'),
      valueName: 'name',
      align: 'left',
    },
    {
      label: t('email'),
      valueName: 'email',
      align: 'left',
    },
    {
      label: t('role'),
      valueName: 'role',
      align: 'left',
    },
    {
      label: t('status'),
      valueName: 'status',
      align: 'center',
    },
    {
      label: t('actions'),
      valueName: 'action',
      align: 'center',
    },
  ];

  const formatData = data.map((el) => ({
    ...el,
    status: (
      <Chip
        label={el?.systemUser?.active ? t('active') : t('deactive')}
        color={el?.systemUser?.active ? 'success' : 'error'}
      />
    ),
    role: el?.role?.name,
    action:
      el?.role?.key === MASTER_ROLE[ROLE_TYPE.SYSTEM] ? (
        <></>
      ) : (
        <Box display="flex" gap={2} alignItems="center" justifyContent="center">
          <Tooltip
            title={t(el?.systemUser?.active ? 'lock' : 'unlock')}
            arrow
            placement="top"
          >
            <FontAwesomeIcon
              icon={el?.systemUser?.active ? faLock : faLockOpen}
              color={
                el?.systemUser?.active
                  ? COLOR.error.base
                  : COLOR.darkestBlue[64]
              }
              size="lg"
              onClick={(event) => handleOpenConfirmPopup(event, el)}
            />
          </Tooltip>
          <Tooltip title={t('changePassword')} arrow placement="top">
            <EditRounded
              sx={{ color: COLOR.darkestBlue[64] }}
              onClick={(event) => handleOpenChangePassword(event, el)}
            />
          </Tooltip>
        </Box>
      ),
  }));

  const handleClickRow = (item) => {
    setDetail(item);
    setOpenDetail(true);
  };

  const handleChangeStatus = async (e) => {
    e.stopPropagation();
    try {
      const res = await apis.admin.updateUserStatus(adminSelected?.id, {
        active: !adminSelected?.systemUser?.active,
      });
      if (!res?.status) {
        throw new Error();
      }

      setAdminSelected({});
      fetchData();
      const message = adminSelected?.systemUser?.active
        ? 'inactiveAccountSuccess'
        : 'activeAccountSuccess';
      enqueueSnackbar(t(message), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleChangePassword = async (dataUpdate) => {
    try {
      const { password } = dataUpdate;
      const res = await apis.admin.updateUser(adminSelected?.id, {
        password,
      });
      if (!res?.status) {
        throw new Error();
      }

      setAdminSelected({});
      enqueueSnackbar(t('changePasswordSuccess'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <>
      <CustomTable
        items={formatData}
        heads={heads}
        pagination={pagination}
        onChangePagination={handleChangePage}
        onClickRow={handleClickRow}
        loading={loading}
      />
      {openDetail && (
        <CreateAdminPopup
          open={openDetail}
          onClose={() => setOpenDetail(false)}
          item={detail}
          roles={roles}
          setListData={setData}
        />
      )}
      <Popup
        open={openConfirmPopup}
        onClose={handleCloseConfirmPopup}
        title={t('confirm')}
        content={t(
          adminSelected?.systemUser?.active
            ? 'inactiveAccount'
            : 'activeAccount',
          {
            name: adminSelected?.name,
          },
        )}
        okMessage={t('confirm')}
        cancelMessage={t('cancel')}
        onOk={handleChangeStatus}
        titleColor={COLOR.warning.base}
      />
      <ChangePasswordPopup
        open={openChangePassword}
        onClose={handleClose}
        onOk={handleChangePassword}
        name={adminSelected?.name}
        email={adminSelected?.email || adminSelected?.phoneNumber}
      />
    </>
  );
};

export default ListAdmin;
