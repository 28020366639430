import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import { SIDE_BAR_WIDTH } from '@src/styles/config';

export const StyledAppDetail = styled(Box)`
  background-color: ${COLOR.white[100]};
  border-radius: 12px;

  .title-app-box {
    display: flex;
    gap: 8px;
    padding: 16px;
    align-items: center;

    .title-app {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .back-button {
      padding: 12px 15px;
      border-radius: 100%;
      :hover {
        cursor: pointer;
        background: ${COLOR.darkBlue[4]};
      }
    }
  }

  .content {
    display: flex;
    padding: 16px;
    position: relative;

    .sidebar-box {
      width: ${SIDE_BAR_WIDTH}px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      background-color: ${COLOR.ink[4]};
      border-radius: 12px;
      padding: 16px 12px;
      position: sticky;
      top: 16px;
      height: fit-content;

      .text-item {
        font-weight: 400;
        cursor: pointer;
        margin-top: 8px;
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
          color: ${COLOR.primary.base};
        }
      }
    }
  }
`;
