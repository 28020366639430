import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR } from '@src/styles/color';
import { Box, Popover, Typography } from '@mui/material';

const SelectPopover = ({ options, onSubmit, display }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box onClick={handleClick}>{display}</Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ '.MuiPopover-paper': { margin: '4px', borderRadius: '16px' } }}
      >
        <Box>
          {options.map((option) => (
            <Box
              key={option}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
                p: 2,
                cursor: 'pointer',
                '&:hover': { backgroundColor: COLOR.blueGray[4] },
                width: '128px',
              }}
              onClick={() => {
                onSubmit(option);
                handleClose();
              }}
            >
              <Typography variant="h6b">{t(option)}</Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default SelectPopover;
