import { SERVICE } from '@src/constants';
import api from './api';

export const createArticle = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/admin/article-groups',
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getArticleGroup = async (articleGroupId) => {
  const res = await api({
    method: 'GET',
    url: `/admin/article-groups/${articleGroupId}`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const updateArticleGroup = async (articleGroupId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/article-groups/${articleGroupId}`,
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
