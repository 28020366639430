/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import camelcase from 'camelcase';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import CustomSearchInput from '@src/components/CustomInput/CustomSearchInput';
import Popup from '@src/components/Popup';
import apis from '@src/apis';
import { ROLE_TYPE } from '@src/constants/role';
import { Add, AddCircle, Delete } from '@mui/icons-material';
import CreateGroupPermission from '../CreateGroupPermission';
import CustomTabItems from '../CustomTabItems';

import { StyledGroupPermission } from './index.style';
import ListPermission from './ListPermission';
import DrawerCreatePermission from './DrawerCreatePermission';

const GroupPermission = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [groupPermissions, setGroupPermissions] = useState();
  const [showCreateGroupPermission, setShowCreateGroupPermission] =
    useState(false);
  const [groupPermissionSelected, setGroupPermissionSelected] = useState({});
  const [tab, setTab] = useState(ROLE_TYPE.SYSTEM);
  const [groupPermissionEdit, setGroupPermissionEdit] = useState();
  const [
    showConfirmDeleteGroupPermission,
    setShowConfirmDeleteGroupPermission,
  ] = useState(false);
  const [openDrawerCreatePermission, setOpenDrawerCreatePermission] =
    useState(false);
  const [permissionSelected, setPermissionSelected] = useState();
  const [showConfirmDeletePermission, setShowConfirmDeletePermission] =
    useState(false);

  const getPermissionsForGroupPermission = ({
    listGroupPermission,
    listPermission,
  }) => {
    const newListGroupPermission = listGroupPermission
      .filter((groupPermission) => groupPermission.type === tab)
      .map((groupPermission) => {
        const permissionsFind = listPermission.filter(
          (permission) => groupPermission.id === permission.groupPermissionId,
        );

        return {
          ...groupPermission,
          permissions: permissionsFind,
        };
      });
    setGroupPermissions(newListGroupPermission);

    let groupPermission = newListGroupPermission[0];
    if (groupPermissionSelected) {
      const groupPermissionFind = newListGroupPermission.find(
        (el) => el.id === groupPermissionSelected.id,
      );
      if (groupPermissionFind) groupPermission = groupPermissionFind;
    }

    setGroupPermissionSelected(
      JSON.parse(JSON.stringify(groupPermission || {})),
    );
  };

  const fetchPermissions = async (listGroupPermission) => {
    try {
      const res = await apis.permission.getPermissions();
      if (res) {
        const { status, result } = res;
        if (status)
          getPermissionsForGroupPermission({
            listGroupPermission,
            listPermission: result,
          });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), {
        variant: 'error',
      });
    }
  };

  const fetchGroupPermissions = async () => {
    try {
      const res = await apis.groupPermission.getGroupPermissions();
      if (res) {
        const { status, result } = res;
        if (status) {
          const listGroupPermission = [...result, { id: null }];
          setGroupPermissions(listGroupPermission);
          await fetchPermissions(listGroupPermission);
        }
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    fetchGroupPermissions();
  }, [tab]);

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const handleSelectGroupPermission = (value) => {
    setGroupPermissionSelected(value);
  };

  const handleAddGroupPermission = () => {
    setShowCreateGroupPermission(true);
  };

  const handleCloseCreateGroupPermission = () => {
    setShowCreateGroupPermission(false);
    setGroupPermissionEdit();
  };

  const handleCreateGroupPermission = async () => {
    await fetchGroupPermissions();
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    setGroupPermissionSelected({});
  };

  const handleEditGroupPermission = (item) => {
    setGroupPermissionEdit(item);
    setShowCreateGroupPermission(true);
  };

  const handleDeleteGroupPermission = () => {
    if (!groupPermissionSelected || !groupPermissionSelected.id) return;
    setShowConfirmDeleteGroupPermission(true);
  };

  const handleCloseConfirmDeleteGroupPermission = () => {
    setShowConfirmDeleteGroupPermission(false);
  };

  const handleConfirmDeleteGroupPermission = async () => {
    if (!groupPermissionSelected || !groupPermissionSelected.id) return;
    try {
      const res = await apis.groupPermission.deleteGroupPermission(
        groupPermissionSelected.id,
      );
      if (!res) throw new Error();

      enqueueSnackbar(t('deleteGroupPermissionSuccess'), {
        variant: 'success',
      });
      setGroupPermissionSelected({});
      await fetchGroupPermissions();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleCreatePermission = async () => {
    await fetchGroupPermissions();
  };

  const handleOpenDrawerCreatePermission = () => {
    setOpenDrawerCreatePermission(true);
  };

  const handleCloseCreatePermission = () => {
    setOpenDrawerCreatePermission(false);
    setPermissionSelected();
  };

  const handleUpdatePermission = (value) => {
    setPermissionSelected({
      ...value,
      groupPermission: groupPermissionSelected.id,
    });
    setOpenDrawerCreatePermission(true);
  };

  const handleDeletePermission = (value) => {
    if (!value || !value.id) return;
    setPermissionSelected({
      ...value,
      groupPermission: groupPermissionSelected.id,
    });
    setShowConfirmDeletePermission(true);
  };

  const handleCloseConfirmDeletePermission = () => {
    setShowConfirmDeletePermission(false);
  };

  const handleConfirmDeletePermission = async () => {
    if (!permissionSelected || !permissionSelected.id) return;
    try {
      const res = await apis.permission.deletePermission(permissionSelected.id);
      if (!res) throw new Error('serverError');

      enqueueSnackbar(t('deletePermissionSuccess'), {
        variant: 'success',
      });
      setPermissionSelected();
      await fetchPermissions(groupPermissions);
    } catch (error) {
      enqueueSnackbar(t('deletePermissionFail'), { variant: 'error' });
    }
  };

  if (!groupPermissions) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <StyledGroupPermission>
      <Box mb={1} className="content">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <CustomSearchInput
            value={search}
            onChange={handleSearchChange}
            placeholder={t('searchGroupPermission')}
          />
        </Box>
        <Box className="content-container">
          <Grid container>
            <Grid item xs={3}>
              <Box className="list-container">
                <Box className="list">
                  <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeTab}
                    aria-label="disabled tabs example"
                    className="tabs"
                  >
                    {Object.values(ROLE_TYPE).map((value) => (
                      <Tab
                        value={value}
                        label={t(camelcase(value))}
                        key={value}
                        className="tab"
                      />
                    ))}
                  </Tabs>
                  <CustomTabItems
                    items={groupPermissions.filter((el) => {
                      const isTabType = el?.type === tab;
                      if (!search.trim() && isTabType) return true;
                      if (
                        el.name &&
                        el.name.toLowerCase().indexOf(search.toLowerCase()) >=
                          0 &&
                        isTabType
                      )
                        return true;
                      return false;
                    })}
                    itemSelected={groupPermissionSelected}
                    onSelectItem={handleSelectGroupPermission}
                    onClickEdit={handleEditGroupPermission}
                  />
                </Box>
                <Box className="box-btn-add">
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={<AddCircle />}
                    onClick={handleAddGroupPermission}
                    className="btn-normal"
                  >
                    {t('addGroupPermission')}
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box
                display="flex"
                paddingX={1.5}
                height={48}
                alignItems="center"
                gap={1.5}
              >
                <Typography marginRight="auto">
                  {t('listPermission')}
                </Typography>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<Delete />}
                  onClick={handleDeleteGroupPermission}
                  disabled={!groupPermissionSelected?.id}
                >
                  {t('deleteGroupPermission')}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={handleOpenDrawerCreatePermission}
                >
                  {t('addPermission')}
                </Button>
              </Box>
              <ListPermission
                permissions={
                  (groupPermissionSelected &&
                    groupPermissionSelected.permissions) ||
                  []
                }
                onUpdatePermission={handleUpdatePermission}
                onDeletePermission={handleDeletePermission}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CreateGroupPermission
        open={showCreateGroupPermission}
        onClose={handleCloseCreateGroupPermission}
        onCreateGroupPermission={handleCreateGroupPermission}
        type={tab}
        groupPermissionEdit={groupPermissionEdit}
      />
      <DrawerCreatePermission
        open={openDrawerCreatePermission}
        onClose={handleCloseCreatePermission}
        permissionSelected={permissionSelected}
        groupPermissions={groupPermissions.filter((el) => el.id)}
        onCreatePermission={handleCreatePermission}
        roleType={tab}
      />
      <Popup
        open={showConfirmDeleteGroupPermission}
        onClose={handleCloseConfirmDeleteGroupPermission}
        onOk={handleConfirmDeleteGroupPermission}
        okMessage={t('deleteGroupPermission')}
        title={t('areYouSureDeleteGroupPermission', {
          groupPermission:
            groupPermissionSelected && groupPermissionSelected.name,
        })}
      />
      <Popup
        open={showConfirmDeletePermission}
        onClose={handleCloseConfirmDeletePermission}
        onOk={handleConfirmDeletePermission}
        okMessage={t('deletePermission')}
        title={t('areYouSureDeletePermission', {
          permission: permissionSelected && permissionSelected.name,
        })}
      />
    </StyledGroupPermission>
  );
};

export default GroupPermission;
