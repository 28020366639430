import { SERVICE } from '@src/constants';
import api from './api';

export const getAppReport = async (params) => {
  const res = await api({
    method: 'GET',
    url: `/admin/reports`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getPaidApps = async (params) => {
  const res = await api({
    method: 'GET',
    url: `/admin/reports/apps`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const exportReport = async (params) => {
  const res = await api({
    method: 'GET',
    responseType: 'arraybuffer',
    url: `/admin/reports/export`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });

  return res;
};

export const exportHistoryTransaction = async (params) => {
  const res = await api({
    method: 'GET',
    responseType: 'arraybuffer',
    url: `/admin/transactions/export`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });

  return res;
};
