import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { validAspectRatio, validFile, validSize } from '@src/utils/checkValid';
import { InsertPhoto } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import PreviewImage from './PreviewImage';
import { StyledFileDropzone } from './index.style';

const FileDropzone = ({
  fileUrl,
  clearFile,
  fileType,
  acceptFile,
  disabled,
  onAddFile,
  onClearFile,
  onDeleteFile,
  maxSize,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  width,
  padding,
  boxSizing,
  aspectRatios,
  isError,
  noteContent,
}) => {
  const { t } = useTranslation();

  const fileInputRef = useRef(null);

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [formatError, setFormatError] = useState(false);
  const [oversize, setOverSize] = useState(false);

  const handleResetError = () => {
    setFormatError(false);
    setOverSize(false);
  };

  const handleReset = () => {
    onDeleteFile(null);
    setPreview([]);
    handleResetError();
  };

  const handleChangeFile = (e) => {
    if (disabled) return;
    const { files } = e.target;

    handleResetError();

    if (!files) return;

    if (!validFile(files[0].name, fileType)) {
      setFormatError(true);
      return;
    }
    if (maxSize && !validSize(files[0].size, maxSize)) {
      setOverSize(true);
      return;
    }

    const objectUrl = URL.createObjectURL(files[0]);

    setPreview(objectUrl);
    setFile(files[0]);
    fileInputRef.current.value = null;
  };

  const handleImageLoad = (imgRef) => {
    const { naturalHeight, naturalWidth } = imgRef.current;
    let isValidWidth = true;
    if (minWidth) isValidWidth = naturalWidth >= minWidth;
    if (isValidWidth && maxWidth) isValidWidth = naturalWidth <= maxWidth;

    let isValidHeight = true;
    if (minHeight) isValidHeight = naturalHeight >= minHeight;
    if (isValidHeight && maxHeight) isValidHeight = naturalHeight <= maxHeight;

    let isValidAspectRatio = true;
    if (aspectRatios)
      isValidAspectRatio = validAspectRatio(
        naturalWidth,
        naturalHeight,
        aspectRatios,
      );

    if (!isValidWidth || !isValidHeight || !isValidAspectRatio) {
      setOverSize(true);
      setPreview(null);
      onAddFile(null);
    } else if (file) onAddFile(file);
  };

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  const handleDeleteFilePreview = () => {
    if (disabled) return;

    handleResetError();

    setPreview(null);
    onAddFile(null);
  };

  useEffect(() => {
    if (clearFile) {
      handleReset();
      onClearFile(false);
    }
  }, [clearFile]);

  const renderContent = () => {
    if (noteContent)
      return (
        <Box className="note-content">
          {noteContent}
          <Button
            variant="outlined"
            // disabled={previewUrls?.length >= maxFiles || disabled}
            className="button-upload"
          >
            <FontAwesomeIcon icon={faUpload} />
            {t('uploadImage')}
          </Button>
        </Box>
      );
    return <InsertPhoto color="info" />;
  };

  return (
    <StyledFileDropzone
      width={width}
      iserror={isError || formatError || oversize ? 1 : 0}
    >
      <Box
        className="dropzone pointer"
        onClick={openFileDialog}
        padding={padding}
        boxSizing={boxSizing}
      >
        <input
          ref={fileInputRef}
          className="file-input"
          type="file"
          disabled={disabled}
          accept={acceptFile}
          onChange={handleChangeFile}
        />

        {preview || fileUrl ? (
          <PreviewImage
            image={preview || fileUrl}
            onDelete={handleDeleteFilePreview}
            onImageLoad={handleImageLoad}
            disabled={disabled}
          />
        ) : (
          renderContent()
        )}
      </Box>
      {(isError || formatError || oversize) && (
        <Box className="error">
          {formatError && (
            <Typography variant="caption1" color="error">
              {t('formatError')}
            </Typography>
          )}
          {oversize && (
            <Typography variant="caption1" color="error">
              {t('sizeError')}
            </Typography>
          )}
          {isError && (
            <Typography variant="caption1" color="error">
              {isError}
            </Typography>
          )}
        </Box>
      )}
    </StyledFileDropzone>
  );
};

export default FileDropzone;
