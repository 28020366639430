import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debounce from '@src/utils/debounce';
import { useSearchParams } from '@src/hooks';
import { StyledCustomSearchInput } from './index.style';

const CustomSearchInput = ({
  value: inputText,
  onChange,
  placeholder,
  keySearch = 'inputText',
}) => {
  const { t } = useTranslation();
  const { addParams, removeParams } = useSearchParams();

  const handleDebounceInputSearch = (params) => {
    if (params[keySearch] === '') {
      removeParams(keySearch);
      return;
    }
    addParams({ ...params, page: 1 });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    onChange(value);
    debounce(handleDebounceInputSearch)({ [keySearch]: value });
  };

  return (
    <StyledCustomSearchInput>
      <TextField
        size="small"
        className="text-field"
        variant="outlined"
        placeholder={placeholder || t('search')}
        value={inputText}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </StyledCustomSearchInput>
  );
};

export default CustomSearchInput;
