import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCustomPermissionTypeTag = styled(Box)`
  background: ${(props) => props.background};
  width: fit-content;
  padding: 3px 10px;
  border-radius: 12px;
  color: ${(props) => props.color};
`;
