import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {
  CancelOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { ReactComponent as KeyIcon } from '@src/assets/icons/key.svg';
import { ReactComponent as EmailIcon } from '@src/assets/icons/email.svg';
import { regexEmail, regexPassword } from '@src/constants/regex';
import apis from '@src/apis';
import { handleReceivingTokens } from '@src/services/auth';
import { StyledLogin } from './index.style';

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeEmail = (e) => {
    setErrors({ ...errors, loginError: '', email: '' });
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setErrors({ ...errors, loginError: '', password: '' });
    setPassword(e.target.value);
  };

  const isValid = () => {
    const newErrors = {};

    if (!email) {
      newErrors.email = t('emailRequired');
    }

    if (email && !regexEmail.test(email)) {
      newErrors.email = t('emailInvalid');
    }

    if (!password) {
      newErrors.password = t('passwordRequired');
    }

    if (password && !regexPassword.test(password)) {
      newErrors.password = t('passwordInvalid');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async () => {
    if (!isValid()) return;
    try {
      setLoading(true);
      const res = await apis.auth.login({ email, password });
      const { accessToken, refreshToken } = res || {};
      if (!accessToken) {
        setErrors({ loginError: t('errorEmailOrPassword') });
        return;
      }
      handleReceivingTokens({ token: accessToken, refreshToken });
      history.push('/');
    } catch (error) {
      setErrors({ loginError: t('errorEmailOrPassword') });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledLogin>
      <Box className="card">
        <Box className="header">
          <img src="/logo-aihub-dark.svg" alt="vbee-logo" />
        </Box>
        <Box className="body">
          <Typography variant="h4m" className="title">
            {t('login')}
          </Typography>
          <Box className="input-card">
            <TextField
              autoFocus
              fullWidth
              variant="outlined"
              className="input"
              value={email}
              onChange={handleChangeEmail}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
              placeholder={t('email')}
              error={!!errors?.email}
              helperText={errors?.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
                endAdornment: email && (
                  <InputAdornment position="end" onClick={() => setEmail('')}>
                    <CancelOutlined fontSize="small" cursor="pointer" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              className="input"
              value={password}
              onChange={handleChangePassword}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
              type={isShowPassword ? '' : 'password'}
              placeholder={t('password')}
              error={!!errors?.password}
              helperText={errors?.password}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setIsShowPassword(!isShowPassword)}
                  >
                    {isShowPassword ? (
                      <VisibilityOffOutlined
                        fontSize="small"
                        cursor="pointer"
                      />
                    ) : (
                      <VisibilityOutlined fontSize="small" cursor="pointer" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {errors.loginError && (
            <Typography variant="caption1" color="error" align="center">
              {errors.loginError}
            </Typography>
          )}
          <Button
            variant="contained"
            color="info"
            fullWidth
            onClick={handleLogin}
            disabled={loading}
          >
            {t('login')}
          </Button>
          <Typography variant="caption" className="copyright">
            2024 © AIHub
          </Typography>
        </Box>
      </Box>
    </StyledLogin>
  );
};

export default Login;
