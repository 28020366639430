import React, { useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { useTranslation } from 'react-i18next';
import { StyledRickTextEditor } from './index.style';

const EDITOR_I18N = {
  vi: {
    bold: 'In đậm',
    italic: 'In nghiêng',
    underline: 'Gạch chân',
    'font family': 'Phông chữ',
    'insert unordered list': 'Danh sách có dấu đầu dòng',
    'insert ordered list': 'Danh sách được đánh số',
    'font size': 'Cỡ chữ',
    'align left': 'Căn trái',
    'align center': 'Căn giữa',
    'align right': 'Căn phải',
    preview: 'Xem trước',
    print: 'In',
    default: 'Mặc định',
    circle: '○',
    dot: '●',
    square: '■',
    'Lower Alpha': 'a, b, c, d, e, ...',
    'Lower Greek': 'α, β, γ, δ, ε, ...',
    'Lower Roman': 'i, ii, iii, iv, v, ...',
    'Upper Alpha': 'A, B, C, D, E, ...',
    'Upper Roman': 'I, II, III, IV, V, ...',
    top: 'Trên',
    right: 'Phải',
    bottom: 'Dưới',
    left: 'Trái',
    normal: 'Bình thường',
    Middler: 'Giữa',
    center: 'Chính giữa',
    'insert image': 'Chèn ảnh',
  },
};

const EDITOR_CONFIG = {
  readonly: false,
  buttons: [
    'bold',
    'italic',
    'underline',
    '|',
    'ul',
    'ol',
    'font',
    'fontsize',
    '|',
    'left',
    'center',
    'right',
    'image',
  ],
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarAdaptive: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  height: '60vh',
  uploader: { insertImageAsBase64URI: true },
  i18n: EDITOR_I18N,
  imagesExtensions: ['jpg'],
};

const RickTextEditor = ({
  content,
  onChangeContent,
  readonly,
  customButtons = [],
  height,
  placeholder,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const onChange = (newContent) => {
    onChangeContent(newContent);
  };

  const config = useMemo(
    () => ({
      ...EDITOR_CONFIG,
      buttons: [...EDITOR_CONFIG.buttons, ...customButtons],
      placeholder: placeholder || t('textEditorPlaceHolder'),
      language,
      readonly,
      height,
    }),
    [language, readonly],
  );

  return (
    <StyledRickTextEditor>
      <JoditEditor value={content} config={config} onChange={onChange} />
    </StyledRickTextEditor>
  );
};

export default RickTextEditor;
