import React, { useState } from 'react';
import { Box, Typography, TableSortLabel, Tooltip } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import CustomTable from '@src/components/CustomTable';
import useFormatNumber from '@src/hooks/useFormatNumber';
import { formatDateExport } from '@src/utils/date';
import { COLOR } from '@src/styles/color';
import { useSearchParams } from '@src/hooks';
import { REPORT_GROUP_OPTION } from '@src/constants';

const SortableHeader = ({
  order,
  orderBy,
  onRequestSort,
  label,
  attribute,
  justifyContent = 'flex-start',
  flexDirection = 'row',
}) => (
  <Box
    sx={{
      '.MuiTableSortLabel-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent,
        flexDirection,
      },
    }}
  >
    <TableSortLabel
      active={orderBy === attribute}
      direction={orderBy === attribute ? order : 'asc'}
      onClick={() => onRequestSort(attribute)}
    >
      {orderBy === attribute ? (
        <Box component="span" sx={visuallyHidden}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}

      <Typography variant="body3" sx={{ color: COLOR.darkestBlue[100] }}>
        {label}
      </Typography>
    </TableSortLabel>
  </Box>
);

const DetailData = ({ series = [] }) => {
  const { params } = useSearchParams();
  const { group } = params;

  const { formatNumber, toLocaleString } = useFormatNumber();
  const [orderBy, setOrderBy] = useState('date');
  const [order, setOrder] = useState('desc');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const heads = [
    {
      label: (
        <SortableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          label="Thời gian"
          flexDirection="row"
          attribute="date"
        />
      ),
      valueName: 'date',
      align: 'left',
    },
    ...series.map((s) => ({
      label: (
        <SortableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          label={s.name}
          flexDirection="row-reverse"
          attribute={s.name}
        />
      ),
      valueName: s.name,
      align: 'right',
    })),
  ];

  const combineSeries = {};

  series.forEach((s) => {
    const { name, data = [] } = s;
    data.forEach(({ x, y }) => {
      combineSeries[x] = { ...combineSeries[x], [name]: y };
    });
  });

  const rows = Object.entries(combineSeries).map(([key, value]) => ({
    date: key,
    ...value,
  }));

  const sortedData = rows?.sort((a, b) => {
    const compareDates = (dateA, dateB, format) => {
      const timeA = moment(dateA, format, true).unix();
      const timeB = moment(dateB, format, true).unix();
      return order === 'asc' ? timeA - timeB : timeB - timeA;
    };

    if (orderBy === 'date') {
      if (group === REPORT_GROUP_OPTION.WEEK)
        return compareDates(a.date, b.date, 'YYYY-[w]WW');

      return compareDates(a.date, b.date);
    }

    return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
  });

  const formatData = [
    {
      date: <Typography>Tổng</Typography>,
      ...series.reduce((acc, s, index) => {
        acc[s.name] =
          index === series.length - 1 ? (
            <Tooltip title={toLocaleString(s.total)} placement="top-end">
              <Typography variant="h4" color={COLOR.black[100]}>
                {formatNumber(s.total)}
              </Typography>
            </Tooltip>
          ) : (
            <Tooltip title={toLocaleString(s.total)} placement="top-end">
              <Typography>{formatNumber(s.total)}</Typography>
            </Tooltip>
          );

        return acc;
      }, {}),
    },
    ...sortedData?.map((el) => ({
      ...Object.keys(el).reduce((acc, k) => {
        acc[k] = (
          <Tooltip title={toLocaleString(el[k])} placement="top-end">
            <Typography>{formatNumber(el[k])}</Typography>
          </Tooltip>
        );

        return acc;
      }, {}),
      date: formatDateExport(el.date),
    })),
  ];

  return <CustomTable items={formatData} heads={heads} disablePagination />;
};

export default DetailData;
