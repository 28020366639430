import { useTranslation } from 'react-i18next';

const useFormatNumber = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const formatNumber = (number = 0, fix = 1) => {
    if (typeof number !== 'number') return number;

    let value = number;
    const units = ['', 'K', 'M', 'B', 'T', 'P', 'E'];
    let unitIndex = 0;

    while (value >= 1000 && unitIndex < units.length - 1) {
      value /= 1000;
      unitIndex += 1;
    }

    const roundedNumber = Number.isInteger(value) ? value : value?.toFixed(fix);
    const formattedNumber = roundedNumber + units[unitIndex];

    return formattedNumber.toLocaleString(language);
  };

  const toLocaleString = (number = 0) => number.toLocaleString(language);

  return { formatNumber, toLocaleString };
};

export default useFormatNumber;
