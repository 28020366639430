export default {
  HOME: '/',
  ROLE_MANAGE: '/system/role-manage',
  GROUP_PERMISSION_MANAGE: '/system/group-permission-manage',
  ADMIN: '/admin',
  CSP: '/csp',
  UNAUTHORIZED: '/403',
  APP: '/app',
  APP_DETAIL: '/app/:appId',
  APP_DETAIL_INFO: '/app/:appId/info',
  APP_PACKAGES_MANAGEMENT: '/app/:appId/packages',
  APP_VERSION_DETAIL: '/app/:appId/version/:versionId',
  CONTENT_MANAGEMENT: '/content-management',
  HISTORY_TRANSACTION: '/history-transaction',
  SPECIAL_GROUP_DETAIL: '/special-group/:specialGroupId',
  ARTICLE_GROUP_DETAIL: '/article-group/:articleGroupId',
  USER: '/user',
  USER_DETAIL: '/user/:userId',
  ARTICLE: '/article/:articleId',
  CREATE_ARTICLE: '/create-article/:articleGroupId',
  LOGIN: '/login',
  REPORT: '/report',
};
