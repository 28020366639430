import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import ROUTE from '@src/constants/route';
import { API_METHOD } from '@src/constants';
import apis from '@src/apis';
import appRoutes from './appRoutes';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const [isPermission, setIsPermission] = useState(null);

  const checkUserPermission = async () => {
    try {
      if (!path) {
        setIsPermission(true);
        return;
      }

      const route = appRoutes
        .filter((item) => item.isMenu)
        .find((menu) => path === menu.path);

      if (route) {
        const response = await apis.auth.checkUserPermission({
          method: API_METHOD.GET,
          uri: route.path,
        });
        if (response?.status) {
          setIsPermission(true);
          return;
        }
      }

      setIsPermission(false);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    checkUserPermission();
  }, []);

  if (isPermission === null) return null;

  return (
    <Route
      path
      {...rest}
      render={(props) =>
        isPermission ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTE.UNAUTHORIZED} />
        )
      }
    />
  );
};

export default PrivateRoute;
