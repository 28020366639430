import React, { useEffect, useState } from 'react';
import moment from 'moment';
import camelcase from 'camelcase';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ArrowBack, Sync } from '@mui/icons-material';

import apis from '@src/apis';
import {
  ALL,
  DATE_TIME_FORMAT,
  DATE_TIME_PICKER_TYPES,
  INIT_PAGINATION,
  TRANSACTION_ACTION,
} from '@src/constants';
import CustomTable from '@src/components/CustomTable';

import { CustomDatePickerRange } from '@src/components/CustomDatePickerRange';
import { formatNumber } from '@src/utils/formatNumber';
import { StyleListApp } from './index.style';

const START_TIME_DEFAULT = new Date(
  moment().subtract(1, 'months').startOf('day').toDate(),
);
const END_TIME_DEFAULT = new Date(moment().endOf('day').toDate());

const INIT_FILTER = {
  action: ALL,
  startTime: START_TIME_DEFAULT,
  endTime: END_TIME_DEFAULT,
};

const AppDetail = ({ userId, app, setApp }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({
    apps: [],
    pagination: { ...INIT_PAGINATION, limit: 5 },
  });
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(INIT_FILTER);

  const fetchTransactions = async (page) => {
    try {
      setLoading(true);
      const params = {
        userId,
        appId: app.id,
        action: filter.action === ALL ? null : filter.action,
        startTime: filter.startTime,
        endTime: filter.endTime,
        pageNum: page || data.pagination.page,
        limit: data.pagination.limit,
      };
      const res = await apis.transaction.getTransactions(params);
      if (res)
        setData((prevData) => ({
          ...prevData,
          apps: res?.result?.data || [],
          pagination: {
            page: res?.result?.pager?.currentPageNum || 1,
            limit: res?.result?.pager?.limit || 5,
            total: res?.result?.pager?.totalCount || 0,
            totalPages: res?.result?.pager?.lastPageNum || 1,
          },
        }));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    fetchTransactions(page);
  };

  const handleBack = () => {
    setApp({});
  };

  const handleChangeAction = (e) =>
    setFilter({ ...filter, action: e.target.value });

  const onChangeStartDate = (value) =>
    setFilter((prevState) => ({ ...prevState, startTime: value }));

  const onChangeEndDate = (value) =>
    setFilter((prevState) => ({ ...prevState, endTime: value }));

  const onReloadData = () =>
    setFilter((prevState) => ({
      ...prevState,
      action: ALL,
      startTime: START_TIME_DEFAULT,
      endTime: END_TIME_DEFAULT,
    }));

  const heads = [
    {
      label: t('stt'),
      valueName: 'index',
      align: 'center',
    },
    {
      label: t('packageName'),
      valueName: 'packageName',
      align: 'center',
    },
    {
      label: t('packageCode'),
      valueName: 'packageCode',
      align: 'center',
    },
    {
      label: t('money'),
      valueName: 'chargePrice',
      align: 'right',
    },
    {
      label: t('transactionTime'),
      valueName: 'createdAt',
      align: 'center',
    },
    {
      label: t('hotline'),
      valueName: 'hotline',
      align: 'center',
    },
    {
      label: t('channel'),
      valueName: 'channel',
      align: 'center',
    },
    {
      label: t('action'),
      valueName: 'action',
      align: 'center',
    },
  ];

  const formatData = data.apps.map((el) => ({
    ...el,
    packageName: el?.package?.name || '-',
    chargePrice:
      el?.chargePrice || el?.chargePrice === 0
        ? formatNumber(el?.chargePrice)
        : '-',
    createdAt: moment(el.createdAt).format(DATE_TIME_FORMAT),
    packageCode: el?.package?.packageCode || '-',
    hotline: el?.package?.hotline || '-',
    channel: el.channel,
    action: t(el.action.toLowerCase()),
  }));

  useEffect(() => {
    if (Object.keys(app).length) fetchTransactions();
  }, [app, filter]);

  return (
    <StyleListApp>
      <Box className="filter-app">
        <Box display="flex" alignItems="center">
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h3" mr="auto">
            {t('appDetail', { appName: app.name })}
          </Typography>
        </Box>
        <Box marginLeft="auto" />
        <TextField
          select
          variant="outlined"
          size="small"
          className="select"
          value={filter.action}
          onChange={handleChangeAction}
        >
          <MenuItem value={ALL}>{t('all')}</MenuItem>
          {Object.keys(TRANSACTION_ACTION).map((action) => (
            <MenuItem key={action} value={action}>
              {t(camelcase(action))}
            </MenuItem>
          ))}
        </TextField>
        <Box minWidth={320}>
          <CustomDatePickerRange
            startDate={filter.startTime}
            endDate={filter.endTime}
            handleChangeStartDate={onChangeStartDate}
            handleChangeEndDate={onChangeEndDate}
            type={DATE_TIME_PICKER_TYPES.DATE}
            isRefresh={false}
            hasFromText={false}
          />
        </Box>
        <Tooltip title={t('reloadData')}>
          <IconButton onClick={onReloadData}>
            <Sync />
          </IconButton>
        </Tooltip>
      </Box>
      <CustomTable
        items={formatData}
        heads={heads}
        pagination={data.pagination}
        onChangePagination={handleChangePage}
        loading={loading}
      />
    </StyleListApp>
  );
};

export default AppDetail;
