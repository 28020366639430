import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import camelcase from 'camelcase';
import moment from 'moment';
import { COLOR } from '@src/styles/color';
import useSearchParams from '@src/hooks/useSearchParams';
import apis from '@src/apis';
import CustomSelect from '@src/components/CustomSelect';
import { Box, Tooltip, Typography } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import SelectPopover from '@src/components/Popover/SelectPopover';
import IconPopover from '@src/components/Popover/IconPopover';
import useFormatNumber from '@src/hooks/useFormatNumber';
import {
  REPORT_GROUP_OPTION,
  START_TIME_DEFAULT,
  REPORT_END_TIME_DEFAULT,
  TRANSACTION_ACTION,
} from '@src/constants';
import {
  REPORT_CHARTS,
  REPORT_CATEGORIES,
  CATEGORY_MAPPER,
} from '@src/constants/chart';
import { formatDate, formatDateExport } from '@src/utils/date';
import { exportExcelFromTable } from '@src/utils/excel';
import DetailStackBarChart from './DetailStackBarChart';
import CompareData from './CompareData';

const parseSeries = ({ reportData, report, category, t }) => {
  const data = reportData[report] || {};

  const series = [];

  const totalSeries = [
    {
      name: t(`${report}Report`),
      data: data?.series?.map((el) => ({ x: el.key, y: el.total })) || [],
      total: data.total,
    },
  ];

  if (category === REPORT_CATEGORIES.TOTAL) series.push(...totalSeries);

  if (category === REPORT_CATEGORIES.BY_EVENT) {
    const entries = Object.entries(data?.action || {}).map(([key, value]) => ({
      name: t(key),
      data: value?.series?.map((el) => ({ x: el.key, y: el.total })) || [],
      total: value.total,
      isCancel: key.toUpperCase() === TRANSACTION_ACTION.CANCEL,
    }));

    entries.sort((a, b) => a.isCancel - b.isCancel);
    series.push(...entries);
  }

  if (category === REPORT_CATEGORIES.BY_PACKAGE)
    series.push(
      ...Object.entries(data?.package || {})?.map(([key, value]) => ({
        name: t(key)?.toUpperCase(),
        data: value?.series?.map((el) => ({ x: el.key, y: el.total })) || [],
        total: value.total,
      })),
    );

  return series;
};

const Summarize = ({ report, total = 0, changed = 0 }) => {
  const { t } = useTranslation();
  const { formatNumber, toLocaleString } = useFormatNumber();

  const color = changed > 0 ? COLOR.success.base : 'error';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1,
      }}
    >
      <Typography variant="h6b">{t(`${report}Report`)}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Tooltip title={toLocaleString(total)} placement="top">
          <Typography fontSize="30px" fontWeight="700" textAlign="end">
            {formatNumber(total)}
          </Typography>
        </Tooltip>
        <Tooltip
          title={`${changed > 0 ? '+' : ''}${toLocaleString(changed)}%`}
          placement="top"
        >
          <Typography
            variant="body2"
            color={changed === 0 ? COLOR.blueGray[64] : color}
            textAlign="end"
          >{`${changed > 0 ? '+' : ''}${formatNumber(changed)}%`}</Typography>
        </Tooltip>
      </Box>
    </Box>
  );
};

const Chart = ({
  appId,
  report,
  group,
  startTime = START_TIME_DEFAULT,
  endTime = REPORT_END_TIME_DEFAULT,
  category = REPORT_CATEGORIES.TOTAL,
  onLoad,
  id,
  onChangeCategory,
  compareTo,
}) => {
  const [reportData, setReportData] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { formatNumber } = useFormatNumber();

  const isReportUserActive = report === REPORT_CHARTS.USER_ACTIVE;

  const fetchAppReport = async () => {
    try {
      const res = await apis.report.getAppReport({
        appId,
        startTime,
        endTime,
        reports: report,
        group: group || REPORT_GROUP_OPTION.DAY,
        category: CATEGORY_MAPPER[category],
      });
      if (res?.status) setReportData(res?.result);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const series = parseSeries({
    reportData,
    report: camelcase(report),
    category,
    t,
  });

  useEffect(() => {
    onLoad({
      total: reportData[camelcase(report)]?.total,
      changed: reportData[camelcase(report)]?.changed,
      ...reportData[camelcase(report)],
    });
  }, [reportData, report]);

  useEffect(() => {
    if (appId && compareTo && report) fetchAppReport();
  }, [appId, startTime, endTime, report, group, category, compareTo]);

  const options = [REPORT_CATEGORIES.TOTAL, REPORT_CATEGORIES.BY_PACKAGE];

  if (!isReportUserActive) options.push(REPORT_CATEGORIES.BY_EVENT);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '22px',
          cursor: 'pointer',
          mb: 3,
        }}
      >
        <SelectPopover
          value={category}
          onSubmit={onChangeCategory}
          options={options}
        />
      </Box>

      <Box height="300px" pb={3}>
        <DetailStackBarChart
          series={series}
          yaxisFormatter={(v) => formatNumber(v)}
          xaxisFormatter={(v) => formatDate(v)}
          group="compare"
          id={id}
        />
      </Box>
    </>
  );
};

const Compare = ({ app = {} }) => {
  const [original, setOriginal] = useState({ total: 0, changed: 0 });
  const [compare, setCompare] = useState({ total: 0, changed: 0 });

  const { toLocaleString } = useFormatNumber();

  const { t } = useTranslation();

  const { params, addParams, removeParams } = useSearchParams();
  const {
    appId,
    report = '',
    group,
    compareTo = '',
    startTime = START_TIME_DEFAULT,
    endTime = REPORT_END_TIME_DEFAULT,
    category = REPORT_CATEGORIES.TOTAL,
    foreignCategory = REPORT_CATEGORIES.TOTAL,
  } = params;

  const handleChangeCompareTo = (value) =>
    addParams({
      compareTo: value,
      foreignCategory:
        value === REPORT_CHARTS.USER_ACTIVE &&
        category === REPORT_CATEGORIES.BY_EVENT
          ? REPORT_CATEGORIES.TOTAL
          : category,
    });

  const handleChangeGroupOption = (value) => addParams({ group: value });

  const handleChangeCategory = (value) => addParams({ category: value });

  const handleChangeForeignCategory = (value) =>
    addParams({ foreignCategory: value });

  const options = [
    { value: 'none', label: `Không` },
    ...[
      REPORT_CHARTS.REVENUE,
      REPORT_CHARTS.TRANSACTION,
      REPORT_CHARTS.USER,
      REPORT_CHARTS.USER_ACTIVE,
    ]
      .filter((o) => o !== report)
      .map((reportKey) => ({
        value: reportKey,
        label: t(`${camelcase(reportKey)}Report`),
      })),
  ];

  const handleExportReport = () => {
    const series = [
      { data: original?.series || [], name: t(`${camelcase(report)}Report`) },
    ];

    if (compareTo !== 'none')
      series.push({
        data: compare?.series || [],
        name: t(`${camelcase(compareTo)}Report`),
      });

    if (foreignCategory && compareTo !== 'none')
      series.unshift(
        ...Object.keys(compare[CATEGORY_MAPPER[foreignCategory]] || {})?.map(
          (key) => ({
            data: compare[CATEGORY_MAPPER[foreignCategory]][key]?.series || [],
            name: `${t(`${camelcase(compareTo)}Report`)} ${
              foreignCategory === REPORT_CATEGORIES.BY_PACKAGE
                ? t(key)?.toUpperCase()
                : t(key)
            }`,
          }),
        ),
      );

    if (category)
      series.unshift(
        ...Object.keys(original[CATEGORY_MAPPER[category]] || {})?.map(
          (key) => ({
            data: original[CATEGORY_MAPPER[category]][key]?.series || [],
            name: `${t(`${report}Report`)} ${
              category === REPORT_CATEGORIES.BY_PACKAGE
                ? t(key)?.toUpperCase()
                : t(key)
            }`,
          }),
        ),
      );

    const heads = [
      { label: 'Thời gian', valueName: 'date' },
      ...series?.map((s) => ({ label: s.name, valueName: s.name })),
    ];

    let rows = {};

    series.forEach((s) => {
      const { name, data } = s;
      data?.forEach(({ key, total }) => {
        rows[key] = { ...rows[key], [name]: toLocaleString(total) };
      });
    });

    rows = Object.entries(rows)?.map(([date, value]) => ({
      date: formatDateExport(date),
      ...value,
    }));

    exportExcelFromTable({
      data: rows,
      fileName: `${app.name}_${t(report)}_so sánh với_${
        compareTo === 'none' ? '_' : t(camelcase(compareTo))
      }_${moment(startTime).format(`DD-MM-YYYY`)}_${moment(endTime).format(
        `DD-MM-YYYY`,
      )}`,
      heads,
    });
  };

  const handleSelectOption = (value) => {
    if (value === 'Xuất báo cáo') {
      handleExportReport();
      return;
    }
    if (value === 'Hủy so sánh') removeParams('compareTo');
  };

  return (
    <Box
      sx={{
        display: compareTo ? 'block' : 'none',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          p: '20px 0 0 50px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 20,
          }}
        >
          <Summarize
            report={report}
            total={original?.total || 0}
            changed={original.changed}
          />
          {compareTo !== 'none' && (
            <Summarize
              report={compareTo}
              total={compare?.total || 0}
              changed={compare.changed}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
            cursor: 'pointer',
            mb: 3,
          }}
        >
          <Typography>So sánh với:</Typography>
          <Box width="220px">
            <CustomSelect
              options={options}
              value={compareTo}
              onChange={handleChangeCompareTo}
              className="select"
            />
          </Box>
          <Box width="100px">
            <CustomSelect
              options={Object.values(REPORT_GROUP_OPTION).map((g) => ({
                value: g,
                label: t(g),
              }))}
              value={group}
              onChange={handleChangeGroupOption}
              className="select"
            />
          </Box>
          <IconPopover
            display={
              <MoreHoriz
                sx={{
                  borderRadius: '50%',
                  p: '6px',
                  border: '1px solid rgba(0, 67, 144, 0.32)',
                  cursor: 'pointer',
                  color: COLOR.darkBlue[100],
                }}
              />
            }
            onSubmit={handleSelectOption}
            options={['Xuất báo cáo', 'Hủy so sánh']}
          />
        </Box>
      </Box>
      <Chart
        id="original"
        appId={appId}
        report={report}
        group={group}
        startTime={startTime}
        endTime={endTime}
        category={category}
        app={app}
        onChangeCategory={handleChangeCategory}
        onLoad={(data) => setOriginal(data)}
        compareTo={compareTo}
      />
      {compareTo && compareTo !== 'none' && (
        <Chart
          id="compare"
          appId={appId}
          report={compareTo}
          group={group}
          startTime={startTime}
          endTime={endTime}
          category={foreignCategory}
          app={app}
          onLoad={(data) => setCompare(data)}
          onChangeCategory={handleChangeForeignCategory}
          compareTo={compareTo}
        />
      )}
      <CompareData original={original} compare={compare} />
    </Box>
  );
};

export default Compare;
