import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import CustomSelect from '@src/components/CustomSelect';
import apis from '@src/apis';
import { AGE_RATING } from '@src/constants';
import { StyledAppDetail } from './index.style';

const AppDetailInfo = ({ app }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [appName, setAppName] = useState('');
  const [ageRating, setAgeRating] = useState('');
  const [primaryCategory, setPrimaryCategory] = useState({});
  const [secondaryCategory, setSecondaryCategory] = useState({});
  const [subTitle, setSubTitle] = useState('');
  const [policyUrl, setPolicyUrl] = useState('');
  const [categories, setCategories] = useState([]);

  const fetchCategories = async (params = {}) => {
    try {
      const response = await apis.category.getCategories({
        ...params,
        limit: 100,
      });
      if (response?.status) {
        setCategories(response?.result?.data || []);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const fetchApp = () => {
    setAppName(app?.name);
    setAgeRating(app?.ageRating);
    setPrimaryCategory(app?.primaryCategory);
    setSecondaryCategory(app?.secondCategory);
    setSubTitle(app?.subTitle);
    setPolicyUrl(app?.policyUrl);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchApp();
  }, [app]);

  return (
    <StyledAppDetail>
      <Box className="header-card">
        <Box className="header-card-title">
          <Typography variant="h3">{t('appInfo')}</Typography>
        </Box>
        <Typography variant="subtitle1">{t('appInfoDescription')}</Typography>
      </Box>
      <Box className="body-card">
        <Grid container spacing={2}>
          <Grid item md={12} lg={6}>
            <Typography className="label-input">
              {t('appName')} <span>*</span>
              <Tooltip title={t('appNameTooltip')} arrow placement="top">
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </Typography>
            <TextField size="small" fullWidth value={appName || ''} disabled />
          </Grid>
          <Grid item md={12} lg={6}>
            <Typography className="label-input">{t('subTitle')}</Typography>
            <TextField size="small" fullWidth value={subTitle || ''} disabled />
          </Grid>
        </Grid>
        <Box>
          <Typography className="label-input">
            {t('category')} <span>*</span>
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={12} lg={6}>
              <Autocomplete
                disablePortal
                value={primaryCategory || ''}
                size="small"
                options={categories?.filter(
                  (item) => item?.id !== secondaryCategory?.id,
                )}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                getOptionLabel={(option) => option?.name || ''}
                disabled
                renderInput={(params) => (
                  <TextField {...params} label={t('primary')} />
                )}
                noOptionsText={t('noData')}
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <Autocomplete
                disablePortal
                value={secondaryCategory || ''}
                size="small"
                options={categories?.filter(
                  (item) => item?.id !== primaryCategory?.id,
                )}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                getOptionLabel={(option) => option?.name || ''}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t('secondary')} (${t('optional')})`}
                  />
                )}
                noOptionsText={t('noData')}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={12} lg={6}>
            <Typography className="label-input">
              {t('ageRating')} <span>*</span>
            </Typography>
            <CustomSelect
              options={Object.values(AGE_RATING).map((value) => ({
                value,
                label: `${value}+ ${t('yearsOld')}`,
              }))}
              value={ageRating || ''}
              disabled
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <Typography className="label-input">{t('urlPolicy')}</Typography>
            <TextField
              size="small"
              fullWidth
              value={policyUrl || ''}
              disabled
            />
          </Grid>
        </Grid>
        <Box />
      </Box>
    </StyledAppDetail>
  );
};

export default AppDetailInfo;
