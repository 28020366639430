import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBottom = styled('div')`
  .avatar {
    cursor: pointer;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  gap: 10px;
`;
