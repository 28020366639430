import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyleCreateUser = styled(Box)`
  display: grid;
  gap: 16px;

  .textfield-input {
    .MuiOutlinedInput-root {
      border-radius: 24px;
      height: 48px;
      background-color: ${COLOR.darkBlue[4]};

      fieldset {
        border: none;
      }
    }
  }

  .input-box {
    display: flex;
    align-items: center;

    .required {
      color: ${COLOR.red[100]};
    }
  }
`;

export const StyleFilterUser = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;

  .select {
    min-width: 160px;
    .MuiOutlinedInput-root {
      border-radius: 12px;
      background-color: ${COLOR.darkBlue[8]};
      color: ${COLOR.darkestBlue[100]};

      fieldset {
        border: none;
      }
    }
  }

  .input-search {
    .MuiOutlinedInput-root {
      border-radius: 24px;

      fieldset {
        border-color: ${COLOR.darkBlue[32]};
      }
    }
  }
`;

export const StyleSearchUserAutocomplete = styled(Box)`
  .user-info {
    font-size: 12px;
    color: ${COLOR.blueGray[64]};
  }

  .select-user {
    height: 42px;
  }
`;
