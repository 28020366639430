import { SERVICE } from '@src/constants';
import api from './api';

export const getPermissions = async (params) => {
  const res = await api({
    method: 'GET',
    url: `/admin/permissions`,
    params,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const createPermission = async (data) => {
  const res = await api({
    method: 'POST',
    url: `/admin/permissions`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const updatePermission = async (permissionId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/permissions/${permissionId}`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const deletePermission = async (permissionId) => {
  const res = await api({
    method: 'DELETE',
    url: `/admin/permissions/${permissionId}`,
    source: SERVICE.ACCOUNT,
  });
  return res;
};
