import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import apis from '@src/apis';
import { ALL, INIT_PAGINATION } from '@src/constants';
import FilterEndUser from './FilterEndUser';
import ListEndUser from './ListEndUser';

import { StyleEndUser } from './index.style';

const EndUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({
    users: [],
    pagination: INIT_PAGINATION,
  });
  const [filter, setFilter] = useState({ inputText: '', active: ALL });
  const [loading, setLoading] = useState(true);

  const fetchUsers = async (page) => {
    try {
      setLoading(true);
      const params = {
        inputText: filter.inputText,
        active: filter.active === ALL ? null : filter.active,
        pageNum: page || data.pagination.page,
      };
      const res = await apis.user.getUsers(params);
      if (res)
        setData((prevData) => ({
          ...prevData,
          users: res?.result?.data || [],
          pagination: {
            page: res?.result?.pager?.currentPageNum || 1,
            limit: res?.result?.pager?.limit || 10,
            total: res?.result?.pager?.totalCount || 0,
            totalPages: res?.result?.pager?.lastPageNum || 1,
          },
        }));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    fetchUsers(page);
  };

  useEffect(() => {
    fetchUsers();
  }, [filter]);

  return (
    <StyleEndUser>
      <FilterEndUser filter={filter} setFilter={setFilter} setData={setData} />
      <ListEndUser
        data={data.users}
        pagination={data.pagination}
        handleChangePage={handleChangePage}
        loading={loading}
      />
    </StyleEndUser>
  );
};

export default EndUser;
