import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  BOX_SHADOW_CARD,
  SIDE_BAR_WIDTH,
  SIDE_BAR_WIDTH_COLLAPSED,
} from '@src/styles/config';
import apis from '@src/apis';

import Top from './Top';
import Bottom from './Bottom';
import ListMenu from './ListMenu';
import { data as menus } from './data';

const getValidMenus = (allMenus = [], permittedMenus = []) => {
  const validMenus = allMenus.reduce((acc, menu) => {
    const { path, subMenu } = menu;

    if (path) {
      const isPermittedMenu = permittedMenus.some((permittedMenu) => {
        if (!permittedMenu?.uri) return false;
        return permittedMenu.uri === path;
      });
      return isPermittedMenu ? [...acc, menu] : acc;
    }

    if (subMenu) {
      const validSubMenu = getValidMenus(subMenu, permittedMenus);
      return validSubMenu?.length
        ? [...acc, { ...menu, subMenu: validSubMenu }]
        : acc;
    }

    return acc;
  }, []);

  return validMenus;
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isCollapse, setIsCollapse] = useState(false);
  const [permittedMenus, setPermittedMenus] = useState([]);
  const widthSidebar = isCollapse ? SIDE_BAR_WIDTH_COLLAPSED : SIDE_BAR_WIDTH;

  const fetchMenus = async () => {
    try {
      const response = await apis.auth.checkPermittedMenus();
      if (response?.status) {
        const { menus: menusResponse, allowAll } = response?.result;
        if (allowAll) {
          setPermittedMenus(menus);
        } else {
          setPermittedMenus(getValidMenus(menus, menusResponse));
        }
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchMenus();
  }, []);

  return (
    <Box
      width={widthSidebar}
      flex="none"
      sx={{ transition: 'width 0.3s ease' }}
      zIndex={1000}
    >
      <Box
        position="fixed"
        top={0}
        left={0}
        height="100vh"
        bgcolor="white"
        width="inherit"
        boxShadow={BOX_SHADOW_CARD}
        sx={{ backdropFilter: 'blur(50px)' }}
      >
        <Top isCollapse={isCollapse} />
        <ListMenu isCollapse={isCollapse} menus={permittedMenus} />
        <Bottom isCollapse={isCollapse} setIsCollapse={setIsCollapse} />
      </Box>
    </Box>
  );
};

export default Sidebar;
