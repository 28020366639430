import { SERVICE } from '@src/constants';
import api from './api';

export const getOrganizations = async (params) => {
  const res = await api({
    method: 'GET',
    url: `/admin/organizations`,
    params,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const createOrganization = async (data) => {
  const res = await api({
    method: 'POST',
    url: `/admin/organizations`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const updateOrganization = async (organizationId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/organizations/${organizationId}`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const updateOrganizationStatus = async (organizationId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/organizations/${organizationId}/change-status`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const getOwners = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/admin/organizations/owners',
    params,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const updateOwner = async (ownerId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/organizations/owners/${ownerId}`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const getOrganizationDetail = async (organizationId) => {
  const res = await api({
    method: 'GET',
    url: `/admin/organizations/${organizationId}`,
    source: SERVICE.ACCOUNT,
  });
  return res;
};
