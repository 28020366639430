import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import camelcase from 'camelcase';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import {
  Add,
  AutorenewRounded,
  ReplayRounded,
  VisibilityRounded,
  Search,
  Delete,
} from '@mui/icons-material';
import {
  SPECIAL_GROUP_LAYOUT,
  SPECIAL_GROUP_LOCATION,
  PAGES,
  PAGE_BLOCK_TYPE,
  ARTICLE_LAYOUT,
  DATE_TIME_FORMAT,
} from '@src/constants';
import CustomSelect from '@src/components/CustomSelect';
import CustomTable from '@src/components/CustomTable';
import apis from '@src/apis';
import { COLOR } from '@src/styles/color';
import moment from 'moment';
import debounce from '@src/utils/debounce';
import Popup from '@src/components/Popup';
import { HOMEPAGE_URL } from '@src/configs';
import { compareStringToSearch, mapPagePrefix } from '@src/utils/string';
import CreateAppGroup from './CreateAppGroup';
import CategorySelect from './CategorySelect';
import PageSelect from './PageSelect';
import { StyledAppDetail } from './index.style';
import CreateBanner from './CreateBanner';
import CreateArticleGroup from './CreateArticleGroup';

const ContentManagement = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const [tab, setTab] = useState(SPECIAL_GROUP_LOCATION.CUSTOM);
  const [searchBlockName, setSearchBlockName] = useState('');
  const [contentType, setContentType] = useState('');
  const [blocks, setBlocks] = useState([]);
  const [blocksApi, setBlocksApi] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categorySelected, setCategorySelected] = useState({});
  const [openConfirmUpdatePopup, setOpenConfirmUpdatePopup] = useState(false);
  const [openCreateAppGroupPopup, setOpenCreateAppGroupPopup] = useState(false);
  const [openCreateBannerPopup, setOpenCreateBannerPopup] = useState(false);
  const [openCreateArticlePopup, setOpenCreateArticlePopup] = useState(false);
  const [bannerSelectedId, setBannerSelectedId] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openSelectContentTypeCreate = Boolean(anchorEl);
  const [deletedBlockId, setDeletedBlockId] = useState(false);
  const [popupContent, setPopupContent] = useState('publishConfirm');

  const [page, setPage] = useState(PAGES.HOME);
  // TODO: Bỏ pageId khi ghép api getPages để đổ dữ liệu ra danh sách page lựa chọn để cấu hình và sử dụng page.id
  const [pageId, setPageId] = useState('');

  const hasFilter = () => {
    if (searchBlockName) return true;
    if (contentType) return true;
    return false;
  };

  const isChangeBlocks = () => {
    for (let i = 0; i < blocks.length; i += 1) {
      if (blocks[i]?.id !== blocksApi[i]?.id) return true;
      if (blocks[i]?.layout !== blocksApi[i]?.layout) return true;
      if (!!blocks[i]?.display !== !!blocksApi[i]?.display) return true;
    }
    return false;
  };

  const tabs = [
    {
      label: t('customContent'),
      value: SPECIAL_GROUP_LOCATION.CUSTOM,
    },
    {
      label: t('categoryContent'),
      value: SPECIAL_GROUP_LOCATION.CATEGORY,
    },
  ];

  const types = [
    {
      label: t('all'),
      value: '',
    },
    {
      label: t('specialGroup'),
      value: PAGE_BLOCK_TYPE.SPECIAL_GROUP,
    },
    {
      label: t('banner'),
      value: PAGE_BLOCK_TYPE.BANNER,
    },
    {
      label: t('articleGroup'),
      value: PAGE_BLOCK_TYPE.ARTICLE_GROUP,
    },
  ];

  const layoutApps = (layout) => {
    const bgcolor = (value) =>
      layout === value ? COLOR.black[4] : COLOR.lightBlue[100];

    return [
      {
        label: t(camelcase(SPECIAL_GROUP_LAYOUT.LIST_FOCUS_LEFT)),
        customLabel: (
          <Box display="flex" alignItems="center" gap={1.5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <Skeleton
                animation="wave"
                width={90}
                height={90}
                sx={{ bgcolor: bgcolor(SPECIAL_GROUP_LAYOUT.LIST_FOCUS_LEFT) }}
              />
              <Box>
                {Array.from(new Array(4)).map(() => (
                  <Skeleton
                    key={uuidv4()}
                    animation="wave"
                    width={20}
                    height={14}
                    sx={{
                      bgcolor: bgcolor(SPECIAL_GROUP_LAYOUT.LIST_FOCUS_LEFT),
                    }}
                  />
                ))}
              </Box>
            </Box>
            {t(camelcase(SPECIAL_GROUP_LAYOUT.LIST_FOCUS_LEFT))}
          </Box>
        ),
        value: SPECIAL_GROUP_LAYOUT.LIST_FOCUS_LEFT,
      },
      {
        label: t(camelcase(SPECIAL_GROUP_LAYOUT.LIST_FOCUS_RIGHT)),
        customLabel: (
          <Box display="flex" alignItems="center" gap={1.5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <Box>
                {Array.from(new Array(4)).map(() => (
                  <Skeleton
                    key={uuidv4()}
                    animation="wave"
                    width={20}
                    height={14}
                    sx={{
                      bgcolor: bgcolor(SPECIAL_GROUP_LAYOUT.LIST_FOCUS_RIGHT),
                    }}
                  />
                ))}
              </Box>
              <Skeleton
                animation="wave"
                width={90}
                height={90}
                sx={{ bgcolor: bgcolor(SPECIAL_GROUP_LAYOUT.LIST_FOCUS_RIGHT) }}
              />
            </Box>
            {t(camelcase(SPECIAL_GROUP_LAYOUT.LIST_FOCUS_RIGHT))}
          </Box>
        ),
        value: SPECIAL_GROUP_LAYOUT.LIST_FOCUS_RIGHT,
      },
      {
        label: t(camelcase(SPECIAL_GROUP_LAYOUT.GRID)),
        customLabel: (
          <Box display="flex" alignItems="center" gap={1.5} height="100px">
            <Grid container width="126px">
              {Array.from(new Array(9)).map(() => (
                <Grid item xs={4} key={uuidv4()}>
                  <Skeleton
                    animation="wave"
                    width="85%"
                    sx={{ bgcolor: bgcolor(SPECIAL_GROUP_LAYOUT.GRID) }}
                  />
                </Grid>
              ))}
            </Grid>
            {t(camelcase(SPECIAL_GROUP_LAYOUT.GRID))}
          </Box>
        ),
        value: SPECIAL_GROUP_LAYOUT.GRID,
      },
      {
        label: t(camelcase(SPECIAL_GROUP_LAYOUT.SWIPER)),
        customLabel: (
          <Box display="flex" alignItems="center" gap={1.5} height="54px">
            <Grid container width="126px">
              {Array.from(new Array(3)).map(() => (
                <Grid item xs={4} key={uuidv4()}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="85%"
                    sx={{ bgcolor: bgcolor(SPECIAL_GROUP_LAYOUT.SWIPER) }}
                  />
                </Grid>
              ))}
            </Grid>
            {t(camelcase(SPECIAL_GROUP_LAYOUT.SWIPER))}
          </Box>
        ),
        value: SPECIAL_GROUP_LAYOUT.SWIPER,
      },
    ];
  };

  const layoutArticle = (layout) => {
    const bgcolor = (value) =>
      layout === value ? COLOR.black[4] : COLOR.lightBlue[100];

    return [
      {
        label: t(camelcase(ARTICLE_LAYOUT.OVERVIEW)),
        customLabel: (
          <Box display="flex" alignItems="center" gap={1.5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Skeleton
                animation="wave"
                width={180}
                height={90}
                sx={{ bgcolor: bgcolor(ARTICLE_LAYOUT.OVERVIEW) }}
              />
              <Box display="flex" flexDirection="row" gap={0.5} width="180px">
                {Array.from(new Array(3)).map(() => (
                  <Skeleton
                    key={uuidv4()}
                    animation="wave"
                    width="95%"
                    height="45px"
                    sx={{
                      bgcolor: bgcolor(ARTICLE_LAYOUT.OVERVIEW),
                    }}
                  />
                ))}
              </Box>
            </Box>
            {t(camelcase(ARTICLE_LAYOUT.OVERVIEW))}
          </Box>
        ),
        value: ARTICLE_LAYOUT.OVERVIEW,
      },
      {
        label: t(camelcase(ARTICLE_LAYOUT.GRID)),
        customLabel: (
          <Box display="flex" alignItems="center" gap={1.5} height="100px">
            <Grid container width="180px">
              {Array.from(new Array(3)).map(() => (
                <Grid item xs={4} key={uuidv4()}>
                  <Skeleton
                    height="45px"
                    animation="wave"
                    width="95%"
                    sx={{ bgcolor: bgcolor(ARTICLE_LAYOUT.GRID) }}
                  />
                </Grid>
              ))}
            </Grid>
            {t(camelcase(ARTICLE_LAYOUT.GRID))}
          </Box>
        ),
        value: ARTICLE_LAYOUT.GRID,
      },
      {
        label: t(camelcase(ARTICLE_LAYOUT.SWIPER)),
        customLabel: (
          <Box display="flex" alignItems="center" gap={1.5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
              width={180}
              flex
            >
              <Skeleton
                animation="wave"
                width={120}
                height={90}
                sx={{ bgcolor: bgcolor(ARTICLE_LAYOUT.SWIPER) }}
                margin="0px"
              />
              <Skeleton
                animation="wave"
                width={120}
                height={90}
                sx={{ bgcolor: bgcolor(ARTICLE_LAYOUT.SWIPER) }}
                margin="0px"
              />
              <Skeleton
                animation="wave"
                width={120}
                height={90}
                sx={{ bgcolor: bgcolor(ARTICLE_LAYOUT.SWIPER) }}
                margin="0px"
              />
            </Box>
            {t(camelcase(ARTICLE_LAYOUT.SWIPER))}
          </Box>
        ),
        value: ARTICLE_LAYOUT.SWIPER,
      },
    ];
  };

  const fetchCategories = async () => {
    try {
      const response = await apis.category.getCategories({ limit: 100 });
      if (response?.status) {
        setCategories(response?.result?.data || []);
        setCategorySelected(response?.result?.data[0]);
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  const handleChangeTab = async (event, newValue) => {
    if (isChangeBlocks()) {
      enqueueSnackbar(t('changeBlockedAction'), { variant: 'warning' });
      return;
    }
    if (newValue === SPECIAL_GROUP_LOCATION.CATEGORY) await fetchCategories();
    setTab(newValue);
  };

  const handleSelectCategory = (category = {}) => setCategorySelected(category);

  const handleSelectPage = (p) => setPage(p);

  const handleChangeSearchBlockName = (e) => setSearchBlockName(e.target.value);

  const handleChangeType = (value) => setContentType(value);

  const handleReorder = (items) => {
    const copyListItems = items.map(({ id }, index) => {
      const item = blocks.find((el) => el.id === id);
      return { ...item, rank: index + 1 };
    });
    setBlocks(copyListItems);
  };

  const handleChangeBlockField = (key, value, index) => {
    if (hasFilter()) return;
    const copyListItems = [...blocks];
    copyListItems[index][key] = value;
    setBlocks(copyListItems);
  };

  const heads = [
    {
      label: t('position'),
      valueName: 'rank',
      align: 'center',
    },
    {
      label: t('groupOrBannerName'),
      valueName: 'name',
      align: 'left',
    },
    {
      label: t('contentType'),
      valueName: 'contentType',
      align: 'left',
    },
    {
      label: t('numAppsOrArticles'),
      valueName: 'numApps',
      align: 'center',
    },
    {
      label: t('layout'),
      valueName: 'layout',
      align: 'center',
    },
    {
      label: t('createdAt'),
      valueName: 'createdAt',
      align: 'center',
    },
    {
      label: t('createdBy'),
      valueName: 'createdBy',
      align: 'left',
    },
    {
      label: t('show'),
      valueName: 'display',
      align: 'center',
    },
    {
      label: t('action'),
      valueName: 'actions',
      align: 'center',
    },
  ];

  const renderType = (groupType) => {
    switch (groupType) {
      case PAGE_BLOCK_TYPE.SPECIAL_GROUP:
        return t('specialGroup');
      case PAGE_BLOCK_TYPE.BANNER:
        return t('banner');
      case PAGE_BLOCK_TYPE.ARTICLE_GROUP:
        return t('articleGroup');

      default:
        return '--';
    }
  };

  const numberItems = (el) => {
    switch (el.type) {
      case PAGE_BLOCK_TYPE.SPECIAL_GROUP:
        return el?.apps?.length || 0;
      case PAGE_BLOCK_TYPE.ARTICLE_GROUP:
        return el?.articles?.length || 0;
      default:
        return '--';
    }
  };

  const formatData = blocks
    .filter((el) => {
      if (contentType && el.type !== contentType) return false;
      return compareStringToSearch(el.name, searchBlockName);
    })
    .map((el, index) => ({
      ...el,
      display: (
        <Switch
          checked={el.display || false}
          onChange={(event) =>
            handleChangeBlockField('display', event.target.checked, index)
          }
        />
      ),
      displayValue: el.display,
      numApps: numberItems(el),
      createdBy: el?.createdBy?.name || '--',
      contentType: renderType(el.type),
      createdAt: moment(el.createdAt).format(DATE_TIME_FORMAT),
      // TODO: Bỏ check default khi banner có layout
      layout:
        el.type === PAGE_BLOCK_TYPE.BANNER ? (
          <Typography variant="subtitle1" color="text.primary" fontWeight="400">
            {t('default')}
          </Typography>
        ) : (
          <CustomSelect
            options={layoutApps(el.layout, el.type)}
            value={el.layout}
            onChange={(value) => handleChangeBlockField('layout', value, index)}
            className="select-layout"
            disabled={hasFilter()}
          />
        ),
      data: el,
    }));

  const handleRefresh = () => {
    setSearchBlockName('');
    setContentType('');
    setCategorySelected({});
  };

  const pageKey = mapPagePrefix(
    tab,
    tab === SPECIAL_GROUP_LOCATION.CUSTOM ? page?.key : categorySelected?.key,
  );

  const fetchBlocks = async () => {
    setLoading(true);
    try {
      const params = {
        key: pageKey,
      };

      // TODO: Thay bằng api getPage và tìm bằng pageId thay vì key sau khi có api getPage và tích hợp api getPages để đổ dữ liệu ra danh sách page lựa chọn để cấu hình
      const response = await apis.page.getPages(params);
      if (!response?.status) throw new Error();

      const { blocks: pageBlocks = [], id } = response?.result;

      setBlocks(JSON.parse(JSON.stringify(pageBlocks)) || []);
      setBlocksApi(JSON.parse(JSON.stringify(pageBlocks)) || []);
      setPageId(id);
    } catch (error) {
      setBlocks([]);
      setBlocksApi([]);
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleResetBlocks = () => {
    setBlocks(JSON.parse(JSON.stringify(blocksApi)));
  };

  const handleClickUpdatePackages = () => {
    setOpenConfirmUpdatePopup(true);
  };

  const handleCloseUpdatePackages = () => {
    setOpenConfirmUpdatePopup(false);
  };

  const handleSelectContent = (group) => {
    if (group?.type === PAGE_BLOCK_TYPE.BANNER) {
      setBannerSelectedId(group?.id);
      setOpenCreateBannerPopup(true);
      return;
    }

    if (group?.type === PAGE_BLOCK_TYPE.ARTICLE_GROUP) {
      history.push(`/article-group/${group?.id}`);
      return;
    }

    history.push(`/special-group/${group?.id}`);
  };

  const updatePage = async () => {
    try {
      const params = {};

      if (tab === SPECIAL_GROUP_LOCATION.CATEGORY) {
        params.categoryId = categorySelected?.id;
        params.location = SPECIAL_GROUP_LOCATION.CATEGORY;
      } else params.location = page.key || tab;

      const data = blocks
        .filter((el) => el.id !== deletedBlockId)
        .map(
          ({
            type: blockType,
            rank,
            display,
            layout,
            specialGroupId,
            bannerId,
            articleGroupId,
          }) => {
            const dataUpdate = { rank, display, layout, type: blockType };
            if (blockType === PAGE_BLOCK_TYPE.BANNER)
              return { ...dataUpdate, bannerId };

            if (blockType === PAGE_BLOCK_TYPE.ARTICLE_GROUP)
              return { ...dataUpdate, articleGroupId };

            return {
              ...dataUpdate,
              specialGroupId,
            };
          },
        );
      const res = await apis.page.updatePage(pageId, { blocks: data });
      if (!res?.status) throw new Error();

      await fetchBlocks();
      enqueueSnackbar(t('updateContentSuccess'), {
        variant: 'success',
      });
      setIsPreview(false);
      setPopupContent('publishConfirm');
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleRemoveBlock = (b) => {
    if (!b.displayValue) {
      setDeletedBlockId(b.id);
      setPopupContent('removeBlockConfirm');
      handleClickUpdatePackages();
    }
  };

  const actions = [
    {
      icon: (el) => (
        <Delete
          color={`${isChangeBlocks() || el.displayValue ? 'inherit' : 'error'}`}
        />
      ),
      onClick: handleRemoveBlock,
      disable: isChangeBlocks(),
    },
  ];

  useEffect(() => {
    debounce(() => {
      fetchBlocks();
    }, 150)();
  }, [tab, categorySelected?.id, page?.key]);

  const sendDataToIframe = () => {
    const iframe = document.getElementById('embeddedIframe');
    if (iframe) {
      // Gửi dữ liệu tới iframe thông qua postMessage
      iframe.contentWindow.postMessage(
        blocks.filter((item) => item?.display),
        '*',
      );
      setIsPreview(true);
    }
  };

  const iframeUrl = () => {
    if (tab === SPECIAL_GROUP_LOCATION.CATEGORY) {
      return `${HOMEPAGE_URL}/categories/${categorySelected?.key?.toLowerCase()}`;
    }

    return HOMEPAGE_URL;
  };

  const handleOpenContentMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseContentMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectContentTypeCreate = (type) => {
    if (type === PAGE_BLOCK_TYPE.SPECIAL_GROUP) {
      setOpenCreateAppGroupPopup(true);
    }

    if (type === PAGE_BLOCK_TYPE.BANNER) {
      setOpenCreateBannerPopup(true);
    }

    if (type === PAGE_BLOCK_TYPE.ARTICLE_GROUP) {
      setOpenCreateArticlePopup(true);
    }

    handleCloseContentMenu();
  };

  const handleCloseCreatePopup = () => {
    setOpenCreateAppGroupPopup(false);
  };

  const handleCloseBannerPopup = () => {
    setBannerSelectedId('');
    setOpenCreateBannerPopup(false);
  };

  const handleCloseArticlePopup = () => {
    setOpenCreateArticlePopup(false);
  };

  const pageSelectElement = document.getElementById('page-selected');
  const pageSelectHeight = pageSelectElement?.clientHeight || 0;
  const categorySelectElement = document.getElementById('category-selected');
  const categorySelectHeight = categorySelectElement?.clientHeight || 0;

  const [tableMaxHeight, setTableMaxHeight] = useState(
    `calc(100vh - ${250 + pageSelectHeight}px)`,
  );

  useEffect(() => {
    setTableMaxHeight(
      tab === SPECIAL_GROUP_LOCATION.CUSTOM
        ? `calc(100vh - ${250 + pageSelectHeight}px)`
        : `calc(100vh - ${250 + categorySelectHeight}px)`,
    );
  }, [pageSelectHeight, categorySelectHeight, isPreview]);

  return (
    <StyledAppDetail ispreview={isPreview ? 1 : 0}>
      <Box className="custom-card">
        <Box className="header-card">
          <Box className="header-card-title">
            <Typography variant="h3">{t('contentManage')}</Typography>
          </Box>
        </Box>

        <Box className="filter-card">
          <Tabs value={tab} onChange={handleChangeTab} className="tabs">
            {tabs.map((item) => (
              <Tab
                key={item.label}
                label={item.label}
                value={item.value}
                className="tab"
              />
            ))}
          </Tabs>
          <Box className="right">
            <TextField
              fullWidth
              size="small"
              placeholder={t('inputGroupName')}
              InputProps={{
                startAdornment: <Search fontSize="small" />,
              }}
              disabled={isChangeBlocks()}
              value={searchBlockName}
              onChange={handleChangeSearchBlockName}
              className="search-input"
            />
            <CustomSelect
              options={types}
              value={contentType}
              onChange={(value) => handleChangeType(value)}
              label={t('contentType')}
              className="select"
              disabled={isChangeBlocks()}
            />
            <IconButton
              className="reload-button"
              disabled={isChangeBlocks()}
              onClick={handleRefresh}
            >
              <ReplayRounded color="primary" />
            </IconButton>
            <Button
              variant="contained"
              onClick={handleOpenContentMenu}
              disabled={isChangeBlocks()}
              id="select-content-button"
              aria-controls={
                openSelectContentTypeCreate ? 'content-menu' : undefined
              }
              aria-expanded={openSelectContentTypeCreate ? 'true' : undefined}
              aria-haspopup="true"
            >
              <Add />
              {t('createNewGroup')}
            </Button>
            <Menu
              id="content-menu"
              MenuListProps={{
                'aria-labelledby': 'select-content-button',
              }}
              anchorEl={anchorEl}
              open={openSelectContentTypeCreate}
              onClose={handleCloseContentMenu}
            >
              {Object.values(PAGE_BLOCK_TYPE).map((item) => (
                <MenuItem
                  onClick={() => handleSelectContentTypeCreate(item)}
                  key={item}
                >
                  {t(camelcase(item))}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>

        {tab === SPECIAL_GROUP_LOCATION.CATEGORY && (
          <CategorySelect
            onSelectCategory={handleSelectCategory}
            categories={categories}
            categoryId={categorySelected?.id}
            disabled={isChangeBlocks()}
          />
        )}
        {tab === SPECIAL_GROUP_LOCATION.CUSTOM && (
          <PageSelect
            onSelectPage={handleSelectPage}
            pages={PAGES}
            selectedPage={page}
            disabled={isChangeBlocks()}
          />
        )}
        <Box className="body-card">
          <CustomTable
            items={formatData}
            heads={heads}
            disablePagination
            loading={loading}
            reorderable={!hasFilter()}
            onReorder={handleReorder}
            isStickyHeader
            maxHeight={tableMaxHeight}
            onClickRow={handleSelectContent}
            actions={actions}
          />
        </Box>
      </Box>

      <Box
        sx={{ display: isPreview ? 'block' : 'none' }}
        className="iframe-container"
      >
        <iframe
          id="embeddedIframe"
          title="AI Hub"
          src={iframeUrl()}
          width="100%"
          height="100%"
          scrolling="auto"
          frameBorder={0}
        />
      </Box>

      <Box className="footer-card">
        <Button
          variant="outlined"
          color="primary"
          className="button"
          onClick={handleResetBlocks}
          disabled={!isChangeBlocks()}
        >
          <AutorenewRounded fontSize="small" sx={{ rotate: '45deg' }} />
          {t('reset')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="button"
          onClick={sendDataToIframe}
          disabled={!isChangeBlocks()}
        >
          <VisibilityRounded fontSize="small" />
          {t('preview')}
        </Button>
      </Box>

      {isPreview && (
        <Box className="footer-card box-shadow">
          <Button
            variant="outlined"
            color="primary"
            className="button"
            onClick={() => setIsPreview(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="button"
            onClick={handleClickUpdatePackages}
          >
            {t('publish')}
          </Button>
        </Box>
      )}

      <Popup
        open={openConfirmUpdatePopup}
        onClose={handleCloseUpdatePackages}
        title={t('confirm')}
        content={t(popupContent)}
        okMessage={t('yes')}
        cancelMessage={t('no')}
        onOk={updatePage}
      />
      <CreateAppGroup
        location={tab}
        selectedPage={page}
        defaultCategoryId={categorySelected?.id}
        fetchBlocks={fetchBlocks}
        onSelectCategory={handleSelectCategory}
        onChangeSearchBlockName={setSearchBlockName}
        onChangeType={setContentType}
        onClose={handleCloseCreatePopup}
        open={openCreateAppGroupPopup}
        layouts={layoutApps}
      />
      <CreateBanner
        pageKey={pageKey}
        defaultCategoryId={categorySelected?.id}
        fetchBlocks={fetchBlocks}
        onChangeSearchBlockName={setSearchBlockName}
        onChangeType={setContentType}
        onClose={handleCloseBannerPopup}
        open={openCreateBannerPopup}
        bannerId={bannerSelectedId}
      />
      <CreateArticleGroup
        location={tab}
        selectedPage={page}
        fetchBlocks={fetchBlocks}
        onChangeSearchBlockName={setSearchBlockName}
        onChangeType={setContentType}
        onClose={handleCloseArticlePopup}
        open={openCreateArticlePopup}
        layouts={layoutArticle}
        pageKey={pageKey}
      />
    </StyledAppDetail>
  );
};

export default ContentManagement;
