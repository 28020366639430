import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import apis from '@src/apis';
import {
  GROUP_DESC_CHARACTER_LIMIT,
  GROUP_NAME_CHARACTER_LIMIT,
  ARTICLE_LAYOUT,
} from '@src/constants';
import CustomSelect from '@src/components/CustomSelect';
import { StyledCreateGroupDialog } from './index.style';

const CreateArticleGroup = ({
  fetchBlocks,
  onChangeSearchBlockName,
  onChangeType,
  onClose,
  open,
  pageKey,
  layouts,
}) => {
  const INITIAL_GROUP_DATA = {
    name: '',
    description: '',
    layout: ARTICLE_LAYOUT.OVERVIEW,
  };

  const [groupData, setGroupData] = useState(INITIAL_GROUP_DATA);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseCreatePopup = () => {
    onClose();
    setGroupData(INITIAL_GROUP_DATA);
    setError({});
  };

  const isValid = () => {
    const { name, description, layout } = groupData;
    const errorData = {};

    if (!name?.trim()) errorData.name = t('nameRequired');

    if (!description) errorData.description = t('descriptionRequired');

    if (!layout) errorData.layout = t('layoutRequired');

    if (Object.keys(errorData).length) {
      setError(errorData);
      return false;
    }
    return true;
  };

  const onChangeGroupData = (key, value) => {
    setError({ ...error, [key]: '' });

    if (key === 'name' && value.length > GROUP_NAME_CHARACTER_LIMIT) return;
    if (key === 'description' && value.length > GROUP_DESC_CHARACTER_LIMIT)
      return;

    setGroupData({ ...groupData, [key]: value });
  };

  const handleSave = async () => {
    if (!isValid()) return;

    try {
      setLoading(true);
      const response = await apis.articleGroup.createArticle({
        pageKey,
        ...groupData,
      });

      if (!response?.status) throw new Error();

      enqueueSnackbar(t('createArticleGroupSuccess'), { variant: 'success' });
      handleCloseCreatePopup();
      onChangeSearchBlockName('');
      onChangeType('');
      fetchBlocks();
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledCreateGroupDialog
      className="dialog"
      open={open}
      onClose={handleCloseCreatePopup}
    >
      <DialogTitle className="dialog-title">
        {t('createArticleGroup')}
      </DialogTitle>
      <DialogContent>
        <Box className="content">
          <Box>
            <Typography className="label-input" variant="h6">
              {t('groupName')}
              <span>*</span>
            </Typography>
            <TextField
              autoFocus
              size="small"
              value={groupData.name}
              onChange={(e) => onChangeGroupData('name', e.target.value)}
              fullWidth
              error={!!error.name}
              helperText={error.name}
              placeholder={t('inputArticleGroupName')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      className=""
                      color="secondary"
                      variant="subtitle2"
                    >
                      {groupData.name?.length || 0}/{GROUP_NAME_CHARACTER_LIMIT}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Typography className="label-input" variant="h6">
              {t('description')}
              <span>*</span>
            </Typography>
            <Box className={`text-area ${!!error.description && 'error'}`}>
              <TextField
                size="small"
                variant="standard"
                multiline
                rows={4}
                value={groupData.description}
                onChange={(e) =>
                  onChangeGroupData('description', e.target.value)
                }
                fullWidth
                placeholder={t('inputDescription')}
              />
              <Typography textAlign="end" color="secondary" variant="subtitle2">
                {groupData.description?.length || 0}/
                {GROUP_DESC_CHARACTER_LIMIT}
              </Typography>
            </Box>
            {!!error.description && (
              <Typography
                color="error"
                variant="caption1"
                className="error-text"
              >
                {error.description}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography className="label-input" variant="h6">
              {t('layout')}
              <span>*</span>
            </Typography>
            <CustomSelect
              options={layouts(groupData.layout)}
              value={groupData.layout}
              onChange={(value) => onChangeGroupData('layout', value)}
              error={!!error.layout}
              helperText={error.layout}
            />
          </Box>
        </Box>
        <Grid item xs={12} className="action">
          <Button
            onClick={handleCloseCreatePopup}
            variant="outlined"
            className="button"
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleSave}
            autoFocus
            variant="contained"
            className="button"
            disabled={loading}
          >
            {t('create')}
          </Button>
        </Grid>
      </DialogContent>
    </StyledCreateGroupDialog>
  );
};

export default CreateArticleGroup;
