import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Redirect, useHistory } from 'react-router-dom';
import 'nprogress/nprogress.css';
import { useDispatch } from 'react-redux';
import Layout from '@src/containers/Layout';
import { Box, CircularProgress } from '@mui/material';
import ROUTE from '@src/constants/route';
import apis from '@src/apis';
import api from '@src/apis/api';
import actions from '@src/redux/actions';

import { getCookie } from '@src/utils/cookie';
import { handleReceivingTokens } from '@src/services/auth';
import appRoutes from './appRoutes';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const PrivateApp = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const privateRoutes = appRoutes.filter((route) => route.isPrivate);

  const handleInvalidAccessToken = () => {
    handleReceivingTokens({ token: '', refreshToken: '' });
    history.push(ROUTE.LOGIN);
  };

  const getMe = async () => {
    try {
      const accessToken = getCookie('accessToken');
      if (!accessToken) {
        handleInvalidAccessToken();
        return;
      }

      const response = await apis.user.getMe(accessToken);
      if (!response?.status) {
        handleInvalidAccessToken();
        return;
      }

      if (response?.status) {
        dispatch(actions.user.updateUser(response?.result));
        setLoading(false);
        api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      }
    } catch (error) {
      handleInvalidAccessToken();
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  if (loading) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Layout>
      <Switch>
        {privateRoutes.map((privateRoute) => (
          <PrivateRoute
            path={privateRoute.path}
            component={privateRoute.component}
            exact
            key={privateRoute.path}
          />
        ))}
        <Redirect to={ROUTE.HOME} />
      </Switch>
    </Layout>
  );
};

const AppRouter = () => {
  const publicRoutes = appRoutes.filter((route) => !route.isPrivate);

  return (
    <BrowserRouter>
      <Switch>
        {publicRoutes.map((publicRoute) => (
          <PublicRoute
            key={publicRoute.path}
            exact
            path={publicRoute.path}
            component={publicRoute.component}
            restricted={publicRoute.restricted}
            ignoreToken={publicRoute.ignoreToken}
          />
        ))}
        <PrivateRoute component={PrivateApp} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
