import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Box } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { StyledCustomTabItems } from './index.style';

const CustomTabItems = ({ items, itemSelected, onSelectItem, onClickEdit }) => {
  const { t } = useTranslation();
  const [isHoverEdit, setIsHoverEdit] = useState('');

  const handleClickItem = (item) => {
    onSelectItem(item);
  };

  const handleClickEdit = (event, item) => {
    event.stopPropagation();
    onClickEdit(item);
  };

  return (
    <StyledCustomTabItems>
      {items.map((item) => (
        <Box
          key={item.id}
          className={`item ${itemSelected.id === item.id && 'item-selected'}`}
          onClick={() => handleClickItem(item)}
          onMouseEnter={() => setIsHoverEdit(item.id)}
          onMouseLeave={() => setIsHoverEdit('')}
        >
          <Typography className="title">{item.name || t('other')}</Typography>
          {onClickEdit && isHoverEdit === item.id ? (
            <Edit onClick={(event) => handleClickEdit(event, item)} />
          ) : (
            <Typography className="description">
              {(item.permissions && item.permissions.length) || 0}{' '}
              {t('permission')}
            </Typography>
          )}
        </Box>
      ))}
    </StyledCustomTabItems>
  );
};

export default CustomTabItems;
