import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import apis from '@src/apis';
import {
  ALL,
  INIT_PAGINATION,
  PAGINATION_LIMIT,
  START_TIME_DEFAULT,
  END_TIME_DEFAULT,
} from '@src/constants';
import FilterHistoryTransaction from './FilterHistoryTransaction';
import ListHistoryTransaction from './ListHistoryTransaction';

const INIT_FILTER = {
  inputText: '',
  status: ALL,
  action: ALL,
  startTime: START_TIME_DEFAULT,
  endTime: END_TIME_DEFAULT,
};

const HistoryTransaction = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({
    historyTransactions: [],
    pagination: INIT_PAGINATION,
  });
  const [filter, setFilter] = useState(INIT_FILTER);
  const [loading, setLoading] = useState(false);

  const fetchTransactions = async (page = 1) => {
    try {
      setLoading(true);
      const params = {
        inputText: filter.inputText,
        pageNum: page || data.pagination.page,
        limit: PAGINATION_LIMIT,
      };

      if (filter.action !== ALL) params.action = filter.action;
      if (filter.startTime) params.startTime = filter.startTime;
      if (filter.endTime) params.endTime = filter.endTime;
      if (filter.organizationId) params.organizationId = filter.organizationId;

      const res = await apis.transaction.getTransactions(params);

      if (res?.status) {
        const { data: transactions, pager } = res.result;
        const newData = transactions.map((transaction) => ({
          ...transaction,
          imageUrl: transaction.app?.latestPublishedAppVersion?.appImageUrl,
        }));
        setData((prevData) => ({
          ...prevData,
          historyTransactions: newData || [],
          pagination: {
            page: pager?.currentPageNum || 1,
            limit: pager?.limit,
            total: pager?.totalCount || 0,
            totalPages: pager?.lastPageNum || 1,
          },
        }));
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => fetchTransactions(page);

  useEffect(() => {
    fetchTransactions();
  }, [filter]);

  return (
    <>
      <FilterHistoryTransaction
        filter={filter}
        setFilter={setFilter}
        setData={setData}
      />
      <ListHistoryTransaction
        data={data.historyTransactions}
        setData={setData}
        pagination={data.pagination}
        handleChangePage={handleChangePage}
        loading={loading}
      />
    </>
  );
};
export default HistoryTransaction;
