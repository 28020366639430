import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { DEFAULT_APP_ICON } from '@src/constants';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

const TitleApp = ({ name, imgSrc = DEFAULT_APP_ICON }) => {
  const history = useHistory();

  const { routeBack } = useSelector((state) => state.route);

  const handleBack = () => history.push(routeBack);

  return (
    <Box className="title-app-box">
      <FontAwesomeIcon
        className="back-button"
        icon={faChevronLeft}
        size="lg"
        onClick={handleBack}
      />
      <Avatar variant="rounded" src={imgSrc} />
      <Typography variant="h2" className="title-app">
        {name}
      </Typography>
    </Box>
  );
};

export default TitleApp;
