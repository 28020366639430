import React from 'react';
import { PERMISSION_TYPE } from '@src/constants';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';
import { StyledCustomPermissionTypeTag } from './index.style';

const CustomPermissionTypeTag = ({ type }) => {
  let color = '';
  let background = '';

  switch (type) {
    case PERMISSION_TYPE.API:
      color = COLOR.success.base;
      background = TRANSPARENT_COLOR.success;
      break;
    case PERMISSION_TYPE.MENU:
      color = COLOR.primary.base;
      background = TRANSPARENT_COLOR.info;
      break;
    default:
      break;
  }

  return (
    <StyledCustomPermissionTypeTag color={color} background={background}>
      {type}
    </StyledCustomPermissionTypeTag>
  );
};

export default CustomPermissionTypeTag;
