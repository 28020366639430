import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, TextField, Button } from '@mui/material';
import CustomerDrawer from '@src/components/CustomDrawer';
import apis from '@src/apis';
import { useSnackbar } from 'notistack';
import { StyledCreateGroupPermission } from './index.style';

const CreateGroupPermission = ({
  open,
  onClose = () => {},
  onCreateGroupPermission = () => {},
  type,
  groupPermissionEdit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [groupPermissionData, setGroupPermissionData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!open) {
      setGroupPermissionData({});
      setErrors({});
    }
  }, [open]);

  useEffect(() => {
    if (groupPermissionEdit?.id) {
      setGroupPermissionData(groupPermissionEdit);
    }
  }, [groupPermissionEdit]);

  const handleChange = ({ name, value }) => {
    setGroupPermissionData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleClose = () => {
    onClose();
  };

  const validateGroupPermissionData = () => {
    const { name } = groupPermissionData;

    let errorApp = {};

    if (!name) {
      errorApp.name = 'groupPermissionNameRequired';
    }

    errorApp = { ...errors, ...errorApp };

    const checkExistError = Object.values(errorApp).find((err) => err);
    if (checkExistError) {
      setErrors(errorApp);
      return false;
    }

    return true;
  };

  const handleCreate = async () => {
    if (!validateGroupPermissionData()) return;

    try {
      const { name, id } = groupPermissionData;
      let res = {};
      if (id)
        res = await apis.groupPermission.updateGroupPermission(id, {
          name,
        });
      else
        res = await apis.groupPermission.createGroupPermission({
          name,
          type,
        });

      if (!res?.status) throw new Error();
      handleClose();
      onCreateGroupPermission();
      enqueueSnackbar(
        groupPermissionEdit?.id
          ? t('updateGroupPermissionSuccess')
          : t('createGroupPermissionSuccess'),
        {
          variant: 'success',
        },
      );
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleReset = () => {
    setGroupPermissionData(groupPermissionEdit || {});
    setErrors({});
  };

  return (
    <CustomerDrawer
      open={open}
      onClose={handleClose}
      title={
        groupPermissionEdit?.id
          ? t('updateGroupPermission')
          : t('addGroupPermission')
      }
    >
      <StyledCreateGroupPermission>
        <Box className="content">
          <Box className="item">
            <Typography className="title">
              {t('groupPermissionName')}
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              placeholder={t('groupPermissionNamePlaceholder')}
              value={groupPermissionData.name || ''}
              onChange={(e) =>
                handleChange({ name: 'name', value: e.target.value })
              }
              helperText={errors.name && t(errors.name)}
              error={Boolean(errors.name)}
            />
          </Box>
        </Box>
        <Box className="actions">
          <Button
            onClick={handleCreate}
            variant="contained"
            disabled={
              !groupPermissionData?.name ||
              groupPermissionData?.name === groupPermissionEdit?.name
            }
          >
            {groupPermissionEdit?.id ? t('save') : t('createNow')}
          </Button>
          <Button variant="outlined" onClick={handleReset}>
            {t('reset')}
          </Button>
        </Box>
      </StyledCreateGroupPermission>
    </CustomerDrawer>
  );
};

export default CreateGroupPermission;
