import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import { BORDER_RADIUS } from '@src/styles/config';

export const StyledGroupPermission = styled(Box)`
  .content {
    background-color: ${COLOR.white[100]};
    padding: 15px;
    border-radius: ${BORDER_RADIUS.container};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .content-container {
    background-color: ${COLOR.white[100]};
    border: 1px solid ${COLOR.pastelBlue[100]};
    border-radius: 6px;
  }

  .list-container {
    min-height: 60vh;
    border-right: 1px solid ${COLOR.pastelBlue[100]};

    .box-btn-add {
      margin: 20px 14px;
    }
  }

  .btn-normal {
    font-weight: normal;
  }

  .tabs {
    border-bottom: 1px solid ${COLOR.pastelBlue[100]};
    width: auto;
  }
  .tab {
    text-transform: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding: 0px 20px;
    width: 50%;
  }

  .tab-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 6px 6px 17px;

    .tab-label {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }

    .btn-delete-group-permission {
      color: ${COLOR.dark};
      border-color: ${COLOR.dark};
    }
  }
`;
