import ROUTES from '@src/constants/route';
import Unauthorized from '@src/pages/Unauthorized';
import Home from '@src/pages/Home';
import GroupPermissionPage from '@src/pages/GroupPermission';
import RolePage from '@src/pages/Role';
import AdminPage from '@src/pages/Admin';
import CSPPage from '@src/pages/CSP';
import AppPage from '@src/pages/App';
import AppDetail from '@src/pages/AppDetail';
import ContentManagement from '@src/pages/ContentManagement';
import HistoryTransaction from '@src/pages/HistoryTransaction';
import SpecialGroupDetail from '@src/pages/SpecialGroupDetail';
import ArticleGroupDetail from '@src/pages/ArticleGroupDetail';
import EndUserPage from '@src/pages/EndUser';
import EndUserDetailPage from '@src/pages/EndUserDetail';
import ArticlePage from '@src/pages/Article';
import Login from '@src/pages/Login';

export default [
  {
    path: ROUTES.UNAUTHORIZED,
    component: Unauthorized,
    exact: true,
    restricted: true,
    isPrivate: false,
    isMenu: false,
    ignoreToken: true,
  },
  {
    path: ROUTES.HOME,
    component: Home,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.GROUP_PERMISSION_MANAGE,
    component: GroupPermissionPage,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.ROLE_MANAGE,
    component: RolePage,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.ADMIN,
    component: AdminPage,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.CSP,
    component: CSPPage,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP,
    component: AppPage,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP_DETAIL,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP_VERSION_DETAIL,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP_DETAIL_INFO,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP_PACKAGES_MANAGEMENT,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.CONTENT_MANAGEMENT,
    component: ContentManagement,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.HISTORY_TRANSACTION,
    component: HistoryTransaction,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.SPECIAL_GROUP_DETAIL,
    component: SpecialGroupDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.ARTICLE_GROUP_DETAIL,
    component: ArticleGroupDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.USER,
    component: EndUserPage,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.USER_DETAIL,
    component: EndUserDetailPage,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.ARTICLE,
    component: ArticlePage,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.CREATE_ARTICLE,
    component: ArticlePage,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.LOGIN,
    component: Login,
    exact: false,
    restricted: false,
    isPrivate: false,
    isMenu: false,
    ignoreToken: false,
  },
];
