/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Typography } from '@mui/material';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { APP_VERSION_STATUS, VERSION_ACTION } from '@src/constants';
import apis from '@src/apis';
import { useSnackbar } from 'notistack';
import RefusalReasonPopup from './RefusalReasonPopup';
import { StyledAppDetailInfo } from './index.style';

const DIFF_VIEW_STYLE = {
  lineNumber: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'SF Pro Rounded !important',
  },
  contentText: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'SF Pro Rounded !important',
  },
};

const COMPARE_ATTRIBUTE = [
  'advertisingContent',
  'description',
  'newFeatures',
  'supportUrl',
  'marketingUrl',
  'copyRight',
];

const isAttributeChanged = ({
  attribute,
  publishVersion = {},
  prepareVersion = {},
}) => prepareVersion[attribute] !== publishVersion[attribute];

const DiffView = ({ attribute, prepareVersion = {}, publishVersion = {} }) => {
  const [showChanged, setShowChanged] = useState(false);
  const { t } = useTranslation();

  if (!publishVersion[attribute] || !prepareVersion[attribute]) return <></>;

  return (
    <div className="diff">
      <div className="label">
        <Typography className="" variant="h6">
          {t(attribute)}
        </Typography>
        <Typography
          className="option"
          onClick={() => setShowChanged(!showChanged)}
        >
          {showChanged ? t('hide') : t('viewChange')}
        </Typography>
      </div>

      {showChanged ? (
        <ReactDiffViewer
          oldValue={publishVersion[attribute]}
          newValue={prepareVersion[attribute]}
          leftTitle={`${t('version')} ${publishVersion?.version}`}
          rightTitle={`${t('version')} ${prepareVersion?.version}`}
          splitView
          compareMethod={DiffMethod.WORDS}
          styles={DIFF_VIEW_STYLE}
          codeFoldMessageRenderer={(number) => t(`expandText`, { number })}
        />
      ) : (
        <Typography className="value">{prepareVersion[attribute]}</Typography>
      )}
    </div>
  );
};

const Attribute = ({ attribute, version = {} }) => {
  const { t } = useTranslation();

  return (
    <div className="diff">
      <div className="label">
        <Typography className="" variant="h6">
          {t(attribute)}
        </Typography>
      </div>
      <>
        {version[attribute] ? (
          <Typography className="value">{version[attribute]}</Typography>
        ) : (
          <Typography className="no-data">{t('noData')}</Typography>
        )}
      </>
    </div>
  );
};

const Version = ({ app = {}, version = {} }) => {
  const isCompare =
    [
      APP_VERSION_STATUS.WAITING_REVIEW,
      APP_VERSION_STATUS.APPROVED,
      APP_VERSION_STATUS.REJECTED,
      APP_VERSION_STATUS.PUBLISH_SCHEDULED,
    ].includes(version.status) && app?.latestPublishedAppVersion?.id;

  return COMPARE_ATTRIBUTE?.map((attribute) => {
    if (
      isCompare &&
      isAttributeChanged({
        attribute,
        publishVersion: app.latestPublishedAppVersion,
        prepareVersion: version,
      })
    )
      return (
        <DiffView
          attribute={attribute}
          publishVersion={app.latestPublishedAppVersion}
          prepareVersion={version}
        />
      );

    return <Attribute attribute={attribute} version={version} />;
  });
};

const AppCreateVersion = ({ app, versionId, onChangeApp }) => {
  const [showRefusalReason, setShowRefusalReason] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    latestPublishedAppVersion: publishVersion = {},
    prepareSubmissionAppVersion: prepareVersion = {},
  } = app;

  const version =
    publishVersion.id === versionId ? publishVersion : prepareVersion;

  const handleUpdateVersion = async ({ action, data }) => {
    try {
      const res = await apis.app.updateVersionStatus({
        versionId: version?.id,
        action,
        data,
      });
      if (res?.status) {
        onChangeApp();

        switch (action) {
          case VERSION_ACTION.APPROVE:
            enqueueSnackbar(t('approveVersionSuccess'), { variant: 'success' });
            break;
          case VERSION_ACTION.UN_BAN:
            enqueueSnackbar(t('unBanVersionSuccess'), { variant: 'success' });
            break;
          case VERSION_ACTION.REJECT:
            enqueueSnackbar(t('rejectVersionSuccess'), { variant: 'success' });
            break;
          case VERSION_ACTION.BAN:
            enqueueSnackbar(t('banVersionSuccess'), { variant: 'success' });
            break;
          default:
            break;
        }
      } else {
        switch (action) {
          case VERSION_ACTION.APPROVE:
            enqueueSnackbar(t('approveVersionError'), { variant: 'error' });
            break;
          case VERSION_ACTION.UN_BAN:
            enqueueSnackbar(t('unBanVersionError'), { variant: 'error' });
            break;
          case VERSION_ACTION.REJECT:
            enqueueSnackbar(t('rejectVersionError'), { variant: 'error' });
            break;
          case VERSION_ACTION.BAN:
            enqueueSnackbar(t('banVersionError'), { variant: 'error' });
            break;
          default:
            break;
        }
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  const renderActionButtons = () => {
    if ([APP_VERSION_STATUS.WAITING_REVIEW].includes(version?.status))
      return (
        <Box display="flex" gap={1}>
          <Button
            variant="contained"
            onClick={() =>
              handleUpdateVersion({ action: VERSION_ACTION.APPROVE })
            }
            className="action-button"
          >
            {t('approval')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowRefusalReason(true)}
            className="action-button"
          >
            {t('refusal')}
          </Button>
        </Box>
      );
    if ([APP_VERSION_STATUS.PUBLISHED].includes(version?.status))
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowRefusalReason(true)}
          className="action-button"
        >
          {t('ban')}
        </Button>
      );

    if ([APP_VERSION_STATUS.BANNED].includes(version?.status))
      return (
        <Button
          variant="outlined"
          onClick={() => handleUpdateVersion({ action: VERSION_ACTION.UN_BAN })}
          className="action-button"
        >
          {t('unBan')}
        </Button>
      );

    return <></>;
  };

  return (
    <StyledAppDetailInfo>
      <Box className="header-card">
        <Box className="header-card-title">
          <Typography variant="h3">
            {`${t('version')} ${version?.version}`}
          </Typography>
          {renderActionButtons()}
        </Box>
        {version?.status === APP_VERSION_STATUS.PUBLISHED && (
          <Typography variant="subtitle1">
            {t('latestPublishedAppVersion')}
          </Typography>
        )}
      </Box>
      <Divider />
      <Version app={app} version={version} />
      <Divider />
      <RefusalReasonPopup
        version={version}
        open={showRefusalReason}
        onClose={() => setShowRefusalReason(false)}
        updateVersion={handleUpdateVersion}
      />
    </StyledAppDetailInfo>
  );
};

export default AppCreateVersion;
