import React, { useRef, useState } from 'react';

import { Delete } from '@mui/icons-material';
import { StyledImagePreview } from './index.style';

const PreviewImage = ({ image, onDelete, onImageLoad, disabled }) => {
  const imgRef = useRef(null);

  const [isHover, setIsHover] = useState(false);

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(image, e);
  };

  const handleImageLoad = () => {
    onImageLoad(imgRef);
  };

  const zoomImage = (e) => {
    e.preventDefault();
    window.open(image);
  };

  return (
    <StyledImagePreview
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <img
        ref={imgRef}
        src={image}
        className="preview-image"
        alt="banner"
        onLoad={handleImageLoad}
        onClick={zoomImage}
      />
      {isHover && !disabled && (
        <div
          onClick={handleDelete}
          role="button"
          tabIndex="0"
          className="delete-image"
        >
          <Delete color="error" fontSize="small" />
        </div>
      )}
    </StyledImagePreview>
  );
};

export default PreviewImage;
