import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Button, Grid } from '@mui/material';
import { DEFAULT_CATEGORY_IMAGE } from '@src/constants';

import { StyledCategorySelect } from './index.style';

const PageSelect = ({
  onSelectPage = () => {},
  pages = [],
  selectedPage,
  disabled,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectPage = (page) => {
    if (disabled) {
      enqueueSnackbar(t('changeBlockedAction'), { variant: 'warning' });
      return;
    }
    onSelectPage(page);
  };

  return (
    <StyledCategorySelect>
      <Grid container spacing={2} id="page-selected">
        {Object.values(pages)?.map((page) => (
          <Grid item xs={3} lg={2} key={page.key}>
            <Button
              className={`category ${
                selectedPage?.key === page?.key ? 'active' : ''
              }`}
              onClick={() => handleSelectPage(page)}
            >
              <img
                src={page.imageUrl || DEFAULT_CATEGORY_IMAGE}
                height="20px"
                width="20px"
                layout="fill"
                loading="lazy"
                alt="banner"
              />
              <div className="name">{page.name}</div>
            </Button>
          </Grid>
        ))}
      </Grid>
    </StyledCategorySelect>
  );
};

export default PageSelect;
