import {
  ERROR_CODE_SYSTEM,
  SERVICE,
  ERROR_CODE_ACCOUNT_SERVICE,
  ERROR_CODE_API_MANAGEMENT_SERVICE,
} from '@src/constants';

const ACCOUNT_ERROR_MESSAGE = {
  // ERROR CODE USER: 1000 - 1999
  [ERROR_CODE_ACCOUNT_SERVICE.USER_EXISTS]: 'userExists',
  [ERROR_CODE_ACCOUNT_SERVICE.EMAIL_OR_PASSWORD_WRONG]: 'emailOrPasswordWrong',
  [ERROR_CODE_ACCOUNT_SERVICE.USER_NOT_FOUND]: 'userNotFound',
  [ERROR_CODE_ACCOUNT_SERVICE.INVALID_USER]: 'invalidUser',

  // ERROR CODE GROUP PERMISSION: 2000 - 2999
  [ERROR_CODE_ACCOUNT_SERVICE.GROUP_PERMISSION_EXISTS]: 'groupPermissionExists',
  [ERROR_CODE_ACCOUNT_SERVICE.GROUP_PERMISSION_NOT_FOUND]:
    'groupPermissionNotFound',
  [ERROR_CODE_ACCOUNT_SERVICE.GROUP_PERMISSION_HAS_PERMISSIONS]:
    'groupPermissionHasPermissions',

  // ERROR CODE PERMISSION: 3000 - 3999
  [ERROR_CODE_ACCOUNT_SERVICE.PERMISSION_EXIST]: 'permissionExists',
  [ERROR_CODE_ACCOUNT_SERVICE.PERMISSION_NOT_FOUND]: 'permissionNotFound',

  // ERROR CODE IAM: 4000 - 4199
  [ERROR_CODE_ACCOUNT_SERVICE.IAM_GET_ACCESS_TOKEN_ERROR]:
    'iamGetAccessTokenError',
  [ERROR_CODE_ACCOUNT_SERVICE.IAM_CREATE_ROLE_ERROR]: 'iamCreateRoleError',
  [ERROR_CODE_ACCOUNT_SERVICE.IAM_DELETE_ROLE_ERROR]: 'iamDeleteRoleError',

  // ERROR CODE ROLE: 4200 - 4399
  [ERROR_CODE_ACCOUNT_SERVICE.ROLE_EXIST]: 'roleExists',
  [ERROR_CODE_ACCOUNT_SERVICE.ROLE_NOT_FOUND]: 'roleNotFound',
  [ERROR_CODE_ACCOUNT_SERVICE.ROLE_ASSIGNED_FOR_USER]: 'roleAssignedForUser',

  // ERROR CODE ORGANIZATION: 4300 - 4399
  [ERROR_CODE_ACCOUNT_SERVICE.ORGANIZATION_NOT_FOUND]: 'organizationNotFound',
  [ERROR_CODE_ACCOUNT_SERVICE.ORGANIZATION_NAME_EXIST]: 'organizationNameExist',
  [ERROR_CODE_ACCOUNT_SERVICE.OWNER_INACTIVE]: 'ownerInactive',
};

const API_MANAGEMENT_ERROR_MESSAGE = {
  // ERROR CODE USER: 1000 - 1999
  [ERROR_CODE_API_MANAGEMENT_SERVICE.PACKAGE_EXIST]: 'packageExists',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.PACKAGE_NOT_FOUND]: 'packageNotFound',

  // ERROR CODE PACKAGE: 4500 - 4599
  [ERROR_CODE_API_MANAGEMENT_SERVICE.PACKAGES_NUMBER_NOT_MATCH]:
    'packagesNumberNotMatch',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.PACKAGES_DUPLICATE_RANK]:
    'packagesDuplicateRank',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.PACKAGE_NAME_EXIST]: 'packageNameExist',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.PACKAGE_CODE_EXIST]: 'packageCodeExist',

  // ERROR CODE GROUP PERMISSION: 4600 - 4699
  [ERROR_CODE_API_MANAGEMENT_SERVICE.SPECIAL_GROUP_EXIST]: 'specialGroupExists',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.SPECIAL_GROUP_NOT_FOUND]:
    'specialGroupNotFound',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.SPECIAL_GROUPS_DUPLICATE_RANK]:
    'specialGroupsDuplicateRank',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.GROUP_NUMBER_NOT_MATCH]:
    'groupNumberNotMatch',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.RANK_EXCEED_LIMIT]: 'rankExceedLimit',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.SPECIAL_GROUPS_DUPLICATE]:
    'specialGroupsDuplicate',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.DUPLICATE_APP_IN_GROUP]:
    'duplicateAppInGroup',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.DUPLICATE_APP_RANK]: 'duplicateAppRank',

  // ERROR CODE PAGE: 4700 - 4799
  [ERROR_CODE_API_MANAGEMENT_SERVICE.PAGE_NOT_FOUND]: 'pageNotFound',

  // ERROR CODE BANNER: 4800 - 4899
  [ERROR_CODE_API_MANAGEMENT_SERVICE.BANNER_NOT_FOUND]: 'bannerNotFound',

  // ERROR CODE ARTICLE GROUP: 4900 - 4999
  [ERROR_CODE_API_MANAGEMENT_SERVICE.ARTICLE_GROUP_NOT_FOUND]:
    'articleGroupNotFound',

  // ERROR CODE ARTICLE: 5000 - 5099
  [ERROR_CODE_API_MANAGEMENT_SERVICE.ARTICLE_NOT_FOUND]: 'articleNotFound',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.DUPLICATE_ARTICLE_IN_GROUP]:
    'duplicateArticleInGroup',
  [ERROR_CODE_API_MANAGEMENT_SERVICE.DUPLICATE_ARTICLE_RANK]:
    'duplicateArticleRank',
};

const getErrorMessage = (code, service, serverErrorMessage) => {
  let message;

  switch (service) {
    case SERVICE.ACCOUNT:
      message = ACCOUNT_ERROR_MESSAGE[code];
      break;

    case SERVICE.API_MANAGEMENT:
      message = API_MANAGEMENT_ERROR_MESSAGE[code];
      break;
    default:
  }

  if (message) return message;

  switch (code) {
    case ERROR_CODE_SYSTEM.BAD_REQUEST:
      return 'badRequest';
    case ERROR_CODE_SYSTEM.UNAUTHORIZED:
      return 'unauthorized';
    case ERROR_CODE_SYSTEM.FORBIDDEN:
      return 'forbidden';
    case ERROR_CODE_SYSTEM.NOT_FOUND:
      return 'notFound';

    default: {
      if (serverErrorMessage) return serverErrorMessage;
      return 'serverError';
    }
  }
};

export default getErrorMessage;
