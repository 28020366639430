import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import Popup from '@src/components/Popup';
import { Delete } from '@mui/icons-material';
import apis from '@src/apis';

const DeleteUser = ({ id, name, setListData, onClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);

  const handleDelete = async () => {
    try {
      const res = await apis.admin.deleteUser(id);
      if (res) {
        setListData((prevData) => ({
          ...prevData,
          admins: prevData.admins.filter((item) => item.id !== id),
          pagination: {
            ...prevData.pagination,
            total: prevData.pagination.total - 1,
          },
        }));
        setOpenDelete(false);
        onClose();
        enqueueSnackbar(t('deleteUserSuccess'), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        size="large"
        color="error"
        startIcon={<Delete />}
        onClick={() => setOpenDelete(true)}
      >
        {t('delete')}
      </Button>
      <Popup
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onOk={handleDelete}
        title={t('areYouSureDeleteUser', { name })}
      />
    </>
  );
};

export default DeleteUser;
