import { SERVICE } from '@src/constants';
import api from './api';

export const getRoles = async (params) => {
  const res = await api({
    method: 'GET',
    url: `/admin/roles`,
    params,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const createRole = async (data) => {
  const res = await api({
    method: 'POST',
    url: `/admin/roles`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const updateRole = async (roleId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/roles/${roleId}`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const deleteRole = async (roleId) => {
  const res = await api({
    method: 'DELETE',
    url: `/admin/roles/${roleId}`,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const updateRolePermissions = async (roleId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/roles/${roleId}/assign-permissions`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};
