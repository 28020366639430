import { SERVICE } from '@src/constants';
import api from './api';

export const createPackage = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/admin/packages',
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getPackages = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/admin/packages',
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getPackage = async (packageId) => {
  const res = await api({
    method: 'GET',
    url: `/admin/packages/${packageId}`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const updatePackage = async (packageId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/packages/${packageId}`,
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const updatePackagesRank = async (data) => {
  const res = await api({
    method: 'PUT',
    url: '/admin/packages/update-rank',
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const deletePackage = async (packageId) => {
  const res = await api({
    method: 'DELETE',
    url: `/admin/packages/${packageId}`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
