import { actionTypes } from './actions';

export const initialState = {
  routeBack: '',
};

const routeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_BACK_ROUTE: {
      const { routeBack } = action;
      return { ...state, routeBack };
    }
    default:
      return state;
  }
};

export default routeReducer;
