import { styled } from '@mui/material/styles';
import { Box, TableCell } from '@mui/material';
import { COLOR } from '@src/styles/color';

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    background-color: ${COLOR.standardBlue[8]};
    font-weight: 510;
    font-size: 14px;
    white-space: nowrap;
    color: ${COLOR.darkestBlue[100]};
    border: ${(props) =>
      props.border ? `1px solid ${COLOR.charcoal[64]}` : 'none'};
    padding: 16px 12px;
    backdrop-filter: blur(100px);
  }

  &.MuiTableCell-body {
    font-size: 14px;
    font-weight: 400;
    border: ${(props) =>
      props.border ? `1px solid ${COLOR.charcoal[64]}` : 'none'};
    border-bottom: 1px solid ${COLOR.charcoal[64]};
    color: ${COLOR.blueGray[100]};
    padding: 12px;
  }

  &.icon-button {
    padding: 5px;
    background: ${COLOR.white[100]};
    color: ${COLOR.charcoal[16]};

    &:hover {
      color: ${COLOR.blueGray[100]};
      background: none;
    }
  }
`;

export const StyledCustomTable = styled(Box)`
  .table-cell {
    font-weight: 500;
    font-size: 16px;
  }

  .table-row {
    cursor: pointer;
    background-color: ${COLOR.white[100]};

    &:hover {
      background: ${COLOR.darkBlue[4]};
    }
  }

  .table-container {
    border-radius: 16px;

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;
