import * as groupPermission from './groupPermission';
import * as permission from './permission';
import * as role from './role';
import * as admin from './admin';
import * as user from './user';
import * as csp from './csp';
import * as auth from './auth';
import * as app from './app';
import * as category from './category';
import * as packages from './package';
import * as specialGroup from './specialGroup';
import * as transaction from './transaction';
import * as upload from './upload';
import * as page from './page';
import * as banner from './banner';
import * as articleGroup from './articleGroup';
import * as userPackage from './userPackage';
import * as article from './article';
import * as report from './report';
import * as stat from './stat';

export default {
  groupPermission,
  permission,
  role,
  admin,
  user,
  csp,
  auth,
  app,
  category,
  packages,
  specialGroup,
  transaction,
  upload,
  page,
  banner,
  articleGroup,
  userPackage,
  article,
  report,
  stat,
};
