import { combineReducers } from 'redux';
import user, { initialState as userInitialState } from './user/reducer';
import route, { initialState as routeInitialState } from './route/reducer';

export const initialState = {
  user: userInitialState,
  route: routeInitialState,
};

export default combineReducers({ user, route });
