import { SERVICE } from '@src/constants';
import api from './api';

export const getMe = async (accessToken) => {
  const res = await api({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: '/users/me',
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const getUsers = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/users',
    params,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const checkUserExist = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/users/user-exist',
    params,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const getUser = async (userId) => {
  const res = await api({
    method: 'GET',
    url: `/users/${userId}`,
    source: SERVICE.ACCOUNT,
  });
  return res;
};
