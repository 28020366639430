import { Box, Divider } from '@mui/material';
import { useParams, matchPath } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import apis from '@src/apis';
import { useSnackbar } from 'notistack';
import ROUTES from '@src/constants/route';
import TitleApp from './TitleApp';
import SidebarApp from './SidebarApp';
import { StyledAppDetail } from './index.style';
import AppCreateVersion from '../AppCreateVersion';
import AppDetailInfo from '../AppDetailInfo';
import AppPackagesManagement from '../AppPackagesManagement';

const AppDetail = () => {
  const { t } = useTranslation();
  const [app, setApp] = useState({});
  const { appId, versionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const currentPath = window.location.pathname;

  const fetchApp = async () => {
    try {
      const res = await apis.app.getApp(appId);
      if (res?.status) setApp(res?.result);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const renderContent = () => {
    if (matchPath(currentPath, ROUTES.APP_DETAIL_INFO))
      return <AppDetailInfo app={app} />;

    if (matchPath(currentPath, ROUTES.APP_PACKAGES_MANAGEMENT))
      return <AppPackagesManagement app={app} />;

    return (
      <AppCreateVersion
        app={app}
        versionId={versionId}
        onChangeApp={fetchApp}
      />
    );
  };

  useEffect(() => {
    fetchApp();
  }, []);

  const getImageUrl = () => {
    if (app?.latestPublishedAppVersion?.appImageUrl) {
      return app?.latestPublishedAppVersion?.appImageUrl;
    }
    return app?.prepareSubmissionAppVersion?.appImageUrl;
  };

  return (
    <StyledAppDetail>
      <TitleApp name={app?.name} imgSrc={getImageUrl()} />
      <Divider />
      <Box className="content">
        <Box className="sidebar-box">
          <SidebarApp
            preVersion={app?.prepareSubmissionAppVersion}
            lastVersion={app?.latestPublishedAppVersion}
            appId={app?.id}
            app={app}
            versionId={versionId}
          />
        </Box>
        <Box p={2} flexGrow={1}>
          {renderContent()}
        </Box>
      </Box>
    </StyledAppDetail>
  );
};

export default AppDetail;
