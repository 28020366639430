import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import { ALL } from '@src/constants';
import debounce from '@src/utils/debounce';
import CreateAdminPopup from './CreateAdminPopup';
import { StyleFilterUser } from './index.style';

const ButtonCreateAdmin = ({ roles, setData }) => {
  const { t } = useTranslation();
  const [showPopupCreateAdmin, setShowPopupCreateAdmin] = useState(false);

  return (
    <Box marginLeft="auto">
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setShowPopupCreateAdmin(true)}
      >
        {t('add')}
      </Button>
      <CreateAdminPopup
        open={showPopupCreateAdmin}
        onClose={() => setShowPopupCreateAdmin(false)}
        roles={roles}
        setListData={setData}
      />
    </Box>
  );
};

const FilterAdmin = ({ filter, setFilter, roles, setData }) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState(filter.inputText);

  const handleChangeInputText = (e) => {
    setInputText(e.target.value);
    debounce(() => setFilter({ ...filter, inputText: e.target.value }))();
  };

  useEffect(() => {
    setInputText(filter.inputText);
  }, [filter.inputText]);

  return (
    <StyleFilterUser>
      <Typography variant="h3">{t('listAdmin')}</Typography>
      <ButtonCreateAdmin roles={roles} setData={setData} />
      <TextField
        select
        label={filter.roleId === null ? t('role') : ''}
        variant="outlined"
        size="small"
        className="select"
        value={filter.roleId}
        onChange={(e) => setFilter({ ...filter, roleId: e.target.value })}
        InputLabelProps={{ shrink: false }}
      >
        <MenuItem value={ALL}>{t('all')}</MenuItem>
        {roles.map((role) => (
          <MenuItem key={role.id} value={role.id}>
            {role.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        size="small"
        variant="outlined"
        className="input-search"
        placeholder={t('searchAdmin')}
        value={inputText}
        onChange={handleChangeInputText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="primary" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    </StyleFilterUser>
  );
};

export default FilterAdmin;
