import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import camelcase from 'camelcase';
import { Box, Button, Chip, Typography } from '@mui/material';
import {
  Add,
  AutorenewRounded,
  Delete,
  FiberManualRecord,
  SaveRounded,
} from '@mui/icons-material';
import moment from 'moment';
import CustomTable from '@src/components/CustomTable';
import apis from '@src/apis';
import { COLOR } from '@src/styles/color';
import Popup from '@src/components/Popup';
import { DATE_TIME_FORMAT } from '@src/constants';
import Tooltip from '@src/components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { StyledAppPackages } from './index.style';
import CreatePackage from './CreatePackage';

const AppPackagesManagement = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const { appId } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const [openCreatePackage, setOpenCreatePackage] = useState(false);
  const [appPackages, setAppPackages] = useState([]);
  const [appPackagesApi, setAppPackagesApi] = useState([]);
  const [packageSelected, setPackageSelected] = useState('');
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] = useState(false);
  const [openConfirmUpdatePopup, setOpenConfirmUpdatePopup] = useState(false);
  const [deletePkg, setDeletePkg] = useState({});
  const [app, setApp] = useState({});
  const [loading, setLoading] = useState(false);
  const [openActivePopup, setOpenActivePopup] = useState(false);
  const [packageActiveSelected, setPackageActiveSelected] = useState({});

  const isChangeRanks = () => {
    for (let i = 0; i < appPackages.length; i += 1) {
      if (appPackages[i]?.id !== appPackagesApi[i]?.id) return true;
    }
    return false;
  };

  const handleOpenCreatePackage = () => {
    setOpenCreatePackage(true);
  };

  const handleCloseCreatePackage = () => {
    setOpenCreatePackage(false);
    setPackageSelected('');
  };

  const handleSelectPackage = (item) => {
    setPackageSelected(item.id);
    setOpenCreatePackage(true);
  };

  const fetchApp = async () => {
    try {
      const res = await apis.app.getApp(appId);
      if (res?.status) setApp(res?.result);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const fetchAppPackages = async () => {
    try {
      setLoading(true);
      const res = await apis.packages.getPackages({
        appId,
        sort: ['rank_asc'],
      });
      if (!res?.status) throw new Error();

      let count = res?.result?.filter((item) => item.rank).length;
      const data = res?.result.map((item) => {
        count += 1;
        return {
          ...item,
          rank: item?.rank || count,
        };
      });

      setAppPackages(data);
      setAppPackagesApi(data);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSavePackageSuccess = async () => {
    await fetchAppPackages();
    await fetchApp();
  };

  useEffect(() => {
    fetchAppPackages();
    fetchApp();
  }, []);

  const heads = [
    {
      label: t('position'),
      valueName: 'rank',
      align: 'center',
    },
    {
      label: t('packageName'),
      valueName: 'name',
      align: 'left',
    },
    {
      label: t('packageCode'),
      valueName: 'packageCode',
      align: 'center',
    },
    {
      label: t('money'),
      valueName: 'price',
      align: 'right',
    },
    {
      label: t('paymentMethod'),
      valueName: 'paymentMethod',
      align: 'center',
    },
    {
      label: t('displayOption'),
      valueName: 'displayOption',
      align: 'center',
    },
    {
      label: t('status'),
      valueName: 'status',
      align: 'center',
    },
    {
      label: t('actions'),
      valueName: 'action',
      align: 'center',
    },
  ];

  const handleReorder = (items) => {
    const copyListItems = items.map(({ id }, index) => {
      const item = appPackages.find((el) => el.id === id);
      return { ...item, rank: index + 1 };
    });
    setAppPackages(copyListItems);
  };

  const handleClickDeletePkg = (pkg) => {
    if (isChangeRanks()) return;
    setDeletePkg(pkg);
    setOpenConfirmDeletePopup(true);
  };

  const handleOpenActivePopup = (e, item) => {
    e.stopPropagation();
    setPackageActiveSelected(item);
    setOpenActivePopup(true);
  };

  const handleCloseActivePopup = (e) => {
    if (e) e.stopPropagation();

    setOpenActivePopup(false);
  };

  const formatData = appPackages.map((el) => ({
    ...el,
    price: el?.price ? `${el?.price.toLocaleString(language)}đ` : '--',
    status: (
      <Chip
        label={el?.active ? t('active') : t('deactive')}
        color={el?.active ? 'success' : 'error'}
      />
    ),
    paymentMethod: el?.paymentMethod ? t(camelcase(el?.paymentMethod)) : '--',
    displayOption: el?.displayOption ? t(camelcase(el?.displayOption)) : '--',
    action: (
      <Box display="flex" gap={2} alignItems="center" justifyContent="center">
        <Tooltip
          title={t(el?.active ? 'lock' : 'unlock')}
          arrow
          placement="top"
        >
          <FontAwesomeIcon
            icon={el?.active ? faLock : faLockOpen}
            color={el?.active ? COLOR.error.base : COLOR.darkestBlue[64]}
            size="lg"
            onClick={(event) => handleOpenActivePopup(event, el)}
          />
        </Tooltip>
        <Delete
          color={`${isChangeRanks() ? 'inactive' : 'error'}`}
          onClick={() => handleClickDeletePkg(el)}
        />
      </Box>
    ),
  }));

  const handleChangeActivePackage = async () => {
    try {
      const res = await apis.packages.updatePackage(packageActiveSelected.id, {
        active: !packageActiveSelected?.active,
      });

      if (!res?.status) throw new Error();

      const message = packageActiveSelected?.active
        ? 'inactivePackageSuccess'
        : 'activePackageSuccess';

      enqueueSnackbar(t(message), {
        variant: 'success',
      });

      handleCloseActivePopup();

      await handleSavePackageSuccess();
    } catch (e) {
      enqueueSnackbar(t(e.message), { variant: 'error' });
    }
  };

  const handleDeletePkg = async () => {
    try {
      const res = await apis.packages.deletePackage(deletePkg.id);
      if (!res?.status) throw new Error();

      await fetchAppPackages();
      await fetchApp();
      enqueueSnackbar(t('deletePackageSuccess'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleClickUpdatePackages = () => {
    setOpenConfirmUpdatePopup(true);
  };

  const handleCloseUpdatePackages = () => {
    setOpenConfirmUpdatePopup(false);
  };

  const handleCloseConfirmPopup = () => {
    setOpenConfirmDeletePopup(false);
    setDeletePkg({});
  };

  const handleResetAppPackages = () => {
    setAppPackages(appPackagesApi);
  };

  const updatePackageRank = async () => {
    try {
      const packages = appPackages.map((item) => ({
        id: item.id,
        rank: item.rank,
      }));
      const res = await apis.packages.updatePackagesRank({
        appId,
        packages,
      });
      if (!res?.status) throw new Error();

      await fetchAppPackages();
      await fetchApp();
      enqueueSnackbar(t('updatePackageRankSuccess'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <StyledAppPackages>
      <Box className="header-card">
        <Box className="header-card-title">
          <Typography variant="h3">{t('packagesList')}</Typography>
          <Button
            variant="contained"
            onClick={handleOpenCreatePackage}
            disabled={isChangeRanks()}
          >
            <Add fontSize="small" />
            {t('addPackage')}
          </Button>
        </Box>
        <Box className="author-info">
          <Typography variant="caption" color="secondary">
            {t('updateLatestBy')}:{' '}
            {app?.latestPackageUpdated?.updatedBy?.name || '--'}
          </Typography>
          <FiberManualRecord sx={{ width: '6px', height: '6px' }} />
          <Typography variant="caption" color="secondary">
            {t('updateAt')}:{' '}
            {app?.latestPackageUpdated?.updatedAt
              ? moment(app?.latestPackageUpdated?.updatedAt).format(
                  DATE_TIME_FORMAT,
                )
              : '--'}
          </Typography>
        </Box>
        <Typography variant="caption" color="secondary">
          {t('packagesListNote')}
        </Typography>
      </Box>
      <Box className="body-card">
        <CustomTable
          items={formatData}
          heads={heads}
          disablePagination
          onClickRow={handleSelectPackage}
          isStickyHeader
          maxHeight="500px"
          reorderable
          onReorder={handleReorder}
          loading={loading}
        />
      </Box>
      <Box className="footer-card">
        <Button
          variant="outlined"
          color="primary"
          className="button"
          onClick={handleResetAppPackages}
          disabled={!isChangeRanks()}
        >
          <AutorenewRounded fontSize="small" sx={{ rotate: '45deg' }} />
          {t('reset')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="button"
          onClick={handleClickUpdatePackages}
          disabled={!isChangeRanks()}
        >
          <SaveRounded fontSize="small" />
          {t('save')}
        </Button>
      </Box>
      <CreatePackage
        open={openCreatePackage}
        onClose={handleCloseCreatePackage}
        onSavePackageSuccess={handleSavePackageSuccess}
        packageId={packageSelected}
        appId={appId}
        disable={isChangeRanks()}
      />
      <Popup
        open={openConfirmDeletePopup}
        onClose={handleCloseConfirmPopup}
        title={t('confirmDeletePackage')}
        content={t('deletePackageConfirm', { name: deletePkg?.packageCode })}
        okMessage={t('yes')}
        cancelMessage={t('no')}
        onOk={handleDeletePkg}
        titleColor={COLOR.warning.base}
      />
      <Popup
        open={openConfirmUpdatePopup}
        onClose={handleCloseUpdatePackages}
        title={t('confirm')}
        content={t('updateConfirm')}
        okMessage={t('yes')}
        cancelMessage={t('no')}
        onOk={updatePackageRank}
      />
      <Popup
        open={openActivePopup}
        onClose={handleCloseActivePopup}
        title={t('confirm')}
        content={t(
          packageActiveSelected?.active ? 'inactivePackage' : 'activePackage',
          {
            name: packageActiveSelected?.name,
          },
        )}
        okMessage={t('confirm')}
        cancelMessage={t('cancel')}
        onOk={handleChangeActivePackage}
        titleColor={COLOR.warning.base}
      />
    </StyledAppPackages>
  );
};

export default AppPackagesManagement;
