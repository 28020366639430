import { SERVICE } from '@src/constants';
import api from './api';

export const createArticle = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/admin/articles',
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getArticle = async (articleId) => {
  const res = await api({
    method: 'GET',
    url: `/admin/articles/${articleId}`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const updateArticle = async (articleId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/articles/${articleId}`,
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const saveDraftArticle = async (articleId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/articles/${articleId}/draft`,
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
