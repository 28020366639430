import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Box, Button, TextField, Typography } from '@mui/material';
import CustomerDrawer from '@src/components/CustomDrawer';
import CustomSelect from '@src/components/CustomSelect';
import { API_METHOD, PERMISSION_TYPE } from '@src/constants';
import apis from '@src/apis';

const INITIAL_STATE = {
  name: '',
  groupPermission: '',
  uri: '',
  method: '',
  type: '',
};

const DrawerCreatePermission = ({
  open,
  onClose,
  permissionSelected,
  groupPermissions,
  onCreatePermission,
  roleType,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [permissionData, setPermissionData] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState({});

  const handleChange = ({ name, value }) => {
    setPermissionData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleReset = () => {
    setPermissionData(permissionSelected || INITIAL_STATE);
  };

  const handleClose = () => {
    onClose();
  };

  const validatePermissionData = () => {
    const { name, groupPermission, uri, method, type } = permissionData;

    let errorApp = {};

    if (!name) {
      errorApp.name = 'fieldRequired';
    }

    if (!groupPermission) {
      errorApp.groupPermission = 'fieldRequired';
    }

    if (!uri) {
      errorApp.uri = 'fieldRequired';
    }

    if (!method) {
      errorApp.method = 'fieldRequired';
    }

    if (!type) {
      errorApp.type = 'fieldRequired';
    }

    errorApp = { ...errors, ...errorApp };

    const checkExistError = Object.values(errorApp).find((err) => err);
    if (checkExistError) {
      setErrors(errorApp);
      return false;
    }

    return true;
  };

  const handleCreate = async () => {
    if (!validatePermissionData()) return;
    try {
      const { name, groupPermission, uri, method, type } = permissionData;

      let res;
      if (permissionSelected) {
        res = await apis.permission.updatePermission(permissionSelected.id, {
          name,
          type,
          uri,
          method,
          groupPermissionId: groupPermission,
          roleType,
        });
      } else {
        res = await apis.permission.createPermission({
          name,
          type,
          uri,
          method,
          groupPermissionId: groupPermission,
          roleType,
        });
      }

      if (!res) throw new Error('serverError');
      handleClose();
      onCreatePermission();
      enqueueSnackbar(
        t(
          !permissionSelected
            ? 'createPermissionSuccess'
            : 'updatePermissionSuccess',
        ),
        {
          variant: 'success',
        },
      );
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (!open) {
      setPermissionData({});
      setErrors({});
    }
  }, [open]);

  useEffect(() => {
    if (open && permissionSelected) {
      setPermissionData(permissionSelected);
    }
  }, [permissionSelected]);

  return (
    <CustomerDrawer
      open={open}
      onClose={handleClose}
      title={permissionSelected ? t('updatePermission') : t('addPermission')}
    >
      <Box>
        <Typography>{t('permissionName')}</Typography>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder={t('permissionNamePlaceholder')}
          name="name"
          value={permissionData.name}
          onChange={(e) => handleChange(e.target)}
        />
      </Box>
      <Box marginTop={1.5}>
        <Typography>{t('groupPermission')}</Typography>
        <CustomSelect
          placeholder={t('chooseGroupPermission')}
          options={groupPermissions.map((groupPermission) => ({
            value: groupPermission.id,
            label: t(groupPermission.name),
          }))}
          value={permissionData.groupPermission}
          onChange={(value) => handleChange({ name: 'groupPermission', value })}
          helperText={errors.groupPermission && t(errors.groupPermission)}
          error={Boolean(errors.groupPermission)}
        />
      </Box>
      <Box marginTop={1.5}>
        <Typography>{t('path')}</Typography>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder={t('permissionPathPlaceholder')}
          name="uri"
          value={permissionData.uri}
          onChange={(e) => handleChange(e.target)}
        />
      </Box>
      <Box marginTop={1.5}>
        <Typography>{t('method')}</Typography>
        <CustomSelect
          placeholder={t('chooseMethod')}
          options={Object.values(API_METHOD).map((method) => ({
            value: method,
            label: method,
          }))}
          value={permissionData.method}
          onChange={(value) => handleChange({ name: 'method', value })}
          helperText={errors.method && t(errors.method)}
          error={Boolean(errors.method)}
        />
      </Box>
      <Box marginTop={1.5}>
        <Typography className="title">{t('permissionType')}</Typography>
        <CustomSelect
          placeholder={t('choosePermissionType')}
          options={Object.values(PERMISSION_TYPE).map((permissionType) => ({
            value: permissionType,
            label: permissionType,
          }))}
          value={permissionData.type}
          onChange={(value) => handleChange({ name: 'type', value })}
          helperText={errors.type && t(errors.type)}
          error={Boolean(errors.type)}
        />
      </Box>
      <Box display="flex" marginTop={3} gap={1}>
        <Button variant="contained" onClick={handleCreate}>
          {t(permissionSelected ? 'save' : 'createNow')}
        </Button>
        <Button variant="outlined" onClick={handleReset}>
          {t('reset')}
        </Button>
      </Box>
    </CustomerDrawer>
  );
};

export default DrawerCreatePermission;
