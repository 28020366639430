import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Add, InfoRounded } from '@mui/icons-material';
import apis from '@src/apis';
import { regexPhone, regexUrl } from '@src/constants/regex';
import { LoadingButton } from '@mui/lab';
import { COLOR } from '@src/styles/color';
import { useSnackbar } from 'notistack';
import debounce from '@src/utils/debounce';
import { CustomDatePicker } from '@src/components/CustomDatePickerRange';
import { ALL, DATE_TIME_PICKER_TYPES } from '@src/constants';
import { getChangedFields } from '@src/utils/object';
import { StyleDialogCreate } from './index.style';
import CreateOwner from './CreateOwner';

const CreateCspPopup = ({ open, onClose, item, fetchData, setFilter }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const INITIAL_STATE = {
    name: item?.name || '',
    address: item?.address || '',
    phoneNumber: item?.phoneNumber || '',
    websiteUrl: item?.websiteUrl || '',
    ownerData: item?.ownerId ? { id: item.ownerId } : {},
    companyName: item?.companyName || '',
    abbreviationName: item?.abbreviationName || '',
    representativeName: item?.representativeName || '',
    idIdentification: item?.idIdentification || '',
    effectiveTime: item?.effectiveTime || '',
    technicalFocalPoint: item?.technicalFocalPoint || '',
    businessFocalPoint: item?.businessFocalPoint || '',
    customerSuccessFocalPoint: item?.customerSuccessFocalPoint || '',
  };

  const [data, setData] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openPopupCreateOwner, setOpenPopupCreateOwner] = useState(false);
  const [owners, setOwners] = useState([]);
  const isEdit = !!item?.id;

  const handleReset = () => {
    setData(INITIAL_STATE);
    setErrors(false);
  };

  const handleClose = () => {
    onClose();
    handleReset();
  };

  const handleChangeData = (key, value) => {
    setErrors({ ...errors, [key]: '' });
    setData({ ...data, [key]: value });
  };

  const fetchOwners = async (text) => {
    try {
      const res = await apis.admin.getUsers({
        active: true,
        inputText: text,
      });
      if (res) {
        const listNewOwners = owners.filter((el) => el.isNew);
        setOwners([...listNewOwners, ...(res?.result?.data || [])]);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const validate = () => {
    const {
      name,
      companyName,
      abbreviationName,
      representativeName,
      idIdentification,
      phoneNumber,
      websiteUrl,
      ownerData,
    } = data;
    const errorData = {};

    if (!name?.trim()) {
      errorData.name = t('organizationNameRequired');
    }

    if (!companyName?.trim()) {
      errorData.companyName = t('companyNameRequired');
    }

    if (!abbreviationName?.trim()) {
      errorData.abbreviationName = t('abbreviationNameRequired');
    }

    if (!representativeName?.trim()) {
      errorData.representativeName = t('representativeNameRequired');
    }

    if (!idIdentification?.trim()) {
      errorData.idIdentification = t('idIdentificationRequired');
    }

    if (!ownerData?.id) {
      errorData.ownerData = t('ownerRequired');
    }

    if (phoneNumber && !regexPhone.test(phoneNumber)) {
      errorData.phoneNumber = t('phoneInvalid');
    }

    if (websiteUrl && !regexUrl.test(data.websiteUrl)) {
      errorData.websiteUrl = t('websiteInvalid');
    }

    if (Object.keys(errorData).length) {
      setErrors(errorData);
      return false;
    }
    return true;
  };

  const handleData = () => {
    const dataChange = getChangedFields(INITIAL_STATE, data, ['ownerData']);

    return dataChange;
  };

  const handleSubmit = async () => {
    try {
      if (!validate()) return;
      setIsSubmitting(true);
      let res;

      const dataSave = handleData();

      if (isEdit) {
        res = await apis.csp.updateOrganization(item.id, dataSave);
        await fetchData();
      } else {
        const isNewOwner = data.ownerData.isNew;
        if (isNewOwner) {
          dataSave.createOwnerData = {
            name: data.ownerData.name,
            email: data.ownerData.email,
            password: data.ownerData.password,
          };
        } else dataSave.ownerId = data.ownerData.id;
        res = await apis.csp.createOrganization(dataSave);
        setFilter({ inputText: '', active: ALL });
      }

      if (!res?.status) throw new Error();

      enqueueSnackbar(isEdit ? t('updateCSPSuccess') : t('createCSPSuccess'), {
        variant: 'success',
      });

      handleClose();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeInputText = (newInputValue) => {
    debounce(fetchOwners)(newInputValue);
  };

  useEffect(() => {
    if (open) fetchOwners();
  }, [openPopupCreateOwner, open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        sx: { borderRadius: 4, minWidth: '700px', padding: '8px 0px' },
      }}
    >
      {openPopupCreateOwner ? (
        <CreateOwner
          setOpenPopupCreateOwner={setOpenPopupCreateOwner}
          setOwners={setOwners}
          updateOwnerCsp={setData}
        />
      ) : (
        <>
          <DialogTitle>
            <Typography variant="h3">
              {isEdit ? t('updateCsp') : t('addCsp')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <StyleDialogCreate>
              <Box>
                <Typography className="label-input" variant="h6">
                  {t('nameOrganization')} <span className="required">*</span>
                </Typography>
                <TextField
                  autoFocus={!isEdit}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder={t('nameOrganization')}
                  value={data?.name}
                  onChange={(e) => handleChangeData('name', e.target.value)}
                  error={!!errors?.name}
                  helperText={errors?.name}
                />
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('companyName')} <span className="required">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('companyName')}
                    value={data?.companyName}
                    onChange={(e) =>
                      handleChangeData('companyName', e.target.value)
                    }
                    error={!!errors?.companyName}
                    helperText={errors?.companyName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('abbreviationName')} <span className="required">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('abbreviationName')}
                    value={data?.abbreviationName}
                    onChange={(e) =>
                      handleChangeData('abbreviationName', e.target.value)
                    }
                    error={!!errors?.abbreviationName}
                    helperText={errors?.abbreviationName}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('representativeName')}{' '}
                    <span className="required">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('representativeName')}
                    value={data?.representativeName}
                    onChange={(e) =>
                      handleChangeData('representativeName', e.target.value)
                    }
                    error={!!errors?.representativeName}
                    helperText={errors?.representativeName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('owner')} <span className="required">*</span>
                  </Typography>
                  <Box className={`input-box ${!!errors.ownerData && 'error'}`}>
                    {isEdit ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={
                          item.owner?.email ||
                          item.owner?.phoneNumber ||
                          item.owner?.name
                        }
                        disabled
                        size="small"
                        sx={{
                          '& .MuiInputBase-input': {
                            '-webkit-text-fill-color': COLOR.text.primary,
                          },
                        }}
                      />
                    ) : (
                      <Autocomplete
                        fullWidth
                        options={owners}
                        getOptionLabel={(option) =>
                          option?.email || option?.phoneNumber
                        }
                        onChange={(e, value) =>
                          handleChangeData('ownerData', value)
                        }
                        onInputChange={(event, newInputValue) =>
                          handleChangeInputText(newInputValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={t('owner')}
                            error={!!errors.ownerData}
                            size="small"
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option?.email || option?.phoneNumber}
                          </Box>
                        )}
                        value={
                          data.ownerData?.isNew &&
                          owners.find((el) => el.id === data.ownerData?.id)
                        }
                        noOptionsText={t('noData')}
                      />
                    )}
                    {!isEdit && (
                      <IconButton onClick={() => setOpenPopupCreateOwner(true)}>
                        <Add color="primary" />
                      </IconButton>
                    )}
                  </Box>
                  {!!errors.ownerData && (
                    <Typography
                      color="error"
                      variant="caption1"
                      className="error-text"
                    >
                      {errors.ownerData}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('idIdentification')} <span className="required">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('idIdentification')}
                    value={data?.idIdentification}
                    onChange={(e) =>
                      handleChangeData('idIdentification', e.target.value)
                    }
                    error={!!errors?.idIdentification}
                    helperText={errors?.idIdentification}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('effectiveTime')}
                    <Tooltip
                      title={t('effectiveTimeDesc')}
                      placement="top"
                      arrow
                    >
                      <InfoRounded fontSize="small" />
                    </Tooltip>
                  </Typography>
                  <CustomDatePicker
                    type={DATE_TIME_PICKER_TYPES.DATE}
                    selectedDate={data?.effectiveTime}
                    handleChangeDate={(value) =>
                      handleChangeData('effectiveTime', value)
                    }
                    textFieldDisable
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('phoneNumber')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('phoneNumber')}
                    value={data?.phoneNumber}
                    onChange={(e) =>
                      handleChangeData('phoneNumber', e.target.value)
                    }
                    error={!!errors?.phoneNumber}
                    helperText={errors?.phoneNumber}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('website')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="https://example.com"
                    value={data?.websiteUrl}
                    onChange={(e) =>
                      handleChangeData('websiteUrl', e.target.value)
                    }
                    error={!!errors?.websiteUrl}
                    helperText={errors?.websiteUrl}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('address')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('address')}
                    value={data?.address}
                    onChange={(e) =>
                      handleChangeData('address', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('technicalFocalPoint')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('inputNameOrPhoneNumber')}
                    value={data?.technicalFocalPoint}
                    onChange={(e) =>
                      handleChangeData('technicalFocalPoint', e.target.value)
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('businessFocalPoint')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('inputNameOrPhoneNumber')}
                    value={data?.businessFocalPoint}
                    onChange={(e) =>
                      handleChangeData('businessFocalPoint', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('customerSuccessFocalPoint')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('inputNameOrPhoneNumber')}
                    value={data?.customerSuccessFocalPoint}
                    onChange={(e) =>
                      handleChangeData(
                        'customerSuccessFocalPoint',
                        e.target.value,
                      )
                    }
                  />
                </Grid>
              </Grid>
            </StyleDialogCreate>
          </DialogContent>
          <DialogActions
            sx={{
              marginBottom: '8px',
            }}
          >
            <Button
              variant="outlined"
              onClick={isEdit ? handleReset : handleClose}
              sx={{
                minWidth: '120px',
              }}
            >
              {isEdit ? t('reset') : t('cancel')}
            </Button>
            <LoadingButton
              onClick={handleSubmit}
              loading={isSubmitting}
              variant="contained"
              disabled={!!(isEdit && !Object.keys(handleData())?.length)}
              sx={{
                minWidth: '120px',
                marginRight: '16px',
              }}
            >
              {isEdit ? t('save') : t('createNow')}
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CreateCspPopup;
