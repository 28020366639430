import React, { useState } from 'react';
import { Box, Typography, TableSortLabel, Tooltip } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CustomTable from '@src/components/CustomTable';
import useFormatNumber from '@src/hooks/useFormatNumber';
import { formatDateExport } from '@src/utils/date';
import { COLOR } from '@src/styles/color';
import { useSearchParams } from '@src/hooks';
import { REPORT_GROUP_OPTION } from '@src/constants';
import { CATEGORY_MAPPER, REPORT_CATEGORIES } from '@src/constants/chart';

const SortableHeader = ({
  order,
  orderBy,
  onRequestSort,
  label,
  attribute,
  justifyContent = 'flex-start',
  flexDirection = 'row',
}) => (
  <Box
    sx={{
      '.MuiTableSortLabel-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent,
        flexDirection,
      },
    }}
  >
    <TableSortLabel
      active={orderBy === attribute}
      direction={orderBy === attribute ? order : 'asc'}
      onClick={() => onRequestSort(attribute)}
    >
      {orderBy === attribute ? (
        <Box component="span" sx={visuallyHidden}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}

      <Typography variant="body3" sx={{ color: COLOR.darkestBlue[100] }}>
        {label}
      </Typography>
    </TableSortLabel>
  </Box>
);

const CompareData = ({ original, compare }) => {
  const { params } = useSearchParams();
  const { group, category, report, compareTo, foreignCategory } = params;

  const { t } = useTranslation();

  const { formatNumber, toLocaleString } = useFormatNumber();
  const [orderBy, setOrderBy] = useState('date');
  const [order, setOrder] = useState('desc');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const series = [
    {
      name: `Origin ${report}`,
      data: original?.series || [],
      label: <Typography>{t(`${report}ReportShortHand`)}</Typography>,
      total: original.total,
    },
  ];

  if (compareTo !== 'none')
    series.push({
      name: `Compare ${compareTo}`,
      data: compare?.series || [],
      label: <Typography>{t(`${compareTo}ReportShortHand`)}</Typography>,
      total: compare.total,
    });

  if (foreignCategory && compareTo !== 'none')
    series.unshift(
      ...Object.keys(compare[CATEGORY_MAPPER[foreignCategory]] || {})?.map(
        (key) => ({
          name: `${compareTo} ${key}`,
          label: (
            <Box>
              <Typography>{t(`${compareTo}ReportShortHand`)}</Typography>
              <Typography fontWeight={400}>
                {foreignCategory === REPORT_CATEGORIES.BY_PACKAGE
                  ? t(key)?.toUpperCase()
                  : t(key)}
              </Typography>
            </Box>
          ),
          data: compare[CATEGORY_MAPPER[foreignCategory]][key]?.series || [],
          total: compare[CATEGORY_MAPPER[foreignCategory]][key].total,
        }),
      ),
    );

  if (category)
    series.unshift(
      ...Object.keys(original[CATEGORY_MAPPER[category]] || {})?.map((key) => ({
        name: `${report} ${key}`,
        label: (
          <Box>
            <Typography>{t(`${report}ReportShortHand`)}</Typography>
            <Typography fontWeight={400}>
              {category === REPORT_CATEGORIES.BY_PACKAGE
                ? t(key)?.toUpperCase()
                : t(key)}
            </Typography>{' '}
          </Box>
        ),
        data: original[CATEGORY_MAPPER[category]][key]?.series || [],
        total: original[CATEGORY_MAPPER[category]][key].total,
      })),
    );

  const heads = [
    {
      label: (
        <SortableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          label="Thời gian"
          flexDirection="row"
          attribute="date"
        />
      ),
      valueName: 'date',
      align: 'left',
    },
    ...series.map((s) => ({
      label: (
        <SortableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          label={s.label || s.name}
          flexDirection="row-reverse"
          attribute={s.name}
        />
      ),
      valueName: s.name,
      align: 'right',
    })),
  ];

  const combineSeries = {};

  series.forEach((s) => {
    const { name, data = [] } = s;
    data.forEach(({ key, total }) => {
      combineSeries[key] = { ...combineSeries[key], [name]: total };
    });
  });

  const rows = Object.entries(combineSeries).map(([key, value]) => ({
    date: key,
    ...value,
  }));

  const sortedData = rows?.sort((a, b) => {
    const compareDates = (dateA, dateB, format) => {
      const timeA = moment(dateA, format, true).unix();
      const timeB = moment(dateB, format, true).unix();
      return order === 'asc' ? timeA - timeB : timeB - timeA;
    };

    if (orderBy === 'date') {
      if (group === REPORT_GROUP_OPTION.WEEK)
        return compareDates(a.date, b.date, 'YYYY-[w]WW');

      return compareDates(a.date, b.date);
    }

    return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
  });

  const formatData = [
    {
      ...series.reduce((acc, s) => {
        const match = [`Origin ${report}`, `Compare ${compareTo}`].includes(
          s.name,
        );
        acc[s.name] = match ? (
          <Tooltip title={toLocaleString(s.total)} placement="top-end">
            <Typography variant="h6" color={COLOR.black[100]}>
              {formatNumber(s.total)}
            </Typography>
          </Tooltip>
        ) : (
          <Tooltip title={toLocaleString(s.total)} placement="top-end">
            <Typography>{formatNumber(s.total)}</Typography>
          </Tooltip>
        );

        return acc;
      }, {}),
      date: <Typography>Tổng</Typography>,
    },
    ...sortedData?.map((el) => ({
      ...Object.keys(el).reduce((acc, k) => {
        const match = [`Origin ${report}`, `Compare ${compareTo}`].includes(k);
        acc[k] = match ? (
          <Tooltip title={toLocaleString(el[k])} placement="top-end">
            <Typography variant="h6" color={COLOR.black[100]}>
              {formatNumber(el[k])}
            </Typography>
          </Tooltip>
        ) : (
          <Tooltip title={toLocaleString(el[k])} placement="top-end">
            <Typography>{formatNumber(el[k])}</Typography>
          </Tooltip>
        );

        return acc;
      }, {}),
      date: formatDateExport(el.date),
    })),
  ];

  return (
    <CustomTable
      items={formatData}
      heads={heads}
      disablePagination
      maxWidth="calc(100vw - 315px)"
    />
  );
};

export default CompareData;
