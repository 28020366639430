import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { COLOR } from '@src/styles/color';
import useSearchParams from '@src/hooks/useSearchParams';
import apis from '@src/apis';
import CustomSelect from '@src/components/CustomSelect';
import { Box, Tooltip, Typography } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import camelcase from 'camelcase';
import SelectPopover from '@src/components/Popover/SelectPopover';
import IconPopover from '@src/components/Popover/IconPopover';
import useFormatNumber from '@src/hooks/useFormatNumber';
import { exportExcelFromTable } from '@src/utils/excel';
import {
  REPORT_GROUP_OPTION,
  START_TIME_DEFAULT,
  REPORT_END_TIME_DEFAULT,
} from '@src/constants';
import {
  REPORT_CHARTS,
  REPORT_CATEGORIES,
  CATEGORY_MAPPER,
} from '@src/constants/chart';
import { formatDateExport, formatDate } from '@src/utils/date';
import DetailStackBarChart from './DetailStackBarChart';
import DetailData from './DetailData';

const DetailReport = ({ app = {} }) => {
  const [reportData, setReportData] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const { params, addParams } = useSearchParams();
  const {
    appId,
    report = '',
    group,
    compareTo = '',
    startTime = START_TIME_DEFAULT,
    endTime = REPORT_END_TIME_DEFAULT,
    category = REPORT_CATEGORIES.TOTAL,
  } = params;

  const fetchAppReport = async () => {
    try {
      const res = await apis.report.getAppReport({
        appId,
        startTime,
        endTime,
        reports: report,
        group: group || REPORT_GROUP_OPTION.DAY,
        category: CATEGORY_MAPPER[category],
      });
      if (res?.status) setReportData(res?.result);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const { formatNumber, toLocaleString } = useFormatNumber();

  const data = reportData[camelcase(report)] || {};

  const series = [];

  const totalSeries = [
    {
      name: t(`${camelcase(report)}Report`),
      data: data?.series?.map((el) => ({ x: el.key, y: el.total })) || [],
      total: data.total,
    },
  ];

  if (category === REPORT_CATEGORIES.TOTAL) series.push(...totalSeries);

  if (category === REPORT_CATEGORIES.BY_EVENT)
    series.push(
      ...Object.entries(data?.action || {})?.map(([key, value]) => ({
        name: t(key),
        data: value?.series?.map((el) => ({ x: el.key, y: el.total })) || [],
        total: value.total,
      })),
    );

  if (category === REPORT_CATEGORIES.BY_PACKAGE)
    series.push(
      ...Object.entries(data?.package || {})?.map(([key, value]) => ({
        name: t(key)?.toUpperCase(),
        data: value?.series?.map((el) => ({ x: el.key, y: el.total })) || [],
        total: value.total,
      })),
    );

  const tableSeries = [
    ...series,
    ...(category === REPORT_CATEGORIES.TOTAL ? [] : totalSeries),
  ];

  const color = data.changed > 0 ? COLOR.success.base : 'error';

  const handleChangeReport = (value) => {
    if (
      value === REPORT_CHARTS.USER_ACTIVE &&
      category === REPORT_CATEGORIES.BY_EVENT
    ) {
      addParams({ report: value, category: REPORT_CATEGORIES.TOTAL });
      return;
    }
    addParams({ report: value });
  };

  const handleChangeGroupOption = (value) => addParams({ group: value });

  const handleChangeCategory = (value) => addParams({ category: value });

  const options = [
    REPORT_CHARTS.REVENUE,
    REPORT_CHARTS.TRANSACTION,
    REPORT_CHARTS.USER,
    REPORT_CHARTS.USER_ACTIVE,
  ].map((reportKey) => ({
    value: reportKey,
    label: t(`${camelcase(reportKey)}Report`),
  }));

  const handleExportReport = () => {
    const heads = [
      { label: 'Tên ứng dụng', valueName: 'x' },
      { label: app.name, valueName: 'y' },
      ...tableSeries.map((s) => ({ label: s.name, valueName: s.name })),
    ];

    const combineSeries = {};

    tableSeries.forEach((s) => {
      const { name, data: d } = s;
      d?.forEach(({ x, y }) => {
        combineSeries[x] = { ...combineSeries[x], [name]: y };
      });
    });

    const rows = Object.entries(combineSeries).map(([key, value]) => ({
      date: key,
      ...value,
    }));

    exportExcelFromTable({
      data: [
        { x: 'Ngày bắt đầu', y: moment(startTime).format(`DD-MM-YYYY`) },
        { x: 'Ngày kết thúc', y: moment(endTime).format(`DD-MM-YYYY`) },
        { x: '', y: '' },
        { x: 'Thời gian', y: '' },

        ...rows.map((row) => ({
          x: formatDateExport(row.date),
          ...Object.keys(row).reduce((acc, k) => {
            acc[k] = toLocaleString(row[k]);
            return acc;
          }, {}),
        })),
      ],
      fileName: `${app.name}_${t(report)}_${moment(startTime).format(
        `DD-MM-YYYY`,
      )}_${moment(endTime).format(`DD-MM-YYYY`)}`,
      heads,
    });
  };

  const handleSelectOption = (value) => {
    if (value === 'Xuất báo cáo') handleExportReport();
    if (value === 'So sánh') addParams({ compareTo: 'none' });
  };

  const categoryOptions = [
    REPORT_CATEGORIES.TOTAL,
    REPORT_CATEGORIES.BY_PACKAGE,
  ];
  if (report !== REPORT_CHARTS.USER_ACTIVE)
    categoryOptions.push(REPORT_CATEGORIES.BY_EVENT);

  useEffect(() => {
    if (appId && report && !compareTo) fetchAppReport();
  }, [appId, startTime, endTime, report, group, category, compareTo]);

  return (
    <Box
      sx={{
        display: !compareTo ? 'block' : 'none',
      }}
    >
      <Box width="220px">
        <CustomSelect
          options={options}
          value={report}
          onChange={handleChangeReport}
          className="select"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          p: '20px 0 0 50px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          <Typography variant="h6b">
            {t(`${camelcase(report)}Report`)}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Tooltip title={toLocaleString(data.total)} placement="top">
              <Typography fontSize="30px" fontWeight="700" textAlign="end">
                {formatNumber(data.total)}
              </Typography>
            </Tooltip>
            <Tooltip
              title={`${data.changed > 0 ? '+' : ''}${toLocaleString(
                data.changed,
              )}%`}
              placement="top"
            >
              <Typography
                variant="body2"
                color={data.changed === 0 ? COLOR.blueGray[64] : color}
                textAlign="end"
              >{`${data.changed > 0 ? '+' : ''}${formatNumber(
                data.changed,
              )}%`}</Typography>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '22px',
            cursor: 'pointer',
          }}
        >
          <SelectPopover
            value={category}
            onSubmit={handleChangeCategory}
            options={categoryOptions}
          />
          <SelectPopover
            value={group}
            onSubmit={handleChangeGroupOption}
            options={Object.values(REPORT_GROUP_OPTION)}
          />
          <IconPopover
            display={
              <MoreHoriz
                sx={{
                  borderRadius: '50%',
                  p: '6px',
                  border: '1px solid rgba(0, 67, 144, 0.32)',
                  cursor: 'pointer',
                  color: COLOR.darkBlue[100],
                }}
              />
            }
            onSubmit={handleSelectOption}
            options={['Xuất báo cáo', 'So sánh']}
          />
        </Box>
      </Box>
      <Box height="300px" pb={3}>
        {series.length ? (
          <DetailStackBarChart
            series={series}
            yaxisFormatter={(v) => formatNumber(v)}
            xaxisFormatter={(v) => formatDate(v)}
          />
        ) : (
          <></>
        )}
      </Box>
      <DetailData series={tableSeries} total={data.total} />
    </Box>
  );
};

export default DetailReport;
