import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import camelCase from 'camelcase-keys';
import queryString from 'query-string';
import moment from 'moment';
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { CloseRounded, ReplayRounded, Search } from '@mui/icons-material';
import CustomTable from '@src/components/CustomTable';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import {
  INIT_PAGINATION,
  APP_VERSION_STATUS,
  ALL,
  DATE_TIME_FORMAT,
} from '@src/constants';
import { useSearchParams } from '@src/hooks';
import { StyledApps } from './index.style';

const App = () => {
  const location = useLocation();

  const { addParams, removeParams, removeAllParams } = useSearchParams();

  const dispatch = useDispatch();

  const [data, setData] = useState({
    apps: [],
    pagination: INIT_PAGINATION,
  });
  const [inputText, setInputText] = useState('');
  const [filterStatus, setFilterStatus] = useState(ALL);
  const [statusStat, setStatusStat] = useState({});
  const [totalApp, setTotalApp] = useState(0);
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organizationSelected, setOrganizationSelected] = useState([]);
  const [isFirst, setIsFirst] = useState(true);

  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const heads = [
    {
      label: t('index'),
      valueName: 'index',
      align: 'center',
    },
    {
      label: t('appName'),
      valueName: 'name',
      align: 'left',
    },
    {
      label: t('organization'),
      valueName: 'organizationName',
      align: 'left',
    },
    {
      label: t('version'),
      valueName: 'version',
      align: 'left',
    },
    {
      label: t('status'),
      valueName: 'status',
      align: 'left',
    },
    {
      label: t('updatedAt'),
      valueName: 'updatedAt',
      align: 'center',
    },
  ];

  const formatAppsInfo = (apps = [], page) =>
    apps.map((app, index) => {
      let status = '';
      let version = '';

      if (
        [APP_VERSION_STATUS.BANNED, APP_VERSION_STATUS.PUBLISHED].includes(
          filterStatus,
        )
      ) {
        status = app.latestPublishedAppVersion?.status;
        version = app.latestPublishedAppVersion?.version;
      }

      if (
        [
          APP_VERSION_STATUS.DRAFT,
          APP_VERSION_STATUS.WAITING_REVIEW,
          APP_VERSION_STATUS.APPROVED,
          APP_VERSION_STATUS.PUBLISH_SCHEDULED,
          APP_VERSION_STATUS.REJECTED,
        ].includes(filterStatus)
      ) {
        status = app.prepareSubmissionAppVersion?.status;
        version = app.prepareSubmissionAppVersion?.version;
      }

      if (filterStatus === ALL) {
        status =
          app.latestPublishedAppVersion?.status ||
          app.prepareSubmissionAppVersion?.status;
        version =
          app.latestPublishedAppVersion?.version ||
          app.prepareSubmissionAppVersion?.version;
      }

      return {
        ...app,
        index:
          index + (page || data.pagination.page) * data.pagination.limit + 1,
        status: t(status),
        version,
        updatedAt: moment(app.updatedAt).format(DATE_TIME_FORMAT),
        organizationName: app.organization?.name || '--',
      };
    });

  const handleRefresh = () => {
    if (isFirst) setIsFirst(false);
    setInputText('');
    setOrganizationSelected('');
    removeAllParams();
  };

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const {
        page = data.pagination.page,
        organizationId = organizationSelected?.id,
        search = inputText,
        status = filterStatus === ALL ? undefined : filterStatus,
      } = filters || {};
      const params = {
        pageNum: page,
        inputText: search,
        status,
        organizationId,
      };
      const res = await apis.app.getApps(params);
      if (res?.status) {
        const currentPage = res?.result?.pager?.currentPageNum || 1;
        setData((prevData) => ({
          ...prevData,
          apps: formatAppsInfo(res?.result?.data, page),
          pagination: {
            page: currentPage,
            limit: res?.result?.pager?.limit || 10,
            total: res?.result?.pager?.totalCount || 0,
            totalPages: res?.result?.pager?.lastPageNum || 1,
          },
        }));
        addParams({ page: currentPage });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchOrganization = async (organizationId) => {
    try {
      const response = await apis.csp.getOrganizationDetail(organizationId);
      if (response?.result) {
        setOrganizations([response?.result]);
        setOrganizationSelected(response?.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const fetchOrganizations = async (params = {}) => {
    try {
      const { organizationId, ...tmpParams } = params;
      const response = await apis.csp.getOrganizations({
        ...tmpParams,
        searchFields: 'name',
      });
      if (response?.status) {
        const organizationList = response?.result?.data || [];
        if (organizationId) {
          await fetchOrganization(organizationId);
          return;
        }

        setOrganizations(organizationList);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleChangeFilterOrganizationsInputText = (newInputValue) => {
    fetchOrganizations({ inputText: newInputValue });
  };

  const handleChangeOrganization = (value) => {
    if (isFirst) setIsFirst(false);

    setOrganizationSelected(value);
    addParams({ organizationId: value?.id });
  };

  const fetchTotalApp = async (filters) => {
    try {
      const { organizationId = organizationSelected?.id, search = inputText } =
        filters || {};
      const params = {
        pageNum: 1,
        inputText: search,
        organizationId,
      };
      const res = await apis.app.getApps(params);
      if (res?.status) setTotalApp(res?.result?.pager?.totalCount);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const fetchStatusStat = async (filters) => {
    try {
      const { organizationId = organizationSelected?.id, search = inputText } =
        filters || {};
      const params = {
        inputText: search,
        organizationId,
      };
      const res = await apis.app.getStatusCount(params);
      if (res?.status) setStatusStat(res.result);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleChangeInputText = (e) => {
    if (isFirst) setIsFirst(false);

    const { value } = e.target;
    setInputText(value);

    if (!value) {
      removeParams('search');
      return;
    }
    addParams({ search: value });
  };

  const handleChangePage = (page) => {
    fetchData({ page });
  };

  const handleClickApp = (app) => {
    let versionId = app.prepareSubmissionAppVersion
      ? app.prepareSubmissionAppVersion.id
      : app.latestPublishedAppVersion.id;

    if (
      [APP_VERSION_STATUS.BANNED, APP_VERSION_STATUS.PUBLISHED].includes(
        filterStatus,
      )
    )
      versionId = app.latestPublishedAppVersion.id;

    const routeBack = location.pathname + location.search;
    dispatch(actions.route.updateBackRoute(routeBack));

    if (versionId) {
      history.push(`/app/${app.id}/version/${versionId}`);
    } else {
      history.push(`/app/${app.id}`);
    }
  };

  const handleChangeFilterStatus = (status) => {
    if (isFirst) setIsFirst(false);

    setFilterStatus(status);
    addParams({ status });
  };

  useEffect(() => {
    if (isFirst) return;

    fetchData({ page: INIT_PAGINATION.page });
    setData({ ...data, pagination: INIT_PAGINATION });
  }, [inputText, filterStatus, organizationSelected?.id]);

  useEffect(() => {
    if (isFirst) return;

    fetchStatusStat();
  }, [inputText, organizationSelected?.id]);

  useEffect(() => {
    if (isFirst) return;

    fetchTotalApp();
  }, [inputText, organizationSelected?.id]);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const { search = '', status, organizationId, page } = searchParams;
    setInputText(search);
    setFilterStatus(status || ALL);
    setData({ ...data, pagination: { ...INIT_PAGINATION, page } });
    fetchOrganizations({ organizationId });
    fetchData({
      page,
      organizationId,
      search,
      status: status === ALL ? undefined : status,
    });
    fetchStatusStat({ organizationId, search });
    fetchTotalApp({ organizationId, search });
  }, []);

  return (
    <StyledApps>
      <div className="filter">
        <Typography variant="h3">{t('appList')}</Typography>
        <Box className="filter-input">
          <TextField
            size="small"
            variant="outlined"
            className="input-search text-search"
            fullWidth
            placeholder={t('appSearchPlaceholder')}
            value={inputText}
            onChange={handleChangeInputText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="primary" fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <CloseRounded
                    color="primary"
                    fontSize="small"
                    className="clear-icon"
                    onClick={() => {
                      handleChangeInputText({ target: { value: '' } });
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Autocomplete
            className="input-search"
            fullWidth
            value={organizationSelected || ''}
            size="small"
            options={organizations}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.name || ''}
            onChange={(event, newValue) => {
              handleChangeOrganization(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('organization')}
                onChange={(event) => {
                  handleChangeFilterOrganizationsInputText(event.target.value);
                }}
              />
            )}
            noOptionsText={t('noData')}
          />
          <IconButton className="reload-button" onClick={handleRefresh}>
            <ReplayRounded color="primary" />
          </IconButton>
        </Box>
      </div>

      <div className="content">
        <div className="status-select">
          <Typography
            className={`status-button ${filterStatus === ALL && 'selected'}`}
            key={ALL}
            onClick={() => handleChangeFilterStatus(ALL)}
          >
            <div className="label">
              {t(ALL)}
              <Typography className="status-count">{totalApp}</Typography>
            </div>
          </Typography>
          {Object.keys(APP_VERSION_STATUS).map((status) => (
            <Typography
              className={`status-button ${
                filterStatus === status && 'selected'
              }`}
              key={status}
              onClick={() => handleChangeFilterStatus(status)}
            >
              <div className="label">
                {t(status)}
                <Typography className="status-count">
                  {statusStat[Object.keys(camelCase({ [status]: true }))[0]] ||
                    0}
                </Typography>
              </div>
            </Typography>
          ))}
        </div>
        <div className="app-table">
          <CustomTable
            items={data.apps}
            heads={heads}
            pagination={data.pagination}
            onChangePagination={handleChangePage}
            onClickRow={handleClickApp}
            loading={loading}
          />
        </div>
      </div>
    </StyledApps>
  );
};
export default App;
