import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { STAT_OVERVIEW_CHART } from '@src/constants/chart';
import apis from '@src/apis';
import DonutChartBlock from '../../components/ApexCharts/DonutChartBlock';
import LoadingOverview from './LoadingOverview';

const OverviewStats = ({ isReLoad }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [charts, setCharts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchStatAppsData = async () => {
    try {
      const res = await apis.stat.statApps();
      if (res?.status) {
        const { appPackageStatus = {}, appStatus: appStatusCount = {} } =
          res?.result || {};
        const { published = 0, banned = 0 } = appStatusCount;
        const { freeAppsCount = 0, paidAppsCount = 0 } = appPackageStatus;

        const appType = {
          type: STAT_OVERVIEW_CHART.APP_TYPE,
          title: t('appType'),
          labels: [t('free'), t('paid')],
          series: [freeAppsCount, paidAppsCount],
          plotLabel: t('app'),
        };
        const appStatus = {
          type: STAT_OVERVIEW_CHART.APP_STATUS,
          title: t('appStatus'),
          labels: [t('PUBLISHED'), t('banned')],
          series: [published, banned],
          plotLabel: t('app'),
        };

        return [appType, appStatus];
      }
      return [];
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      return [];
    }
  };

  const fetchStatUsersData = async () => {
    try {
      const res = await apis.stat.statUsers();
      if (res?.status) {
        const { userPackageStatus = {} } = res?.result || {};
        const { freeUsersCount = 0, paidUsersCount = 0 } = userPackageStatus;

        const userType = {
          type: STAT_OVERVIEW_CHART.USER_TYPE,
          title: t('userType'),
          labels: [t('free'), t('paid')],
          series: [freeUsersCount, paidUsersCount],
          plotLabel: t('user'),
        };

        return [userType];
      }
      return [];
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      return [];
    }
  };

  const fetchStatPackagesData = async () => {
    try {
      const res = await apis.stat.statPackages();
      if (res?.status) {
        const { packageStatus: packageStatusCount = {} } = res?.result || {};
        const { activePackagesCount = 0, inactivePackagesCount = 0 } =
          packageStatusCount;

        const packageStatus = {
          type: STAT_OVERVIEW_CHART.PACKAGE_STATUS,
          title: t('packageStatus'),
          labels: [t('working'), t('stopWorking')],
          series: [activePackagesCount, inactivePackagesCount],
          plotLabel: t('package'),
        };

        return [packageStatus];
      }
      return [];
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      return [];
    }
  };

  const fetchStatData = async () => {
    setLoading(true);
    const statApps = await fetchStatAppsData();
    const statUsers = await fetchStatUsersData();
    const statPackages = await fetchStatPackagesData();
    setCharts([...statApps, ...statUsers, ...statPackages]);
    setLoading(false);
  };

  useEffect(() => {
    if (isReLoad) fetchStatData();
  }, [isReLoad]);

  if (loading) return <LoadingOverview />;

  return (
    <Grid container spacing={2.5}>
      {charts.map((data) => (
        <Grid item xs={12} md={6} key={data.title}>
          <DonutChartBlock
            data={data}
            hasLegendValue
            colors={['#F765A3', '#32ADE6', '#AF52DE', '#41DF69']}
          />
        </Grid>
      ))}
    </Grid>
  );
};
export default OverviewStats;
