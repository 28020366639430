import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { COLOR } from '@src/styles/color';
import { v4 as uuidv4 } from 'uuid';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  BOX_SHADOW,
  SIDE_BAR_WIDTH,
  SIDE_BAR_WIDTH_COLLAPSED,
} from '@src/styles/config';
import { useTranslation } from 'react-i18next';

const stylesMenuItem = {
  height: 52,
  paddingX: 1,
  display: 'flex',
  alignItems: 'center',
  gap: 1.5,
  borderRadius: 4,
  cursor: 'pointer',
};

const SubItemMenu = ({ item, currentPath, onClickItem }) => {
  const { t } = useTranslation();
  const isActive = currentPath === item.path;

  const handleItemClick = () => {
    onClickItem(item.path);
  };

  return (
    <Box
      key={uuidv4()}
      color={COLOR.darkestBlue[80]}
      sx={{
        color: isActive ? COLOR.darkestBlue[100] : COLOR.darkestBlue[80],
        backgroundColor: isActive ? COLOR.darkBlue[8] : 'transparent',
        '&:hover': {
          backgroundColor: isActive ? COLOR.darkBlue[8] : COLOR.darkBlue[4],
        },
        ...stylesMenuItem,
      }}
      onClick={handleItemClick}
    >
      <Box width={48} />
      <Typography marginRight="auto">{t(item.label)}</Typography>
    </Box>
  );
};

const SubMenu = ({ subMenu, currentPath, onClickItem, isCollapse }) => (
  <>
    {subMenu.map((subItem) => (
      <SubItemMenu
        key={uuidv4()}
        item={subItem}
        currentPath={currentPath}
        onClickItem={onClickItem}
        isCollapse={isCollapse}
      />
    ))}
  </>
);

const ItemMenu = ({
  item,
  isCollapse,
  currentPath,
  setCurrentPath,
  history,
}) => {
  const { t } = useTranslation();
  const [isShowSubMenu, setIsShowSubMenu] = useState(false);

  const handleRedirect = (path) => {
    setCurrentPath(path);
    history.push(path);
  };

  const handleClick = (path) => {
    if (item.subMenu && item.subMenu.length > 0) {
      setIsShowSubMenu(!isShowSubMenu);
    } else {
      handleRedirect(path);
    }
  };

  const isActive =
    currentPath === item.path ||
    item?.subMenu?.some((subItem) => subItem.path === currentPath);

  useEffect(() => {
    if (isActive && !isCollapse) setIsShowSubMenu(true);
  }, []);

  const backgroundColor =
    isShowSubMenu && item.subMenu && item.subMenu.length > 0
      ? 'transparent'
      : COLOR.blue[8];

  const stylesActive = {
    color: isCollapse ? COLOR.charcoal[100] : COLOR.darkestBlue[100],
    backgroundColor: isCollapse ? COLOR.blue[100] : backgroundColor,
  };
  const stylesHover = { '&:hover': { backgroundColor: COLOR.darkBlue[4] } };

  return (
    <>
      <Box
        key={uuidv4()}
        color={COLOR.darkestBlue[80]}
        sx={{
          ...(isActive && stylesActive),
          ...(!isActive && stylesHover),
          ...stylesMenuItem,
        }}
        onClick={() => handleClick(item.path)}
      >
        <Box width={48} textAlign="center">
          {item.icon}
        </Box>
        {!isCollapse && (
          <Typography marginRight="auto">{t(item.label)}</Typography>
        )}
        {item.subMenu && item.subMenu.length > 0 && !isCollapse && (
          <IconButton size="small">
            {isShowSubMenu ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        )}
        {item.subMenu && isShowSubMenu && isCollapse && (
          <Box
            position="absolute"
            left={SIDE_BAR_WIDTH_COLLAPSED + 4}
            width={SIDE_BAR_WIDTH}
            bgcolor="white"
            zIndex={1}
            display="flex"
            flexDirection="column"
            gap={0.5}
            paddingX={1}
            paddingY={1.5}
            borderRadius={4}
            boxShadow={BOX_SHADOW}
          >
            <SubMenu
              subMenu={item.subMenu}
              currentPath={currentPath}
              onClickItem={handleRedirect}
              isCollapse={isCollapse}
            />
          </Box>
        )}
      </Box>
      {item.subMenu && isShowSubMenu && !isCollapse && (
        <SubMenu
          subMenu={item.subMenu}
          currentPath={currentPath}
          onClickItem={handleRedirect}
          isCollapse={isCollapse}
        />
      )}
    </>
  );
};

export default ItemMenu;
