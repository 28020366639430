export const download = (url, fileName) => {
  if (!url) return;
  const link = document.createElement('a');
  link.href = url;

  if (fileName) link.download = fileName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadExcel = (data, fileName) => {
  if (!data) return;
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);
  download(url, fileName);
};
