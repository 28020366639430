/* eslint-disable no-restricted-syntax */
import * as XLSX from 'xlsx/xlsx';
import FileSaver from 'file-saver';

const FILE_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export const exportExcelFromTable = ({
  data,
  fileName,
  heads,
  sheetName = 'data',
}) => {
  const wscols = [];

  const header = heads.map((head) => {
    wscols.push({ wch: head.label.length + 10 });
    return head.label;
  });

  const csvData = data.map((el) => {
    const rowData = {};
    heads.forEach(({ label, valueName }) => {
      const value = el[valueName];
      rowData[label] = (value && value.toString()) || '';
    });
    return rowData;
  });

  const ws = XLSX.utils.json_to_sheet(csvData, { header });
  ws['!cols'] = wscols;

  const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const dataExport = new Blob([excelBuffer], { type: FILE_TYPE });
  FileSaver.saveAs(dataExport, fileName);
};
