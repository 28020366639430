/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Box, CircularProgress, Grid } from '@mui/material';
import { ROLE_TYPE } from '@src/constants/role';
import { BORDER, BORDER_RADIUS } from '@src/styles/config';
import apis from '@src/apis';
import ActionRole from './ActionRole';
import ListRole from './ListRole';
import ListPermissions from './ListPermissions';

const Role = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [roles, setRoles] = useState({ data: [], selected: {} });
  const [groupPermissions, setGroupPermissions] = useState();
  const [permissions, setPermissions] = useState([]);
  const [permissionsChange, setPermissionsChange] = useState([]);
  const [tab, setTab] = useState(ROLE_TYPE.ORGANIZATION);

  const { selected: roleSelected } = roles;
  const permissionIds = roleSelected?.permissions?.map((item) => item.id);

  const fetchRoles = async (type) => {
    try {
      const res = await apis.role.getRoles({ type });
      if (!res) throw new Error();
      const roleSelectedDefault = search
        ? res?.result.find((item) =>
            item.name.toLowerCase().includes(search.toLowerCase()),
          )
        : res?.result[0];
      setRoles({
        data: res?.result,
        selected: roleSelectedDefault || {},
      });
      setPermissionsChange(res?.result[0]?.permissions?.map((item) => item.id));
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const fetchPermissions = async () => {
    try {
      const res = await apis.permission.getPermissions();
      if (!res) throw new Error();
      setPermissions(res?.result);
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const fetchGroupPermissions = async () => {
    try {
      const res = await apis.groupPermission.getGroupPermissions();
      if (!res) throw new Error();
      setGroupPermissions(res?.result);
    } catch (error) {
      enqueueSnackbar(t(`common:::${error.message}`), {
        variant: 'error',
      });
    }
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    setRoles({
      ...roles,
      selected:
        roles.data.find((item) =>
          item.name.toLowerCase().includes(value.toLowerCase()),
        ) || {},
    });
  };

  const handleSelectPermission = (permission) => {
    const permissionId = permission.id;
    if (permissionsChange.includes(permissionId)) {
      setPermissionsChange(
        permissionsChange.filter((item) => item !== permissionId),
      );
      setRoles({
        ...roles,
        selected: {
          ...roles.selected,
          permissions: roles.selected.permissions.filter(
            (item) => item.id !== permissionId,
          ),
        },
      });
    } else {
      setPermissionsChange([...permissionsChange, permissionId]);
      setRoles({
        ...roles,
        selected: {
          ...roles.selected,
          permissions: [...roles.selected.permissions, permission],
        },
      });
    }
  };

  const handleSelectRole = (value) => {
    setRoles({ ...roles, selected: value });
    setPermissionsChange(value?.permissions?.map((item) => item.id));
  };

  const handleChangeTab = (event, newValue) => setTab(newValue);

  useEffect(() => {
    fetchRoles(tab);
  }, [tab]);

  useEffect(() => {
    fetchGroupPermissions();
    fetchPermissions();
  }, []);

  if (!roles || !groupPermissions) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const listGroupPermission = groupPermissions
    ?.filter((groupPermission) => groupPermission.type === tab)
    ?.map((groupPermission) => {
      const permissionsFind = permissions?.filter(
        (permission) => groupPermission.id === permission.groupPermissionId,
      );
      return { ...groupPermission, permissions: permissionsFind };
    });

  return (
    <Box bgcolor="white" padding={2} borderRadius={BORDER_RADIUS.container}>
      <ActionRole
        search={search}
        handleSearchChange={handleSearchChange}
        roleSelected={roleSelected}
        permissionsChange={permissionsChange}
        tab={tab}
        setRoles={setRoles}
        setPermissionsChange={setPermissionsChange}
        permissionSelectedDefault={roles.data
          .find((item) => item.id === roleSelected.id)
          ?.permissions?.map((item) => item.id)}
      />
      <Box border={BORDER} borderRadius={BORDER_RADIUS.container}>
        <Grid container>
          <ListRole
            tab={tab}
            handleChangeTab={handleChangeTab}
            roleSelected={roleSelected}
            handleSelectRole={handleSelectRole}
            roles={roles.data.filter((item) =>
              item.name.toLowerCase().includes(search.toLowerCase()),
            )}
            setRoles={setRoles}
            setPermissionsChange={setPermissionsChange}
          />
          {roleSelected?.id && (
            <ListPermissions
              permissionIdsSelected={permissionIds}
              handleSelectPermission={handleSelectPermission}
              groupPermissions={listGroupPermission}
              isMasterRole={roleSelected?.isMasterRole}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Role;
