import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyleEndUserDetail = styled(Box)`
  .user-info {
    padding: 30px 24px 24px 24px;
    border: 1px solid ${COLOR.standardBlue[32]};
    border-radius: 24px;
    margin-bottom: 2rem;
  }
`;

export const StyleListApp = styled(Box)`
  .filter-app {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 16px;

    .select {
      min-width: 180px;
      .MuiOutlinedInput-root {
        border-radius: 12px;
        background-color: ${COLOR.darkBlue[8]};
        color: ${COLOR.darkestBlue[100]};

        fieldset {
          border: none;
        }
      }
    }

    .input-search {
      .MuiOutlinedInput-root {
        border-radius: 24px;

        fieldset {
          border-color: ${COLOR.darkBlue[32]};
        }
      }
    }
  }

  .app-status {
    width: 128px;
  }

  .app-name {
    color: ${COLOR.darkestBlue[100]};
  }
`;
