import { Box, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, matchPath } from 'react-router-dom';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faCheckCircle,
  faClock,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { APP_VERSION_STATUS } from '@src/constants';
import { sideBarListOptions } from './data';

const SidebarApp = ({ preVersion, lastVersion, appId, app, versionId }) => {
  const { t } = useTranslation();
  const currentPath = window.location.pathname;
  const history = useHistory();

  const handleClickInfoItem = ({ path, versionIdRedirect }) => {
    if (path) {
      const newPath = path.replace(':appId', appId);
      history.push({
        pathname: newPath,
        state: { app },
      });
    } else {
      history.push({
        pathname: `/app/${appId}/version/${versionIdRedirect}`,
        state: { app },
      });
    }
  };

  const getVersionIcon = (status) => {
    if (status === APP_VERSION_STATUS.PUBLISHED)
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="sm"
          style={{ color: 'green' }}
        />
      );
    if (status === APP_VERSION_STATUS.REJECTED)
      return (
        <FontAwesomeIcon
          icon={faXmarkCircle}
          size="sm"
          style={{ color: 'red' }}
        />
      );
    if (status === APP_VERSION_STATUS.BANNED)
      return (
        <FontAwesomeIcon icon={faBan} style={{ color: 'red' }} size="sm" />
      );
    return (
      <FontAwesomeIcon icon={faClock} size="sm" style={{ color: 'orange' }} />
    );
  };

  const isDisplayTooltip = (text) => text.length >= 28;

  const renderAppVersion = (appVersion) => {
    const textVersion = `${appVersion.version} - ${t(
      appVersion?.status?.toUpperCase(),
    )}`;

    const isActive = appVersion.id === versionId;

    if (appVersion) {
      return (
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          onClick={() =>
            handleClickInfoItem({ versionIdRedirect: appVersion.id })
          }
        >
          <Typography variant="body2" className="text-item">
            {getVersionIcon(appVersion.status)}
          </Typography>
          <Tooltip
            title={isDisplayTooltip(textVersion) ? textVersion : ''}
            arrow
          >
            <Typography
              className="text-item"
              color={isActive ? 'primary' : 'initial'}
            >
              {textVersion}
            </Typography>
          </Tooltip>
        </Box>
      );
    }
    return '';
  };

  return (
    <>
      <Box>
        <Typography variant="h6">{t('version')}</Typography>
        <Box px={1}>
          {preVersion && renderAppVersion(preVersion)}
          {lastVersion && renderAppVersion(lastVersion)}
        </Box>
      </Box>
      <Box>
        <Typography variant="h6">{t('Chung')}</Typography>
        <Box px={1}>
          {sideBarListOptions.map((item) => (
            <Tooltip
              key={item.id}
              title={isDisplayTooltip(t(item.label)) ? t(item.label) : ''}
              arrow
            >
              <Typography
                onClick={() => handleClickInfoItem({ path: item.path })}
                color={
                  matchPath(currentPath, item.path) ? 'primary' : 'initial'
                }
                className="text-item"
              >
                {t(item.label)}
              </Typography>
            </Tooltip>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default SidebarApp;
