import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import UnauthorizedImage from '@src/assets/image/unauthorized.png';
import { handleReceivingTokens } from '@src/services/auth';
import ROUTE from '@src/constants/route';
import actions from '@src/redux/actions';
import { StyledUnauthorized } from './index.style';

const Unauthorized = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    handleReceivingTokens({ token: '', refreshToken: '' });
    dispatch(actions.user.updateUser({}));
    history.push(ROUTE.LOGIN);
  };

  return (
    <StyledUnauthorized>
      <Box className="logo">
        <img src="/logo-aihub-collapse.png" alt="vbee-logo" />
      </Box>

      <Typography className="unauthorized-title center">
        {t('unauthorizedTitle')}
      </Typography>
      <Button onClick={handleLogout} variant="contained">
        {t('logout')}
      </Button>
      <img
        src={UnauthorizedImage}
        alt="unauthorized"
        className="unauthorized-img"
      />
    </StyledUnauthorized>
  );
};

export default Unauthorized;
