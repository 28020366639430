import { SERVICE } from '@src/constants';
import api from './api';

export const getUsers = async (params) => {
  const res = await api({
    method: 'GET',
    url: `/admin/users`,
    params,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const createUser = async (data) => {
  const res = await api({
    method: 'POST',
    url: `/admin/users`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const updateUser = async (userId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/users/${userId}`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const deleteUser = async (userId) => {
  const res = await api({
    method: 'DELETE',
    url: `/admin/users/${userId}`,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const updateUserStatus = async (userId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/users/${userId}/change-status`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const promoteUserToAdmin = async (userId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/users/${userId}/promote-user-to-admin`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};
