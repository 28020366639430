import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Divider } from '@mui/material';

const Top = ({ isCollapse }) => {
  const history = useHistory();
  const logo = isCollapse ? '/logo-aihub-collapse.png' : '/logo-aihub-dark.svg';
  const widthLogo = isCollapse ? 40 : 117;
  const heightLogo = isCollapse ? 32 : 32;

  return (
    <>
      <Box
        paddingX={2}
        paddingTop={1.5}
        paddingBottom={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={logo}
          alt="aihub"
          width={widthLogo}
          height={heightLogo}
          onClick={() => history.push('/')}
          style={{ cursor: 'pointer' }}
        />
      </Box>
      {!isCollapse && <Divider />}
    </>
  );
};

export default Top;
