import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCreateGroupPermission = styled(Box)`
  .content {
    .title {
      font-size: 14px;
    }
  }

  .actions {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
`;
