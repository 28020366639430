import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledAppDetailInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }

  .body-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .label-input {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;

    span {
      color: ${COLOR.error.base};
    }
  }

  .action-button {
    min-width: 150px;
  }

  .diff {
    .label {
      display: flex;
      justify-content: space-between;
      .option {
        font-size: 14px;
        color: ${COLOR.primary.base};
        cursor: pointer;
        :hover {
          text-decoration: underline;
        }
      }
    }

    .no-data {
      font-style: italic;
      color: ${COLOR.secondary.base};
    }
    .css-1ks9aww-gutter {
      min-width: 10px;
    }
  }
`;

export const StyledRefusalReasonForm = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  .action {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
`;
