import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import ItemMenu from './ItemMenu';

const ListMenu = ({ isCollapse, menus }) => {
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState(history.location.pathname);

  return (
    <Box
      paddingX={1}
      paddingY={1.5}
      display="flex"
      flexDirection="column"
      gap={0.5}
      overflow="auto"
      height="calc(100vh - 180px)"
      sx={{ overflowX: 'hidden' }}
    >
      {menus.map((item) => (
        <ItemMenu
          key={uuidv4()}
          item={item}
          isCollapse={isCollapse}
          currentPath={currentPath}
          setCurrentPath={setCurrentPath}
          history={history}
        />
      ))}
    </Box>
  );
};

export default ListMenu;
