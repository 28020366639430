import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import apis from '@src/apis';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import debounce from '@src/utils/debounce';
import { Search } from '@mui/icons-material';
import { StyleSearchUserAutocomplete } from './index.style';

const SearchUserAutocomplete = ({ userId, setUserId, isError }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});

  const fetchUsers = async (text) => {
    try {
      const res = await apis.admin.getUsers({
        active: true,
        inputText: text,
      });
      if (res) setUsers(res?.result?.data);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleChangeInputText = (newInputValue) =>
    debounce(fetchUsers)(newInputValue);

  useEffect(() => {
    fetchUsers();
  }, []);

  const getLabel = ({ email = '', phoneNumber = '' }) => email || phoneNumber;

  const getUserInfo = ({ name = '', phoneNumber = '' }) =>
    `${name}${name !== '' && phoneNumber !== '' ? ' | ' : ''}${phoneNumber}`;

  const handleChange = (value) => {
    setUser(value);
    setUserId(value?.id);
  };

  const handleCancel = () => {
    fetchUsers();
    setUserId('');
    setUser({});
  };

  return (
    <StyleSearchUserAutocomplete>
      {userId ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Avatar alt={user.name || ''} src={user.avatar || ''} />
            <Box>
              <Typography>{user.email || user.name || ''}</Typography>
              <Typography className="user-info">{getUserInfo(user)}</Typography>
            </Box>
          </Stack>
          <Button
            startIcon={<CloseIcon />}
            onClick={handleCancel}
            color="error"
          >
            {t('cancelSelect')}
          </Button>
        </Stack>
      ) : (
        <Autocomplete
          fullWidth
          options={users}
          getOptionLabel={(option) => getLabel(option)}
          onChange={(e, value) => handleChange(value)}
          onInputChange={(event, newInputValue) =>
            handleChangeInputText(newInputValue)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('chooseUser')}
              error={isError && !userId}
              helperText={isError && !userId && t('required')}
              className="textfield-input"
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {getLabel(option)}
            </Box>
          )}
          value={users.find((el) => el.id === userId)}
          noOptionsText={t('noData')}
          className="select-user"
        />
      )}
    </StyleSearchUserAutocomplete>
  );
};

export default SearchUserAutocomplete;
