import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import apis from '@src/apis';
import ROUTES from '@src/constants/route';
import { Avatar, Box, Button, TextField, Typography } from '@mui/material';
import {
  Add,
  ArrowBackRounded,
  AutorenewRounded,
  Delete,
  EditRounded,
  SaveRounded,
  Search,
} from '@mui/icons-material';
import CustomTable from '@src/components/CustomTable';
import Popup from '@src/components/Popup';
import { DEFAULT_APP_ICON, SPECIAL_GROUP_TYPE } from '@src/constants';
import { compareStringToSearch } from '@src/utils/string';
import { COLOR } from '@src/styles/color';
import camelcase from 'camelcase';
import { StyledSpecialGroupDetail } from './index.style';
import AddApp from './AddApp';
import CreateAppGroup from '../ContentManagement/CreateAppGroup';

const SpecialGroupDetail = () => {
  const { t } = useTranslation();

  const { specialGroupId } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [specialGroup, setSpecialGroup] = useState({});
  const [openEditGroupPopup, setOpenEditGroupPopup] = useState(false);
  const [groupApps, setGroupApps] = useState([]);
  const [groupAppsApi, setGroupAppsApi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchAppName, setSearchAppName] = useState('');
  const [openConfirmUpdatePopup, setOpenConfirmUpdatePopup] = useState(false);
  const [openAddAppPopup, setOpenAddAppPopup] = useState(false);
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] = useState(false);
  const [deleteApp, setDeleteApp] = useState({});

  const handleChangeSearchAppName = (e) => setSearchAppName(e.target.value);

  const handleBack = () => history.push(ROUTES.CONTENT_MANAGEMENT);

  const handleApps = (data) => {
    const apps = data.map((item) => {
      const {
        id,
        name,
        latestPublishedAppVersion = {},
        organization = {},
        rank,
      } = item;
      const { appImageUrl = '' } = latestPublishedAppVersion;
      const { name: orgName = '' } = organization;
      return {
        id,
        name,
        avatar: appImageUrl,
        orgName,
        rank,
      };
    });

    return apps;
  };

  const fetchSpecialGroup = async () => {
    try {
      setLoading(true);
      const res = await apis.specialGroup.getSpecialGroup(specialGroupId);

      if (!res.status) {
        handleBack();
        throw new Error(res.message);
      }

      let { apps = [] } = res.result;

      apps = handleApps(apps);

      setSpecialGroup(res.result);
      setGroupApps(apps);
      setGroupAppsApi(apps);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenEditGroup = () => {
    setOpenEditGroupPopup(true);
  };

  const handleCloseEditPopup = () => {
    setOpenEditGroupPopup(false);
  };

  const handleUpdateSpecialGroup = async (dataUpdate) => {
    try {
      const response = await apis.specialGroup.updateSpecialGroup(
        specialGroup.id,
        dataUpdate,
      );

      if (!response?.status) throw new Error();

      handleCloseEditPopup();
      enqueueSnackbar(t('updateSpecialGroupSuccess'), { variant: 'success' });
      await fetchSpecialGroup();
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  const updateApps = async (apps) => {
    await handleUpdateSpecialGroup({ apps });
    fetchSpecialGroup();
  };

  const confirmUpdateApps = async () => {
    const apps = groupApps.map((item) => ({
      id: item.id,
      rank: Number(item.rank),
    }));
    await updateApps(apps);
  };

  const handleAddApp = async (app) => {
    const { id, rank } = app;
    const apps = groupApps.map((item) => ({
      id: item.id,
      rank: Number(item.rank),
    }));

    for (let i = 0; i < apps.length; i += 1) {
      if (apps[i].rank >= Number(rank)) {
        apps[i].rank += 1;
      }
    }

    apps.push({ id, rank: Number(rank) });

    await updateApps(apps);
  };

  const handleRemoveApp = async () => {
    const apps = groupApps.map((item) => ({
      id: item.id,
      rank: Number(item.rank),
    }));

    const index = apps.findIndex((item) => item.id === deleteApp.id);
    apps.splice(index, 1);

    await updateApps(apps);
    setOpenConfirmDeletePopup(false);
    setDeleteApp({});
  };

  const handleReorder = (items) => {
    const copyListItems = items.map(({ id }, index) => {
      const item = groupApps.find((el) => el.id === id);
      return { ...item, rank: index + 1 };
    });
    setGroupApps(copyListItems);
  };

  useEffect(() => {
    fetchSpecialGroup();
  }, []);

  const heads = [
    {
      label: t('position'),
      valueName: 'rank',
      align: 'center',
    },
    {
      label: t('appName'),
      valueName: 'appName',
      align: 'left',
    },
    {
      label: t('organization'),
      valueName: 'orgName',
      align: 'left',
    },
  ];

  if (specialGroup.type === SPECIAL_GROUP_TYPE.MANUAL)
    heads.push({
      label: t('action'),
      valueName: 'actions',
      align: 'center',
    });

  const formatData = groupApps
    .filter((el) => compareStringToSearch(el.name, searchAppName))
    .map((el) => ({
      ...el,
      appName: (
        <Box display="flex" alignItems="center" gap={1}>
          <Avatar
            variant="rounded"
            src={el.avatar || DEFAULT_APP_ICON}
            alt={el.name}
            sx={{ width: 40, height: 40 }}
          />
          {el.name}
        </Box>
      ),
    }));

  const handleClickDeleteApp = (pkg) => {
    setDeleteApp(pkg);
    setOpenConfirmDeletePopup(true);
  };

  const handleCloseConfirmPopup = () => {
    setDeleteApp({});
    setOpenConfirmDeletePopup(false);
  };

  const isChangeSpecialGroups = () => {
    for (let i = 0; i < groupApps.length; i += 1) {
      if (groupApps[i]?.id !== groupAppsApi[i]?.id) return true;
    }
    return false;
  };

  const actions = [
    {
      icon: (
        <Delete color={`${isChangeSpecialGroups() ? 'inherit' : 'error'}`} />
      ),
      onClick: handleClickDeleteApp,
      disable: isChangeSpecialGroups(),
    },
  ];

  const handleResetApps = () => {
    setGroupApps(JSON.parse(JSON.stringify(groupAppsApi)));
  };

  const handleClickUpdate = () => {
    setOpenConfirmUpdatePopup(true);
  };

  const handleCloseUpdate = () => {
    setOpenConfirmUpdatePopup(false);
  };

  const handleOpenAddAppGroup = () => {
    setOpenAddAppPopup(true);
  };

  const handleCloseAddAppPopup = () => {
    setOpenAddAppPopup(false);
  };

  return (
    <StyledSpecialGroupDetail>
      <Box>
        <Box className="title-app-box">
          <Box className="flex-row-8">
            <ArrowBackRounded
              className="pointer"
              size="small"
              color="primary"
              onClick={handleBack}
            />
            <Typography variant="h3" className="title-app">
              {specialGroup.name}
            </Typography>
            <EditRounded
              className="pointer"
              size="small"
              color="primary"
              onClick={handleOpenEditGroup}
            />
          </Box>
          <Box className="flex-row-8">
            <TextField
              fullWidth
              size="small"
              placeholder={t('inputAppName')}
              InputProps={{
                startAdornment: <Search fontSize="small" />,
              }}
              disabled={isChangeSpecialGroups()}
              value={searchAppName}
              onChange={handleChangeSearchAppName}
              className="search-input"
            />
            {specialGroup.type === SPECIAL_GROUP_TYPE.MANUAL && (
              <Button
                variant="contained"
                className="fit-content-button"
                onClick={handleOpenAddAppGroup}
                disabled={isChangeSpecialGroups()}
              >
                <Add />
                {t('addApp')}
              </Button>
            )}
          </Box>
        </Box>
        <Typography variant="subtitle2">{specialGroup.description}</Typography>
        <Box className="body">
          <CustomTable
            items={formatData}
            heads={heads}
            disablePagination
            loading={loading}
            reorderable={
              !searchAppName && specialGroup.type === SPECIAL_GROUP_TYPE.MANUAL
            }
            onReorder={handleReorder}
            isStickyHeader
            maxHeight="calc(100vh - 250px)"
            actions={specialGroup.type === SPECIAL_GROUP_TYPE.MANUAL && actions}
          />
        </Box>
        {specialGroup.type === SPECIAL_GROUP_TYPE.MANUAL && (
          <Box className="footer-card">
            <Button
              variant="outlined"
              color="primary"
              className="button"
              onClick={handleResetApps}
              disabled={!isChangeSpecialGroups()}
            >
              <AutorenewRounded fontSize="small" sx={{ rotate: '45deg' }} />
              {t('reset')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="button"
              onClick={handleClickUpdate}
              disabled={!isChangeSpecialGroups()}
            >
              <SaveRounded fontSize="small" />
              {t('save')}
            </Button>
          </Box>
        )}
      </Box>

      {/* <EditGroup
        name={specialGroup.name}
        description={specialGroup.description}
        onClose={handleCloseEditPopup}
        open={openEditGroupPopup}
        onUpdate={handleUpdateSpecialGroup}
      /> */}

      <CreateAppGroup
        id={specialGroup.id}
        groupApi={specialGroup}
        location={specialGroup.location}
        onClose={handleCloseEditPopup}
        open={openEditGroupPopup}
        onUpdate={handleUpdateSpecialGroup}
        layouts={() => [
          {
            label: t(camelcase(specialGroup?.layout || '')),
            value: specialGroup?.layout,
          },
        ]}
      />

      <AddApp
        onClose={handleCloseAddAppPopup}
        open={openAddAppPopup}
        rankDefault={groupApps?.length + 1}
        onAddApp={handleAddApp}
        categoryId={specialGroup?.categoryId}
      />

      <Popup
        open={openConfirmUpdatePopup}
        onClose={handleCloseUpdate}
        title={t('confirm')}
        content={t('updateConfirm')}
        okMessage={t('yes')}
        cancelMessage={t('no')}
        onOk={confirmUpdateApps}
      />

      <Popup
        open={openConfirmDeletePopup}
        onClose={handleCloseConfirmPopup}
        title={t('confirmDeleteAppFromGroup')}
        content={t('removeAppFromGroupConfirm', {
          name: deleteApp?.name,
        })}
        okMessage={t('yes')}
        cancelMessage={t('no')}
        onOk={handleRemoveApp}
        titleColor={COLOR.warning.base}
      />
    </StyledSpecialGroupDetail>
  );
};

export default SpecialGroupDetail;
