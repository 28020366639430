import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

const CustomPasswordInput = ({
  value = '',
  placeholder,
  disabled,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const [isShowPassword, setIsShowPassword] = useState(false);

  return (
    <TextField
      {...props}
      size="small"
      fullWidth
      value={value}
      type={isShowPassword ? '' : 'password'}
      disabled={disabled}
      placeholder={placeholder || t('password')}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={() => setIsShowPassword(!isShowPassword)}
          >
            {isShowPassword ? (
              <VisibilityOffOutlined
                fontSize="small"
                cursor="pointer"
                color="info"
              />
            ) : (
              <VisibilityOutlined
                fontSize="small"
                cursor="pointer"
                color="info"
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomPasswordInput;
