import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { BORDER } from '@src/styles/config';
import CustomPermissionTypeTag from '../CustomPermissionTypeTag';

const ListPermissions = ({
  permissionIdsSelected,
  handleSelectPermission,
  groupPermissions,
  isMasterRole,
}) => (
  <Grid item xs={9}>
    <Box borderLeft={BORDER} maxHeight="calc(100vh - 140px)" overflow="auto">
      {groupPermissions.map((el) => (
        <Box px={4.5} py={2.5} borderBottom={BORDER} key={el.id}>
          <Typography mb={1}>{el.name}</Typography>
          <Grid container spacing={0.5}>
            {el.permissions?.map((permission) => (
              <Grid key={permission.id} item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        isMasterRole ||
                        permissionIdsSelected.includes(permission.id)
                      }
                      disabled={isMasterRole}
                    />
                  }
                  label={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap={1}
                    >
                      <CustomPermissionTypeTag type={permission.type} />
                      <Typography variant="subtitle1">
                        {permission.name}
                      </Typography>
                    </Box>
                  }
                  onChange={() => handleSelectPermission(permission)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  </Grid>
);

export default ListPermissions;
