import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import camelcase from 'camelcase';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import CustomSelect from '@src/components/CustomSelect';
import {
  TIME_UNIT,
  DEFAULT_HOTLINE,
  PACKAGE_DISPLAY_OPTION,
  PAYMENT_METHOD,
} from '@src/constants';
import apis from '@src/apis';
import { regexHotline, regexNumber } from '@src/constants/regex';
import { COLOR } from '@src/styles/color';
import { getChangedFields } from '@src/utils/object';
import { StyledCreatePackage } from './index.style';

const MAX_PACKAGE_NAME_LENGTH = 30;
const MAX_PACKAGE_NAME_CODE = 30;

const CreatePackage = ({
  open,
  onClose,
  onSavePackageSuccess,
  packageId,
  appId,
  disable,
}) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [packageName, setPackageName] = useState('');
  const [packageCode, setPackageCode] = useState('');
  const [price, setPrice] = useState('');
  const [hotline, setHotline] = useState(DEFAULT_HOTLINE);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.RECURRING);
  const [displayOption, setDisplayOption] = useState(
    PACKAGE_DISPLAY_OPTION.DEFAULT,
  );
  const [regularValue, setRegularValue] = useState({
    unit: '',
    value: '',
  });
  const [description, setDescription] = useState('');
  const [packageDataApi, setPackageDataApi] = useState({});
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({});

  const handleResetData = () => {
    setPackageName('');
    setPackageCode('');
    setPrice('');
    setHotline(DEFAULT_HOTLINE);
    setPaymentMethod(PAYMENT_METHOD.RECURRING);
    setDisplayOption('');
    setRegularValue({
      unit: '',
      value: '',
    });
    setDescription('');
    setPackageDataApi({});
    setError({});
  };

  const handleClose = () => {
    handleResetData();
    onClose();
  };

  const handleChangePackageName = (e) => {
    setError({ ...error, packageName: '' });
    setPackageName(e.target.value);
  };

  const handleChangePackageCode = (e) => {
    setError({ ...error, packageCode: '' });
    setPackageCode(e.target.value);
  };

  const handleChangePrice = (e) => {
    const { value } = e.target;
    if (!regexNumber.test(value)) return;

    setError({ ...error, price: '' });
    setPrice(value);
  };

  const handleChangeHotline = (e) => {
    const { value } = e.target;

    setError({ ...error, hotline: '' });
    setHotline(value);
  };

  const handleChangePaymentMethod = (value) => {
    setError({ ...error, paymentMethod: '' });
    setPaymentMethod(value);
  };

  const handleChangeDisplayOption = (value) => {
    setError({ ...error, displayOption: '' });
    setDisplayOption(value);
  };

  const handleChangeRegularValue = (key, value) => {
    if (key === 'value' && !regexNumber.test(value)) return;

    setError({ ...error, regularValue: { ...error?.regularValue, [key]: '' } });
    setRegularValue({
      ...regularValue,
      [key]: value,
    });
  };

  const handleChangeDescription = (e) => {
    setError({ ...error, description: '' });
    setDescription(e.target.value);
  };

  const validatePackageData = () => {
    const errorData = {};

    if (!packageName.trim()) errorData.packageName = t('packageNameRequired');

    if (packageName?.length > MAX_PACKAGE_NAME_LENGTH)
      errorData.packageName = t('packageNameTooLong', {
        length: MAX_PACKAGE_NAME_LENGTH,
      });

    if (packageCode?.length > MAX_PACKAGE_NAME_CODE)
      errorData.packageCode = t('packageCodeTooLong', {
        length: MAX_PACKAGE_NAME_CODE,
      });

    if (!packageCode.trim()) errorData.packageCode = t('packageCodeRequired');

    if (!price) errorData.price = t('priceRequired');

    if (!hotline) errorData.hotline = t('hotlineRequired');

    if (hotline && !regexHotline.test(hotline))
      errorData.hotline = t('hotlineInvalid');

    if (!paymentMethod) errorData.paymentMethod = t('paymentMethodRequired');

    if (!displayOption) errorData.displayOption = t('displayOptionRequired');

    if (paymentMethod === PAYMENT_METHOD.RECURRING) {
      errorData.regularValue = {};
      if (!regularValue?.value)
        errorData.regularValue.value = t('regularValueRequired');
      if (!regularValue?.unit)
        errorData.regularValue.unit = t('regularUnitRequired');

      if (!Object.keys(errorData?.regularValue)?.length)
        delete errorData.regularValue;
    }

    if (!description) errorData.description = t('introduceRequired');

    setError(errorData);
    return Object.keys(errorData).length === 0;
  };

  const handleDataUpdate = () => {
    const newData = {
      name: packageName,
      packageCode,
      price,
      // Bỏ default hotline nếu có nhiều hotline
      hotline: DEFAULT_HOTLINE,
      paymentMethod,
      displayOption,
      description,
    };
    const data = getChangedFields(packageDataApi, newData, ['regularValue']);

    if (
      (packageDataApi?.regularValue?.unit !== regularValue?.unit ||
        packageDataApi?.regularValue?.value !== regularValue?.value) &&
      paymentMethod === PAYMENT_METHOD.RECURRING
    ) {
      data.paymentMethod = paymentMethod;
      data.regularValue = regularValue;
    }

    return data;
  };

  const handleSave = async () => {
    if (!validatePackageData()) return;

    try {
      let res = {};

      if (packageId) {
        const dataUpdate = handleDataUpdate();
        res = await apis.packages.updatePackage(packageId, dataUpdate);
      } else {
        const dataCreate = {
          appId,
          name: packageName.trim(),
          packageCode: packageCode.trim(),
          hotline,
          price,
          paymentMethod,
          displayOption,
          description,
        };
        if (paymentMethod === PAYMENT_METHOD.RECURRING)
          dataCreate.regularValue = regularValue;

        res = await apis.packages.createPackage(dataCreate);
      }

      if (!res?.status) throw new Error();

      setPackageDataApi(res?.result);

      enqueueSnackbar(
        packageId ? t('updatePackageSuccess') : t('createPackageSuccess'),
        {
          variant: 'success',
        },
      );

      handleClose();

      onSavePackageSuccess();
    } catch (e) {
      enqueueSnackbar(t(e.message), { variant: 'error' });
    }
  };

  const fetchPackage = async () => {
    try {
      setLoading(true);
      const res = await apis.packages.getPackage(packageId);
      if (!res?.status) throw new Error();
      const { result = {} } = res;
      setPackageName(result.name);
      setPackageCode(result.packageCode);
      setPrice(result.price);
      setHotline(result.hotline);
      setPaymentMethod(result.paymentMethod);
      setDisplayOption(result.displayOption);
      setRegularValue(result.regularValue);
      setDescription(result.description);
      setPackageDataApi(result);
      setLoading(false);
    } catch (e) {
      enqueueSnackbar(t(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (packageId) fetchPackage();
  }, [packageId]);

  return (
    <StyledCreatePackage open={open}>
      <DialogTitle className="dialog-title pd-0">
        {t('editPackage')}
        {t(packageId ? 'editPackage' : 'createPackage')}
        {packageId && (
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon
              icon={faXmark}
              color={COLOR.text.highlight}
              size="sm"
            />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className="dialog-content pd-0">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className="label-input" variant="h6">
              {t('packageName')}
              <span>*</span>
            </Typography>
            <TextField
              size="small"
              value={packageName}
              onChange={handleChangePackageName}
              fullWidth
              autoFocus={!packageId}
              error={!!error.packageName}
              helperText={error.packageName}
              disabled={disable}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className="label-input" variant="h6">
              {t('displayOption')}
              <span>*</span>
            </Typography>
            <CustomSelect
              options={Object.values(PACKAGE_DISPLAY_OPTION).map((value) => ({
                value,
                label: t(camelcase(value)),
              }))}
              value={displayOption}
              onChange={handleChangeDisplayOption}
              error={!!error.displayOption}
              helperText={error.displayOption}
              disabled={disable}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className="label-input" variant="h6">
              {t('packageCode')}
              <span>*</span>
            </Typography>
            <TextField
              size="small"
              fullWidth
              value={packageCode}
              onChange={handleChangePackageCode}
              error={!!error.packageCode}
              helperText={error.packageCode}
              disabled={disable}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className="label-input" variant="h6">
              {t('serviceNumber')}
              <span>*</span>
            </Typography>
            <TextField
              size="small"
              fullWidth
              value={DEFAULT_HOTLINE}
              onChange={handleChangeHotline}
              error={!!error.hotline}
              helperText={error.hotline}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className="label-input" variant="h6">
              {t('paymentMethod')}
              <span>*</span>
            </Typography>
            <CustomSelect
              options={Object.values(PAYMENT_METHOD).map((value) => ({
                value,
                label: t(camelcase(value)),
              }))}
              value={paymentMethod}
              onChange={handleChangePaymentMethod}
              error={!!error.paymentMethod}
              helperText={error.paymentMethod}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className="label-input" variant="h6">
              {t('money')} (đ)
              <span>*</span>
            </Typography>
            <TextField
              size="small"
              value={price}
              onChange={handleChangePrice}
              fullWidth
              error={!!error.price}
              helperText={error.price}
              disabled={disable}
            />
          </Grid>
        </Grid>
        {paymentMethod === PAYMENT_METHOD.RECURRING && (
          <Box>
            <Typography className="label-input" variant="h6">
              {t('regularValue')}
              <span>*</span>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  value={regularValue?.value || ''}
                  onChange={(e) =>
                    handleChangeRegularValue('value', e.target.value)
                  }
                  error={!!error.regularValue?.value}
                  helperText={error.regularValue?.value}
                  label={t('value')}
                  disabled={disable}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomSelect
                  options={Object.values(TIME_UNIT).map((value) => ({
                    value,
                    label: t(camelcase(value)),
                  }))}
                  value={regularValue?.unit || ''}
                  onChange={(value) => handleChangeRegularValue('unit', value)}
                  error={!!error.regularValue?.unit}
                  helperText={error.regularValue?.unit}
                  label={t('unit')}
                  disabled={disable}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        <Box>
          <Typography className="label-input" variant="h6">
            {t('introduce')}
            <span>*</span>
          </Typography>
          <TextField
            size="small"
            fullWidth
            value={description}
            onChange={handleChangeDescription}
            multiline
            rows={5}
            error={!!error.description}
            helperText={error.description}
            disabled={disable}
          />
        </Box>
      </DialogContent>
      <DialogActions className="pd-0">
        <Button
          onClick={handleClose}
          variant="text"
          style={{ minWidth: '100px' }}
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          style={{ minWidth: '100px' }}
          disabled={!Object.keys(handleDataUpdate())?.length || loading}
        >
          {packageId ? t('save') : t('create')}
        </Button>
      </DialogActions>
    </StyledCreatePackage>
  );
};

export default CreatePackage;
