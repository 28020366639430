import React, { useState } from 'react';
import { Avatar, Box, Popover, Typography } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { DEFAULT_APP_ICON } from '@src/constants';
import { COLOR } from '@src/styles/color';
import SearchAppTextfield from './SearchAppTextfield';

const AppSelect = ({ onSubmit = () => {}, app, onlyPaidApp }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Avatar
          variant="rounded"
          src={app?.latestPublishedAppVersion?.appImageUrl || DEFAULT_APP_ICON}
          alt={app?.name}
          sx={{ width: 44, height: 44, mr: 0.5 }}
        />
        <Typography variant="h6b" className="nowrap">
          {app?.name}
        </Typography>
        <Box sx={{ color: COLOR.primary.dark, pt: 0.6 }}>
          {!open ? <ArrowDropDown /> : <ArrowDropUp />}
        </Box>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '.MuiPopover-paper': { borderRadius: '16px', marginTop: '4px' },
        }}
      >
        <SearchAppTextfield
          onSubmit={(value) => {
            handleClose();
            onSubmit(value);
          }}
          onlyPaidApp={onlyPaidApp}
        />
      </Popover>
    </>
  );
};

export default AppSelect;
