import apis from '@src/apis';
import { getFileExtension } from '@src/utils/file';
import { FILE_MAX_SIZE } from '@src/constants';

const uploadImageFile = async (file, suffix = '') => {
  const extension = getFileExtension(file);
  if (!extension) return null;

  const presignedUrlRes = await apis.upload.getUploadAppImagePresignedUrl(
    extension,
    suffix,
  );
  if (!presignedUrlRes || !presignedUrlRes.status) return null;

  const { url: presignedUrl } = presignedUrlRes.result;
  const { pathname, origin } = new URL(presignedUrl);

  const uploadRes = await apis.upload.uploadByPresignedUrl(presignedUrl, file);
  if (uploadRes.status !== 200) return null;

  return `${origin}${pathname}`;
};

const getBase64 = async (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });

const uploadBase64Image = async (file) => {
  const extension = getFileExtension(file);
  if (!extension) return null;

  const files = await getBase64(file);
  const url = await apis.upload.uploadBase64(files, extension);

  return url;
};

const replaceImageByS3Link = async (c) => {
  const regex = /src=['"]([^'"]+)['"]/g;
  const matches = c.match(regex) || [];
  let newContent = c;

  await Promise.all(
    matches.map(async (b) => {
      const src = b.match(/src=['"]([^'"]+)['"]/)[1];
      if (src.length < FILE_MAX_SIZE) return;

      const extension = src.substring(
        'data:image/'.length,
        src.indexOf(';base64'),
      );

      const link = await apis.upload.uploadBase64(src, extension);
      newContent = newContent.replace(src, link);
    }),
  );

  return newContent;
};

const handleUploadImage = async (file, enqueueSnackbar, t) => {
  try {
    if (!file) return '';
    if (typeof file === 'string') {
      return file;
    }
    const presignedUrl = await uploadBase64Image(file);
    if (presignedUrl) {
      return presignedUrl;
    }
    return null;
  } catch (e) {
    enqueueSnackbar(t(e.message), { variant: 'error' });
    return null;
  }
};

export {
  uploadImageFile,
  replaceImageByS3Link,
  uploadBase64Image,
  handleUploadImage,
};
