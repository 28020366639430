import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import apis from '@src/apis';
import { ALL, INIT_PAGINATION } from '@src/constants';
import { ROLE_TYPE } from '@src/constants/role';
import FilterAdmin from './FilterAdmin';
import ListAdmin from './ListAdmin';

const AdminManage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({
    admins: [],
    pagination: INIT_PAGINATION,
    roles: [],
  });
  const [filter, setFilter] = useState({ inputText: '', roleId: ALL });
  const [isRolesFetched, setIsRolesFetched] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchRoles = async () => {
    try {
      if (isRolesFetched) return;
      const roles = await apis.role.getRoles();
      if (roles) {
        setData((prevData) => ({
          ...prevData,
          roles:
            roles?.result?.filter((role) => role?.type === ROLE_TYPE.SYSTEM) ||
            [],
        }));
        setIsRolesFetched(true);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const fetchUsers = async (page) => {
    try {
      setLoading(true);
      if (!isRolesFetched) return;
      const params = {
        inputText: filter.inputText,
        roleId: filter.roleId === ALL ? null : filter.roleId,
        pageNum: page || data.pagination.page,
      };
      const res = await apis.admin.getUsers(params);
      if (res)
        setData((prevData) => ({
          ...prevData,
          admins: res?.result?.data || [],
          pagination: {
            page: res?.result?.pager?.currentPageNum || 1,
            limit: res?.result?.pager?.limit || 10,
            total: res?.result?.pager?.totalCount || 0,
            totalPages: res?.result?.pager?.lastPageNum || 1,
          },
        }));
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    fetchUsers(page);
  };

  const fetchData = async () => {
    await fetchRoles();
    await fetchUsers();
  };

  useEffect(() => {
    fetchData();
  }, [filter, isRolesFetched]);

  return (
    <>
      <FilterAdmin
        filter={filter}
        setFilter={setFilter}
        setData={setData}
        roles={data.roles}
      />
      <ListAdmin
        data={data.admins}
        roles={data.roles}
        setData={setData}
        pagination={data.pagination}
        handleChangePage={handleChangePage}
        loading={loading}
        fetchData={fetchData}
      />
    </>
  );
};

export default AdminManage;
