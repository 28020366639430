import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {
  GROUP_DESC_CHARACTER_LIMIT,
  GROUP_NAME_CHARACTER_LIMIT,
} from '@src/constants';
import { getChangedFields } from '@src/utils/object';
import { StyledDialog } from './index.style';

const EditGroup = ({
  name = '',
  description = '',
  onClose,
  open,
  onUpdate,
}) => {
  const [groupData, setGroupData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleCloseCreatePopup = () => {
    onClose();
    setGroupData({});
    setError({});
  };

  const isValid = () => {
    const errorData = {};

    if (!groupData?.name?.trim()) errorData.name = t('nameRequired');

    if (!groupData?.description)
      errorData.description = t('descriptionRequired');

    if (Object.keys(errorData).length) {
      setError(errorData);
      return false;
    }
    return true;
  };

  const onChangeGroupData = (key, value) => {
    setError({ ...error, [key]: '' });
    if (key === 'name' && value.length > GROUP_NAME_CHARACTER_LIMIT) return;
    if (key === 'description' && value.length > GROUP_DESC_CHARACTER_LIMIT)
      return;
    setGroupData({ ...groupData, [key]: value });
  };

  const handleDataUpdate = () => {
    const data = getChangedFields({ name, description }, groupData);

    return data;
  };

  const handleSave = async () => {
    if (!isValid()) return;
    setLoading(true);

    const dataUpdate = handleDataUpdate();
    await onUpdate(dataUpdate);
    handleCloseCreatePopup();
    setLoading(false);
  };

  useEffect(() => {
    if (open) setGroupData({ name, description });
  }, [open]);

  return (
    <StyledDialog open={open} onClose={handleCloseCreatePopup}>
      <div className="title">{t('changeGroupInfo')}</div>
      <DialogContent className="content">
        <Box>
          <Typography className="label-input" variant="h6">
            {t('groupName')}
            <span>*</span>
          </Typography>
          <TextField
            size="small"
            value={groupData.name}
            onChange={(e) => onChangeGroupData('name', e.target.value)}
            fullWidth
            error={!!error.name}
            helperText={error.name}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography
                    className=""
                    color="secondary"
                    variant="subtitle2"
                  >
                    {groupData.name?.length || 0}/{GROUP_NAME_CHARACTER_LIMIT}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Typography className="label-input" variant="h6">
            {t('description')}
            <span>*</span>
          </Typography>
          <Box className={`text-area ${!!error.description && 'error'}`}>
            <TextField
              size="small"
              variant="standard"
              multiline
              rows={5}
              value={groupData.description}
              onChange={(e) => onChangeGroupData('description', e.target.value)}
              fullWidth
            />
            <Typography textAlign="end" color="secondary" variant="subtitle2">
              {groupData.description?.length || 0}/{GROUP_DESC_CHARACTER_LIMIT}
            </Typography>
          </Box>
          {!!error.description && (
            <Typography color="error" variant="caption1" className="error-text">
              {error.description}
            </Typography>
          )}
        </Box>
        <Box className="action">
          <Button
            onClick={handleCloseCreatePopup}
            variant="outlined"
            className="button"
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleSave}
            autoFocus
            variant="contained"
            className="button"
            disabled={loading || !Object.keys(handleDataUpdate())?.length}
          >
            {t('save')}
          </Button>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default EditGroup;
