import { SERVICE } from '@src/constants';
import api from './api';

export const createGroupPermission = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/admin/group-permissions',
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const getGroupPermissions = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/admin/group-permissions',
    params,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const updateGroupPermission = async (groupPermissionId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/group-permissions/${groupPermissionId}`,
    data,
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const deleteGroupPermission = async (groupPermissionId) => {
  const res = await api({
    method: 'DELETE',
    url: `/admin/group-permissions/${groupPermissionId}`,
    source: SERVICE.ACCOUNT,
  });
  return res;
};
