import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField } from '@mui/material';
import apis from '@src/apis';
import { useSearchParams } from '@src/hooks';

const OrganizationSearch = () => {
  const { addParams, params, removeParams } = useSearchParams();
  const { organizationId: organizationIdFromParams } = params;

  const [organizations, setOrganizations] = useState([]);
  const [organizationSelected, setOrganizationSelected] = useState([]);
  const [inputText, setInputText] = useState('');

  const { t } = useTranslation();

  const fetchOrganization = async (organizationId) => {
    try {
      const response = await apis.csp.getOrganizationDetail(organizationId);
      if (response?.result) {
        setOrganizations([response?.result]);
        setOrganizationSelected(response?.result);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const fetchOrganizations = async (parameter = {}) => {
    try {
      const { organizationId, ...tmpParams } = parameter;
      const response = await apis.csp.getOrganizations({
        ...tmpParams,
        searchFields: 'name',
      });
      if (response?.status) {
        const organizationList = response?.result?.data || [];
        if (organizationId) {
          await fetchOrganization(organizationId);
          return;
        }
        setOrganizations(organizationList);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleInputChange = (newInputValue) => {
    if (!newInputValue) removeParams('organizationId');

    setInputText(newInputValue);
    setOrganizationSelected({});
    fetchOrganizations({ inputText: newInputValue });
  };

  const handleChangeOrganization = (value) => {
    if (!value?.id) return;
    addParams({ organizationId: value?.id, page: 1 });
  };

  useEffect(() => {
    if (!organizationIdFromParams) setOrganizationSelected({});

    if (organizationIdFromParams)
      fetchOrganizations({ organizationId: organizationIdFromParams });
  }, [organizationIdFromParams]);

  return (
    <Autocomplete
      fullWidth
      freeSolo
      size="small"
      options={organizations}
      inputValue={inputText || organizationSelected?.name || ''}
      onInputChange={(e, v) => handleInputChange(v)}
      onChange={(e, newValue) => handleChangeOrganization(newValue)}
      getOptionLabel={(option) => option?.name || option}
      renderInput={(parameter) => (
        <TextField {...parameter} label={t('organization')} />
      )}
    />
  );
};
export default OrganizationSearch;
