import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Box,
  Button,
  Divider,
} from '@mui/material';
import apis from '@src/apis';
import { Search, Delete } from '@mui/icons-material';
import { StyledDialog } from './index.style';

const AddAppPopup = ({ open, onClose, onAddApp }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [apps, setApps] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  const [selectedApps, setSelectedApps] = useState([]);

  const { t } = useTranslation();

  const confirmAddApp = () => {
    onAddApp(selectedApps);
    onClose(selectedApps);
  };

  const handleChangeApp = (value) => {
    if (!value) return;
    setSelectedApps([value]);

    // TODO - Uncomment this code if you want to allow multiple apps to be selected
    // setSelectedApps((prev) => {
    //   if (prev.find((item) => item.id === value.id)) return prev;
    //   return [...prev, value];
    // });
  };

  const fetchApps = async (params = {}) => {
    try {
      const response = await apis.app.getApps({
        ...params,
        available: true,
        limit: 5,
      });
      if (response?.status) {
        setApps(response?.result?.data || []);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleChangeFilterAppsInputText = (newInputValue) => {
    if (isFirst) {
      setIsFirst(false);
      return;
    }
    fetchApps({ inputText: newInputValue });
  };

  setTimeout(async () => {
    // Take the Reference of Close Button
    const close = document.getElementsByClassName(
      'MuiAutocomplete-clearIndicator',
    )[0];

    // Add a Click Event Listener to the button
    if (close)
      close.addEventListener('click', () => {
        fetchApps();
      });
  }, 100);

  useEffect(() => {
    setSelectedApps([]);
  }, [open]);

  return (
    <StyledDialog open={open} onClose={onClose}>
      <div className="title">{t('addApp')}</div>
      <DialogContent className="content">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              options={apps}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name || ''}
              onChange={(event, newValue) => handleChangeApp(newValue)}
              onFocus={() => fetchApps()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(event) => {
                    handleChangeFilterAppsInputText(event.target.value);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Search fontSize="small" sx={{ paddingLeft: '5px' }} />
                    ),
                  }}
                  placeholder={t('selectApp')}
                />
              )}
              noOptionsText={t('noData')}
            />
          </Grid>
        </Grid>
        <Box className="apps-list">
          {selectedApps.map((app) => (
            <Box key={app.id}>
              <Box
                display="flex"
                justifyContent="space-between"
                paddingX={3}
                paddingY={2}
                alignItems="center"
              >
                <Box display="flex" gap={1} alignItems="center">
                  <img
                    src={app.latestPublishedAppVersion?.appImageUrl}
                    height="20px"
                    width="20px"
                    layout="fill"
                    loading="lazy"
                    alt="avatar"
                  />
                  <span>{app?.name || '--'}</span>
                </Box>
                <Delete
                  color="error"
                  onClick={() => {
                    setSelectedApps((prev) =>
                      prev.filter((item) => item.id !== app.id),
                    );
                  }}
                  fontSize="small"
                  className="pointer"
                />
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className="action">
          <Button onClick={onClose} variant="outlined" className="button">
            {t('cancel')}
          </Button>
          <Button
            onClick={confirmAddApp}
            autoFocus
            variant="contained"
            className="button"
          >
            {t('add')}
          </Button>
        </Box>
      </DialogActions>
    </StyledDialog>
  );
};

export default AddAppPopup;
