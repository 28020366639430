import { SERVICE } from '@src/constants';
import api from './api';

export const getApps = async (params) => {
  const res = await api({
    method: 'GET',
    url: `/admin/apps`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getApp = async (appId) => {
  const res = await api({
    method: 'GET',
    url: `/admin/apps/${appId}`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getStatusCount = async (params) => {
  const res = await api({
    method: 'GET',
    url: `/admin/apps/status-stat`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const updateVersionStatus = async ({ versionId, action, data }) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/versions/${versionId}/${action}`,
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
