import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyleDialogCreate = styled(Box)`
  display: grid;
  gap: 16px;

  .date-picker {
    max-width: unset !important;
  }

  .input-box {
    display: flex;
    align-items: center;

    .required {
      color: ${COLOR.red[100]};
    }
  }

  .label-input {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;

    span {
      color: ${COLOR.error.base};
    }
  }

  .error {
    border-color: ${COLOR.error.base};
    margin-bottom: 2px;

    :hover {
      border-color: ${COLOR.error.base};
    }
  }

  .error-text {
    margin-left: 15px;
  }

  .action {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding: 12px 0 0 0;

    .button {
      min-width: 120px;
    }
  }
`;

export const StyleFilter = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 16px;

  .title {
    white-space: nowrap;
  }

  .right-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
    width: fill-available;
  }

  .select {
    min-width: 180px;
    .MuiOutlinedInput-root {
      border-radius: 12px;
      background-color: ${COLOR.darkBlue[8]};
      color: ${COLOR.darkestBlue[100]};

      fieldset {
        border: none;
      }
    }
  }

  .input-search {
    min-width: 350px;
  }

  .button-create-owner {
    min-width: fit-content;
  }

  .reload-button {
    border: 1px solid ${COLOR.darkBlue[32]};
  }
`;

export const StyleSearchUserAutocomplete = styled(Box)`
  .user-info {
    font-size: 12px;
    color: ${COLOR.blueGray[64]};
  }

  .select-user {
    height: 42px;
  }
`;

export const StyleCSPManage = styled(Box)`
  .tabs {
    margin-bottom: 10px;
  }
`;
