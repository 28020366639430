import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  APP_VERSION_STATUS,
  VERSION_ACTION,
  FILE_MAX_SIZE,
} from '@src/constants';
import apis from '@src/apis';

import { StyledRefusalReasonForm } from './index.style';
import RickTextEditor from '../../components/RickTextEditor';

const RefusalReasonPopup = ({ open, onClose, updateVersion, version }) => {
  const [reason, setReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();

  const handleReset = () => onClose();

  const handleSubmit = async () => {
    setIsSubmitting(true);

    let action;
    switch (version?.status) {
      case APP_VERSION_STATUS.WAITING_REVIEW:
        action = VERSION_ACTION.REJECT;
        break;

      case APP_VERSION_STATUS.PUBLISHED:
        action = VERSION_ACTION.BAN;
        break;

      default:
    }

    const regex = /src=['"]([^'"]+)['"]/g;
    const matches = reason.match(regex) || [];
    let newReason = reason;

    await Promise.all(
      matches.map(async (b) => {
        const src = b.match(/src=['"]([^'"]+)['"]/)[1];
        if (src.length < FILE_MAX_SIZE) return;

        const extension = src.substring(
          'data:image/'.length,
          src.indexOf(';base64'),
        );

        const link = await apis.upload.uploadBase64(src, extension);
        newReason = newReason.replace(src, link);
      }),
    );

    await updateVersion({ action, data: { reason: newReason } });

    onClose();
    setIsSubmitting(false);
  };

  const handleChangeReason = (r) => setReason(r);

  const handleClose = () => {
    if (!isSubmitting) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      height="100%"
      PaperProps={{ sx: { borderRadius: 4 } }}
      enforceFocus={false}
    >
      <StyledRefusalReasonForm>
        <Typography variant="h3">{t('refusalReason')}</Typography>
        <RickTextEditor
          content={reason}
          onChangeContent={handleChangeReason}
          readonly={isSubmitting}
        />

        <div className="action">
          <LoadingButton
            variant="outlined"
            onClick={handleReset}
            size="large"
            loading={isSubmitting}
          >
            {t('cancel')}
          </LoadingButton>
          <LoadingButton
            onClick={handleSubmit}
            loading={isSubmitting}
            variant="contained"
            size="large"
          >
            {t('save')}
          </LoadingButton>
        </div>
      </StyledRefusalReasonForm>
    </Dialog>
  );
};

export default RefusalReasonPopup;
