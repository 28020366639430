import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import apis from '@src/apis';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { ArrowBackIosRounded } from '@mui/icons-material';
import { regexEmail, regexName, regexPassword } from '@src/constants/regex';
import { StyleDialogCreate } from './index.style';

const INITIAL_STATE = {
  name: '',
  email: '',
  password: '',
};

const CreateOwner = ({
  setOpenPopupCreateOwner,
  setOwners,
  updateOwnerCsp,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(INITIAL_STATE);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isError, setIsError] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);

  const validate = () => {
    if (!data.name || !data.email || !data.password || !confirmPassword) {
      setIsError(true);
      return false;
    }
    if (!regexName.test(data.name)) {
      setIsError(true);
      return false;
    }
    if (!regexEmail.test(data.email)) {
      setIsError(true);
      return false;
    }
    if (!regexPassword.test(data.password)) {
      setIsError(true);
      return false;
    }
    if (data.password !== confirmPassword) {
      setIsError(true);
      return false;
    }
    setIsError(false);
    return true;
  };

  const handleCreateOwner = async () => {
    try {
      if (!validate()) return;
      setIsError(false);
      const checkEmail = await apis.user.checkUserExist({ email: data.email });
      if (checkEmail?.result) {
        setIsEmailExist(true);
        return;
      }
      const newOwner = { ...data, id: uuidv4(), isNew: true };
      setOwners((prevOwners) => [...prevOwners, newOwner]);
      updateOwnerCsp((prevData) => ({ ...prevData, ownerData: newOwner }));
      setOpenPopupCreateOwner(false);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const isErrorEmail = () => {
    if (isEmailExist) return true;
    if (isError && (!data.email || !regexEmail.test(data.email))) return true;
    return false;
  };

  const renderHelperTextEmail = () => {
    if (isEmailExist) {
      return t('userExists');
    }
    if (isError && (!data.email || !regexEmail.test(data.email))) {
      return t('invalidEmail');
    }
    return '';
  };

  return (
    <Box
      paddingX={3}
      paddingTop={1.25}
      paddingBottom={2}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <IconButton onClick={() => setOpenPopupCreateOwner(false)}>
          <ArrowBackIosRounded />
        </IconButton>
        <Typography variant="h3">{t('addOwner')}</Typography>
      </Box>
      <StyleDialogCreate>
        <Box className="input-box">
          <Typography variant="body1" minWidth={150}>
            {t('name')}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder={t('name')}
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            error={isError && (!data.name || !regexName.test(data.name))}
            helperText={
              isError &&
              (!data.name || !regexName.test(data.name)) &&
              t('nameInvalid')
            }
          />
        </Box>
        <Box className="input-box">
          <Typography variant="body1" minWidth={150}>
            {t('email')}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder={t('email')}
            value={data.email}
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
              if (isEmailExist) setIsEmailExist(false);
            }}
            error={isErrorEmail()}
            helperText={renderHelperTextEmail()}
          />
        </Box>
        <Box className="input-box">
          <Typography variant="body1" minWidth={150}>
            {t('password')}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            type="password"
            size="small"
            placeholder={t('password')}
            value={data.phoneNumber}
            onChange={(e) => setData({ ...data, password: e.target.value })}
            error={
              isError && (!data.password || !regexPassword.test(data.password))
            }
            helperText={
              isError &&
              (!data.password || !regexPassword.test(data.password)) &&
              t('invalidPassword')
            }
          />
        </Box>
        <Box className="input-box">
          <Typography variant="body1" minWidth={150}>
            {t('confirmPassword')}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            type="password"
            size="small"
            placeholder={t('confirmPassword')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={isError && data.password !== confirmPassword}
            helperText={
              isError &&
              data.password !== confirmPassword &&
              t('passwordNotMatch')
            }
          />
        </Box>
        <Box className="action">
          <Button
            variant="contained"
            className="button"
            onClick={handleCreateOwner}
          >
            {t('continue')}
          </Button>
        </Box>
      </StyleDialogCreate>
    </Box>
  );
};

export default CreateOwner;
