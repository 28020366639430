import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledAppDetail = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }

  .body-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .label-input {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;

    span {
      color: ${COLOR.error.base};
    }
  }
`;
