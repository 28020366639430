import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Skeleton } from '@mui/material';

const LoadingOverview = () => (
  <Grid container spacing={2.5}>
    {[...Array(4)].map(() => (
      <Grid item xs={12} md={6} key={uuidv4()}>
        <Skeleton
          variant="rectangular"
          height="calc((100vh - 377px) / 2)"
          width="100%"
        />
      </Grid>
    ))}
  </Grid>
);
export default LoadingOverview;
