/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import camelcase from 'camelcase';
import apis from '@src/apis';
import {
  SPECIAL_GROUP_LOCATION,
  SPECIAL_GROUP_TYPE,
  SPECIAL_GROUP_LAYOUT,
  PAGE_BLOCK_TYPE,
  GROUP_NAME_CHARACTER_LIMIT,
  GROUP_DESC_CHARACTER_LIMIT,
  DATA_FILTER_TIME,
  NUM_APPS,
  TIME_UNIT,
} from '@src/constants';
import CustomSelect, { NONE_VALUE } from '@src/components/CustomSelect';
import CustomNumberInput from '@src/components/CustomInput/CustomNumberInput ';
import { getChangedFields } from '@src/utils/object';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { COLOR } from '@src/styles/color';
import { StyledCreateGroupDialog } from './index.style';

const CreateAppGroup = ({
  location = SPECIAL_GROUP_LOCATION.CUSTOM,
  defaultCategoryId = '',
  fetchBlocks,
  onSelectCategory,
  onChangeSearchBlockName,
  onChangeType,
  onClose,
  open,
  layouts = () => {},
  selectedPage,
  id,
  onUpdate,
  groupApi,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const INITIAL_GROUP_DATA = {
    name: '',
    description: '',
    type: SPECIAL_GROUP_TYPE.MANUAL,
    layout: SPECIAL_GROUP_LAYOUT.LIST_FOCUS_LEFT,
    categoryId: defaultCategoryId,
  };

  const AutoAppGroupCriteria = {
    MOST_USED: 'MOST_USED',
    MOST_PURCHASED: 'MOST_PURCHASED',
    MOST_VIEWED: 'MOST_VIEWED',
  };

  const INITIAL_APP_AUTO_CONFIG = {
    criteria: NONE_VALUE,
    dataFilterTime: {
      type: DATA_FILTER_TIME.NO_LIMIT,
      value: '',
      unit: NONE_VALUE,
    },
    numApps: {
      type: NUM_APPS.NO_LIMIT,
      value: '',
    },
  };

  const [groupData, setGroupData] = useState(INITIAL_GROUP_DATA);
  const [error, setError] = useState({});
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [appAutoConfig, setAppAutoConfig] = useState(INITIAL_APP_AUTO_CONFIG);

  const inCategory = location === SPECIAL_GROUP_LOCATION.CATEGORY;

  const handleCloseCreatePopup = () => {
    onClose();
    setGroupData(INITIAL_GROUP_DATA);
    setAppAutoConfig(INITIAL_APP_AUTO_CONFIG);
    setError({});
  };

  const isValid = () => {
    const { name, description, categoryId, type, layout } = groupData;
    const { criteria, dataFilterTime, numApps } = appAutoConfig;
    const errorData = {};

    if (!name?.trim()) errorData.name = t('nameRequired');

    if (!description) errorData.description = t('descriptionRequired');

    if (!categoryId && inCategory && !id)
      errorData.categoryId = t('categoryRequired');

    if (!type) errorData.type = t('typeRequired');

    if (!layout) errorData.layout = t('layoutRequired');

    if (type === SPECIAL_GROUP_TYPE.AUTO) {
      if (!criteria) errorData.criteria = t('criteriaRequired');

      if (dataFilterTime.type === DATA_FILTER_TIME.CUSTOM) {
        errorData.dataFilterTime = {};
        if (!dataFilterTime.value) {
          errorData.dataFilterTime = {
            ...errorData.dataFilterTime,
            value: t('valueRequired'),
          };
        }
        if (!dataFilterTime.unit) {
          errorData.dataFilterTime = {
            ...errorData.dataFilterTime,
            unit: t('unitRequired'),
          };
        }
      }

      if (numApps.type === NUM_APPS.CUSTOM && !numApps.value) {
        errorData.numApps = { value: t('numAppsRequired') };
      }
    }

    if (
      errorData?.dataFilterTime &&
      !Object.keys(errorData?.dataFilterTime)?.length
    )
      delete errorData.dataFilterTime;

    if (Object.keys(errorData).length) {
      setError(errorData);
      return false;
    }
    return true;
  };

  const onChangeGroupData = (key, value) => {
    setError({ ...error, [key]: '' });

    if (key === 'name' && value.length > GROUP_NAME_CHARACTER_LIMIT)
      value = value.slice(0, GROUP_NAME_CHARACTER_LIMIT);
    if (key === 'description' && value.length > GROUP_DESC_CHARACTER_LIMIT)
      value = value.slice(0, GROUP_DESC_CHARACTER_LIMIT);

    setGroupData({ ...groupData, [key]: value });
  };

  const onChangeAppAutoConfig = (key, value) => {
    if (key.includes('.')) {
      const [parentKey, childKey] = key.split('.');
      setError({
        ...error,
        [parentKey]: { ...error[parentKey], [childKey]: '' },
      });
      setAppAutoConfig({
        ...appAutoConfig,
        [parentKey]: { ...appAutoConfig[parentKey], [childKey]: value },
      });
      return;
    }

    setError({ ...error, [key]: '' });
    setAppAutoConfig({ ...appAutoConfig, [key]: value });
  };

  const handleAppAutoConfigData = (data) => {
    const { criteria, dataFilterTime, numApps } = data;
    const {
      type: dataFilterTimeType,
      value: dataFilterTimeTypeValue,
      unit: dataFilterTimeTypeUnit,
    } = dataFilterTime || {};
    const { type: numAppsType, value: numAppsValue } = numApps || {};

    const dataHandle = {};

    if (criteria) dataHandle.criteria = criteria;
    if (dataFilterTimeType)
      dataHandle.dataFilterTime =
        dataFilterTimeType === DATA_FILTER_TIME.NO_LIMIT
          ? { type: dataFilterTimeType }
          : {
              type: dataFilterTimeType,
              value: dataFilterTimeTypeValue,
              unit: dataFilterTimeTypeUnit,
            };
    if (numAppsType)
      dataHandle.numApps =
        numAppsType === NUM_APPS.NO_LIMIT
          ? { type: numAppsType }
          : {
              type: numAppsType,
              value: numAppsValue,
            };

    return dataHandle;
  };

  const handleDataUpdate = () => {
    let data = getChangedFields(groupApi, { ...groupData, ...appAutoConfig });
    data = { ...data, ...handleAppAutoConfigData(data) };
    return data;
  };

  const handleSave = async () => {
    if (!isValid()) return;

    try {
      setLoading(true);

      if (id) {
        const dataUpdate = handleDataUpdate();
        await onUpdate(dataUpdate);
      } else {
        const data = {
          name: groupData.name,
          description: groupData.description,
          type: groupData.type,
          layout: groupData.layout,
        };
        if (inCategory) {
          data.location = SPECIAL_GROUP_LOCATION.CATEGORY;
          data.categoryId = groupData.categoryId;
        } else {
          data.location = selectedPage.key || SPECIAL_GROUP_LOCATION.CUSTOM;
        }

        if (groupData.type === SPECIAL_GROUP_TYPE.AUTO)
          data.autoGroupData = handleAppAutoConfigData(appAutoConfig);

        const response = await apis.specialGroup.createSpecialGroup(data);

        if (!response?.status) throw new Error();

        enqueueSnackbar(t('createSpecialGroupSuccess'), { variant: 'success' });
        onSelectCategory({ id: groupData.categoryId });
        onChangeSearchBlockName('');
        onChangeType('');
        fetchBlocks();
      }

      handleCloseCreatePopup();
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async (params = {}) => {
    try {
      const response = await apis.category.getCategories({
        ...params,
        limit: 100,
      });
      if (response?.status) {
        setCategories(response?.result?.data || []);
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  const handleReset = () => {
    setGroupData({
      name: groupApi.name,
      description: groupApi.description,
      type: groupApi.type,
      layout: groupApi.layout,
      categoryId: groupApi.categoryId,
    });

    setAppAutoConfig({
      criteria: groupApi.criteria,
      dataFilterTime: groupApi.dataFilterTime,
      numApps: groupApi.numApps,
    });
  };

  useEffect(() => {
    if (open && inCategory) fetchCategories();
  }, [open]);

  useEffect(() => {
    if (defaultCategoryId) {
      setGroupData({ ...groupData, categoryId: defaultCategoryId });
    }
  }, [defaultCategoryId]);

  useEffect(() => {
    if (open && id) {
      const {
        name,
        description,
        type,
        layout,
        criteria,
        dataFilterTime,
        numApps,
      } = groupApi;
      setGroupData({ name, description, type, layout });
      setAppAutoConfig({
        criteria,
        dataFilterTime,
        numApps,
      });
    }
  }, [open, id]);

  return (
    <StyledCreateGroupDialog
      className="dialog"
      open={open}
      onClose={handleCloseCreatePopup}
    >
      <DialogTitle className="dialog-title">
        {t(id ? 'changeGroupInfo' : 'createAppGroup')}
        {id && (
          <IconButton onClick={handleCloseCreatePopup}>
            <FontAwesomeIcon
              icon={faXmark}
              color={COLOR.text.highlight}
              size="sm"
            />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className="label-input" variant="h6">
              {t('groupName')}
              <span>*</span>
            </Typography>
            <TextField
              autoFocus
              size="small"
              value={groupData.name}
              onChange={(e) => onChangeGroupData('name', e.target.value)}
              fullWidth
              error={!!error.name}
              helperText={error.name}
              placeholder={t('inputAppGroupName')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      className=""
                      color="secondary"
                      variant="subtitle2"
                    >
                      {groupData.name?.length || 0}/{GROUP_NAME_CHARACTER_LIMIT}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className="label-input" variant="h6">
              {t('layout')}
              <span>*</span>
            </Typography>
            <CustomSelect
              options={layouts(groupData.layout, PAGE_BLOCK_TYPE.SPECIAL_GROUP)}
              value={groupData.layout}
              onChange={(value) => onChangeGroupData('layout', value)}
              error={!!error.layout}
              helperText={error.layout}
              disabled={!!id}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className="label-input" variant="h6">
              {t('howAddApp')}
            </Typography>
            <CustomSelect
              options={Object.values(SPECIAL_GROUP_TYPE).map((type) => ({
                value: type,
                label: t(camelcase(type)),
              }))}
              value={groupData.type}
              onChange={(value) => onChangeGroupData('type', value)}
              error={!!error.type}
              helperText={error.type}
              disabled={!!id}
            />
          </Grid>

          {groupData.type === SPECIAL_GROUP_TYPE.AUTO && (
            <>
              <Grid item xs={6}>
                <Typography className="label-input" variant="h6">
                  {t('criteria')}
                  <span>*</span>
                </Typography>
                <CustomSelect
                  options={Object.values(AutoAppGroupCriteria).map((item) => ({
                    value: item,
                    label: t(camelcase(item)),
                  }))}
                  value={appAutoConfig.criteria}
                  onChange={(value) => onChangeAppAutoConfig('criteria', value)}
                  placeholder={t('selectCriteria')}
                  error={!!error?.criteria}
                  helperText={error?.criteria}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className="label-input" variant="h6">
                  {t('dataFilterTime')}
                </Typography>
                <CustomSelect
                  options={Object.values(DATA_FILTER_TIME).map((item) => ({
                    value: item,
                    label: t(camelcase(item)),
                  }))}
                  value={appAutoConfig.dataFilterTime.type}
                  onChange={(value) =>
                    onChangeAppAutoConfig('dataFilterTime.type', value)
                  }
                  placeholder={t('selectTime')}
                />
                {appAutoConfig.dataFilterTime.type ===
                  DATA_FILTER_TIME.CUSTOM && (
                  <Box marginTop="8px">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <CustomNumberInput
                          value={appAutoConfig.dataFilterTime.value}
                          onChange={(value) =>
                            onChangeAppAutoConfig('dataFilterTime.value', value)
                          }
                          error={!!error?.dataFilterTime?.value}
                          helperText={error?.dataFilterTime?.value}
                          placeholder={t('inputValue')}
                          isHideIcon
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomSelect
                          options={Object.values(TIME_UNIT).map((item) => ({
                            value: item,
                            label: t(camelcase(item)),
                          }))}
                          value={appAutoConfig.dataFilterTime.unit}
                          onChange={(value) =>
                            onChangeAppAutoConfig('dataFilterTime.unit', value)
                          }
                          error={!!error?.dataFilterTime?.unit}
                          helperText={error?.dataFilterTime?.unit}
                          placeholder={t('unit')}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography className="label-input" variant="h6">
                  {t('numApps')}
                </Typography>
                <CustomSelect
                  options={Object.values(NUM_APPS).map((item) => ({
                    value: item,
                    label: t(camelcase(item)),
                  }))}
                  value={appAutoConfig.numApps.type}
                  onChange={(value) =>
                    onChangeAppAutoConfig('numApps.type', value)
                  }
                  placeholder={t('selectNums')}
                />
                {appAutoConfig.numApps.type === NUM_APPS.CUSTOM && (
                  <Box marginTop="8px">
                    <CustomNumberInput
                      value={appAutoConfig.numApps.value}
                      onChange={(value) =>
                        onChangeAppAutoConfig('numApps.value', value)
                      }
                      error={!!error?.numApps?.value}
                      helperText={error?.numApps?.value}
                      placeholder={t('inputNumsApp')}
                    />
                  </Box>
                )}
              </Grid>
            </>
          )}

          {inCategory && !id && (
            <Grid item xs={6}>
              <Typography className="label-input" variant="h6">
                {t('category')}
                <span>*</span>
              </Typography>
              <CustomSelect
                options={Object.values(categories).map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
                value={groupData.categoryId}
                onChange={(value) => onChangeGroupData('categoryId', value)}
                error={!!error.categoryId}
                helperText={error.categoryId}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography className="label-input" variant="h6">
              {t('description')}
              <span>*</span>
            </Typography>
            <Box className={`text-area ${!!error.description && 'error'}`}>
              <TextField
                size="small"
                variant="standard"
                multiline
                rows={4}
                value={groupData.description}
                onChange={(e) =>
                  onChangeGroupData('description', e.target.value)
                }
                fullWidth
                placeholder={t('inputDescription')}
              />
              <Typography textAlign="end" color="secondary" variant="subtitle2">
                {groupData.description?.length || 0}/
                {GROUP_DESC_CHARACTER_LIMIT}
              </Typography>
            </Box>
            {!!error.description && (
              <Typography
                color="error"
                variant="caption1"
                className="error-text"
              >
                {error.description}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className="action">
          {id ? (
            <Button onClick={handleReset} variant="outlined" className="button">
              {t('reset')}
            </Button>
          ) : (
            <Button
              onClick={handleCloseCreatePopup}
              variant="outlined"
              className="button"
            >
              {t('cancel')}
            </Button>
          )}
          <Button
            onClick={handleSave}
            autoFocus
            variant="contained"
            className="button"
            disabled={loading}
          >
            {id ? t('save') : t('create')}
          </Button>
        </Grid>
      </DialogContent>
    </StyledCreateGroupDialog>
  );
};

export default CreateAppGroup;
