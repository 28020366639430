import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@mui/material';

import ROUTES from '@src/constants/route';
import CustomTable from '@src/components/CustomTable';

const ListAdmin = ({ data, pagination, handleChangePage, loading }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const heads = [
    {
      label: t('stt'),
      valueName: 'index',
      align: 'center',
    },
    {
      label: t('name'),
      valueName: 'name',
      align: 'left',
    },
    {
      label: t('phoneNumber'),
      valueName: 'phoneNumber',
      align: 'left',
    },
    {
      label: t('status'),
      valueName: 'status',
      align: 'center',
    },
  ];

  const handleClickRow = (user) => {
    history.push(`${ROUTES.USER}/${user.id}`);
  };

  const formatData = data.map((el) => ({
    ...el,
    email: (
      <>
        {el.email ? (
          <Box onClick={() => handleClickRow(el)} className="email">
            {el.email}
          </Box>
        ) : (
          '-'
        )}
      </>
    ),
    name: el.name || '-',
    phoneNumber: el.phoneNumber || '',
    status: (
      <Chip
        label={el.active ? t('active') : t('deactive')}
        color={el.active ? 'success' : 'error'}
      />
    ),
  }));

  return (
    <>
      <CustomTable
        items={formatData}
        heads={heads}
        pagination={pagination}
        onChangePagination={handleChangePage}
        onClickRow={handleClickRow}
        loading={loading}
      />
    </>
  );
};

export default ListAdmin;
