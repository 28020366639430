import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import CustomerDrawer from '@src/components/CustomDrawer';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import apis from '@src/apis';
import { Delete } from '@mui/icons-material';

const INITIAL_STATE = { name: '', key: '', description: [] };
const regexKey = /^[A-Z]+(_[A-Z]+)*$/;

const DrawerCreateRole = ({
  open,
  handleClose,
  setRoles,
  tab,
  item,
  setPermissionsChange,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(INITIAL_STATE);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [description, setDescription] = useState(['']);
  const isEdit = !!item;
  const disableDeleteDescription = description.length === 1;

  const handleReset = () => {
    const dataReset = isEdit
      ? { name: item.name, key: item.key, description: item.description }
      : INITIAL_STATE;
    setData(dataReset);
    setDescription(item?.description || ['']);
    setIsError(false);
  };

  const handleChangeName = (e) => {
    if (!isEdit) {
      const key = e.target.value
        .toUpperCase()
        .replace(/[^A-Z_]/g, '_')
        .replace(/_+/g, '_');

      setData({ ...data, name: e.target.value, key });
      return;
    }
    setData({ ...data, name: e.target.value });
  };

  const handleChangeKey = (e) =>
    setData({ ...data, key: e.target.value.toUpperCase() });

  const handleChangeDescription = (e, index) => {
    const newData = [...description];
    newData[index] = e.target.value;
    setDescription(newData);
  };

  const handleDeleteDescription = (index) => {
    const newData = [...description];
    newData.splice(index, 1);
    setDescription(newData);
  };

  const handleAddDescription = () => {
    setDescription([...description, '']);
  };

  const validate = () => {
    if (!data.name || !data.key || !regexKey.test(data.key)) {
      setIsError(true);
      return false;
    }
    setIsError(false);
    return true;
  };

  const handleSubmit = async () => {
    try {
      if (!validate()) return;
      setIsSubmitting(true);
      const descriptionUpdate = description.filter((el) => el);
      if (isEdit) {
        const res = await apis.role.updateRole(item.id, {
          name: data.name,
          description: descriptionUpdate,
        });
        if (!res) throw new Error();
        setRoles((prev) => ({
          ...prev,
          data: prev.data.map((el) => {
            if (el.id === item.id)
              return { ...el, name: data.name, description: descriptionUpdate };
            return el;
          }),
        }));
        setData({
          ...data,
          name: res?.result.name,
          description: res?.result.description,
        });
        setIsError(false);
        enqueueSnackbar(t('updateRoleSuccess'), { variant: 'success' });
      } else {
        const res = await apis.role.createRole({
          ...data,
          type: tab,
          description: descriptionUpdate,
        });
        if (!res) throw new Error();
        setRoles((prev) => ({
          ...prev,
          data: [...prev.data, res?.result],
          selected: res?.result,
        }));
        setPermissionsChange([]);
        handleReset();
        enqueueSnackbar(t('createRoleSuccess'), { variant: 'success' });
      }
      handleClose();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (item) {
      setData({
        name: item.name,
        key: item.key,
        description: item.description,
      });
      setDescription(item?.description || ['']);
    }
  }, [item]);

  return (
    <CustomerDrawer
      open={open}
      onClose={handleClose}
      title={isEdit ? t('updateRole') : t('addRole')}
    >
      <Typography>{t('roleName')}</Typography>
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        placeholder={t('roleNamePlaceholder')}
        name="name"
        value={data.name}
        onChange={handleChangeName}
        error={isError && !data.name}
        helperText={isError && !data.name && t('fieldRequired')}
      />
      <Typography mt={2}>{t('Key')}</Typography>
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        placeholder={t('keyPlaceholder')}
        name="key"
        value={data.key}
        onChange={handleChangeKey}
        disabled={isEdit}
        error={isError && (!data.key || !regexKey.test(data.key))}
        helperText={
          isError && (!data.key || !regexKey.test(data.key)) && t('keyInvalid')
        }
      />
      <Typography mt={2}>{t('description')}</Typography>
      <Box display="flex" flexDirection="column" gap={1}>
        {description.map((el, index) => (
          <TextField
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            variant="outlined"
            fullWidth
            size="small"
            placeholder={t('descriptionPlaceholder')}
            value={el}
            onChange={(e) => handleChangeDescription(e, index)}
            InputProps={
              !disableDeleteDescription
                ? {
                    endAdornment: (
                      <IconButton
                        onClick={() => handleDeleteDescription(index)}
                      >
                        <Delete color="error" />
                      </IconButton>
                    ),
                  }
                : {}
            }
          />
        ))}
        <Box>
          <Button size="small" onClick={handleAddDescription}>
            {t('addDescription')}
          </Button>
        </Box>
      </Box>
      <Box mt={3} display="flex" gap={1}>
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitting}
          variant="contained"
        >
          {isEdit ? t('save') : t('createNow')}
        </LoadingButton>
        <Button variant="outlined" onClick={handleReset}>
          {t('reset')}
        </Button>
      </Box>
    </CustomerDrawer>
  );
};

export default DrawerCreateRole;
