import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledRickTextEditor = styled(Box)`
  .jodit-wysiwyg {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${COLOR.blueGray[16]};
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${COLOR.blueGray[4]};
      border-radius: 4px;
    }
  }
`;
