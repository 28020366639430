import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledLogin = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${COLOR.standardBlue[100]};

  .card {
    border-radius: 24px;
    background-color: ${COLOR.white[100]};
    background-blend-mode: color-dodge, normal;
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.32);
    backdrop-filter: blur(75px);
    width: 480px;
  }

  .header {
    padding: 20px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${COLOR.outlined[16]};
  }

  .body {
    padding: 40px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    .input-card {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .title {
      color: ${COLOR.darkBlue[100]};
    }
  }

  .input {
    .MuiOutlinedInput-root {
      height: 48px;
    }

    svg {
      margin-right: 4px;
      color: ${COLOR.blueGray[64]};
    }
  }

  .copyright {
    color: ${COLOR.blueGray[64]};
  }
`;
