import { TextField, MenuItem } from '@mui/material';
import React from 'react';
import { StyledPlaceholder } from './index.style';

export const NONE_VALUE = '';

const CustomSelect = ({
  className = '',
  placeholder = '',
  label = '',
  options = [],
  value,
  onChange,
  helperText,
  error,
  disabled,
}) => {
  const handleChange = (event) => {
    if (onChange) onChange(event.target.value);
  };

  return (
    <TextField
      className={className}
      size="small"
      variant="outlined"
      select
      fullWidth
      defaultValue={NONE_VALUE}
      disabled={disabled}
      value={value || value === false ? value : NONE_VALUE}
      SelectProps={{
        displayEmpty: true,
        renderValue: (selected) => {
          if (selected === NONE_VALUE)
            return <StyledPlaceholder>{placeholder}</StyledPlaceholder>;

          const item = options.find((e) => e.value === selected);
          return item?.label;
        },
      }}
      onChange={handleChange}
      error={error}
      helperText={helperText}
      label={label}
    >
      {options.map((option, index) => (
        <MenuItem key={`${index.toString()}`} value={option.value}>
          {option?.customLabel || option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CustomSelect;
