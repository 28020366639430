import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@mui/material';
import ListCSP from './ListCSP';
import { StyleCSPManage } from './index.style';
import ListOwner from './ListOwner';

const TABS = {
  CSP: 0,
  OWNER: 1,
};

const CSPManage = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState(TABS.CSP);

  const tabs = [
    {
      label: t('listCSP'),
    },
    {
      label: t('ownerList'),
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <StyleCSPManage>
      <Tabs value={tab} onChange={handleChangeTab} className="tabs">
        {tabs.map((item, index) => (
          <Tab
            key={item.label}
            label={item.label}
            value={index}
            className="tab"
          />
        ))}
      </Tabs>
      {tab === TABS.CSP && <ListCSP />}
      {tab === TABS.OWNER && <ListOwner />}
    </StyleCSPManage>
  );
};

export default CSPManage;
