import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyleEndUser = styled(Box)`
  .email {
    color: ${COLOR.darkestBlue[100]};
  }
`;

export const StyleFilterUser = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;

  .select {
    min-width: 180px;
    .MuiOutlinedInput-root {
      border-radius: 12px;
      background-color: ${COLOR.darkBlue[8]};
      color: ${COLOR.darkestBlue[100]};

      fieldset {
        border: none;
      }
    }
  }

  .input-search {
    .MuiOutlinedInput-root {
      border-radius: 24px;

      fieldset {
        border-color: ${COLOR.darkBlue[32]};
      }
    }
  }
`;
