import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import OverviewStats from './OverviewStats';
import Report from '../Report';

const STATS_PAGE = { STATISTIC: 'STATISTIC', REPORT: 'REPORT' };

const Stats = () => {
  const [tab, setTab] = useState(STATS_PAGE.STATISTIC);

  const tabs = [
    { label: 'Thống kê', value: STATS_PAGE.STATISTIC },
    { label: 'Báo cáo', value: STATS_PAGE.REPORT },
  ];

  const handleChangeTab = (event, newValue) => {
    // TODO do something
    setTab(newValue);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2.5}
      >
        <Tabs value={tab} onChange={handleChangeTab} className="tabs">
          {tabs.map((item) => (
            <Tab
              key={item.label}
              label={item.label}
              value={item.value}
              className="tab"
            />
          ))}
        </Tabs>
      </Box>
      {tab === STATS_PAGE.STATISTIC && (
        <OverviewStats isReLoad={tab === STATS_PAGE.STATISTIC} />
      )}
      {tab === STATS_PAGE.REPORT && (
        <Report
          isReLoad={tab === STATS_PAGE.REPORT}
          tableMaxHeight="calc(100vh - 210px)"
        />
      )}
    </>
  );
};
export default Stats;
