import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { ALL } from '@src/constants';
import debounce from '@src/utils/debounce';
import { StyleFilterUser } from './index.style';

const FilterAdmin = ({ filter, setFilter, setData }) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState(filter.inputText);

  const handleChangeInputText = (e) => {
    setInputText(e.target.value);
    debounce(() => setFilter({ ...filter, inputText: e.target.value }))();
    setData((prevData) => ({
      ...prevData,
      pagination: {
        page: 1,
        limit: prevData.pagination.limit,
        total: prevData.pagination.total,
        totalPages: prevData.pagination.totalPages,
      },
    }));
  };

  useEffect(() => {
    setInputText(filter.inputText);
  }, [filter.inputText]);

  return (
    <StyleFilterUser>
      <Typography variant="h3">{t('listEndUser')}</Typography>
      <Box marginLeft="auto" />
      <TextField
        size="small"
        variant="outlined"
        className="input-search"
        placeholder={t('searchEndUser')}
        value={inputText}
        onChange={handleChangeInputText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="primary" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        select
        label={filter.active === null ? t('status') : ''}
        variant="outlined"
        size="small"
        className="select"
        value={filter.active}
        onChange={(e) => setFilter({ ...filter, active: e.target.value })}
        InputLabelProps={{ shrink: false }}
      >
        <MenuItem value={ALL}>{t('all')}</MenuItem>
        <MenuItem value>{t('active')}</MenuItem>
        <MenuItem value={false}>{t('deactive')}</MenuItem>
      </TextField>
    </StyleFilterUser>
  );
};

export default FilterAdmin;
