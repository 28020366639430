/* eslint-disable react/no-danger */
import React from 'react';
import { DialogTitle, DialogContent, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledDialog } from './index.style';

const PreviewPopup = ({ open, onClose, header, content }) => {
  const { t } = useTranslation();
  return (
    <StyledDialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title" variant="h3">
        {t('preview')}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div> {header} </div>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </DialogContent>
    </StyledDialog>
  );
};

export default PreviewPopup;
