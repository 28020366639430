import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledApps = styled(Box)`
  .filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .filter-input {
      display: flex;
      gap: 8px;

      .input-search {
        width: 250px;
      }

      .reload-button {
        border: 1px solid ${COLOR.darkBlue[32]};
        width: 42px;
        height: 42px;
      }

      .clear-icon {
        display: none;
        cursor: pointer;
      }

      .text-search {
        :hover {
          .clear-icon {
            display: block;
          }
        }
      }
    }
  }

  .content {
    display: flex;

    .app-table {
      width: fill-available;
    }

    .status-select {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      color: ${COLOR.blueGray[80]};

      .selected {
        background: ${COLOR.darkestBlue[16]};
      }

      .status-button {
        white-space: nowrap;
        align-self: stretch;
        font-weight: 500;
        line-height: 1.5;
        padding: 6px 12px;
        border-radius: 12px;

        &:hover {
          cursor: pointer;
          background: ${COLOR.darkestBlue[8]};
        }

        .label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 30px;
          width: 100%;

          .status-count {
            font-size: 12px;
            border-radius: 50%;
            padding: 0 4px;
            background: ${COLOR.darkestBlue[16]};
          }
        }
      }
    }
  }
`;
