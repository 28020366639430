import ROUTES from '@src/constants/route';

export const sideBarListOptions = [
  {
    id: 1,
    label: 'Thông tin ứng dụng',
    path: ROUTES.APP_DETAIL_INFO,
  },
  {
    id: 3,
    label: 'Lịch sử phát hành',
  },
  {
    id: 4,
    label: 'Quản lý gói cước',
    path: ROUTES.APP_PACKAGES_MANAGEMENT,
  },
];
