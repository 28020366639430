import { Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledPopup = styled(Dialog)`
  .MuiDialog-paper {
    min-width: ${(props) => props.width || '512px'};
    padding: 32px !important;
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px !important;
    margin: 16px 0px;

    span {
      color: ${COLOR.error.base};
    }
  }

  .dialog-action {
    padding: 0px !important;

    .button {
      padding: 8px 24px;
      margin-right: 16px;
      min-width: 120px;
    }
  }
`;

export const StyleCreateUser = styled(Box)`
  display: grid;
  gap: 16px;
  margin-bottom: 8px;

  .textfield-input {
    .MuiOutlinedInput-root {
      border-radius: 24px;
      height: 48px;
      background-color: ${COLOR.darkBlue[4]};

      fieldset {
        border: none;
      }
    }
  }

  .input-box {
    display: flex;
    align-items: center;

    .required {
      color: ${COLOR.red[100]};
    }
  }
`;
