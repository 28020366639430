import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledCustomDatePicker = styled(Box)`
  width: ${(props) => props.width || '100%'};

  .MuiTextField-root {
    width: 100%;
    min-width: 100px;
  }

  .MuiOutlinedInput-root {
    width: 100%;
    padding-left: 10.5px;
    padding-right: 0px;
    input {
      padding: 8.5px 0;
      height: fit-content;
      color: ${COLOR.text.primary};
    }
  }

  .MuiIconButton-root {
    color: ${COLOR.text.primary};
    margin-right: 5px;
    svg {
      width: 20px;
      margin-right: 0;
    }
  }

  .MuiIconButton-label {
    color: ${COLOR.black};
  }
`;
