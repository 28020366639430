import { actionTypes } from './actions';

export const initialState = {
  userInfo: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER: {
      const { userInfo } = action;
      return { ...state, userInfo };
    }
    default:
      return state;
  }
};

export default userReducer;
