import { Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledAppDetail = styled(Box)`
  position: relative;
  height: calc(100vh - 34px);

  .custom-card {
    display: ${({ ispreview }) => (ispreview ? 'none' : 'flex')};
    flex-direction: column;
    gap: 24px;
  }

  .header-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }

  .body-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 64px;
  }

  .label-input {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;

    span {
      color: ${COLOR.error.base};
    }
  }

  .filter-card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
      display: flex;
      gap: 12px;
    }
  }

  .tabs {
    min-height: unset;

    .tab {
      padding: 6px 16px;
      min-width: 160px;
      min-height: unset;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .search-input {
    width: 280px;
  }

  .select {
    width: 210px;
    .MuiOutlinedInput-root {
      border-radius: 12px;
      background-color: ${COLOR.darkBlue[8]};
      color: ${COLOR.darkestBlue[100]};

      fieldset {
        border: none;
      }
    }

    .MuiInputLabel-root {
      color: ${COLOR.darkestBlue[100]} !important;
      opacity: 1 !important;
    }
  }

  .select-layout {
    width: 160px;
    .MuiOutlinedInput-root {
      border-radius: 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      fieldset {
        border: none;
      }
    }
  }

  .reload-button {
    border: 1px solid ${COLOR.darkBlue[32]};
  }

  .footer-card {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: fill-available;
    position: absolute;
    bottom: 0;
    background-color: ${COLOR.white[100]};
    padding: 16px 32px 32px 32px;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      min-width: 128px;
    }
  }

  .iframe-container {
    position: relative;
    width: calc(100% - 8px);
    height: 91%;
    overflow: hidden;
    background-color: ${COLOR.white[100]};
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.24);
    border-radius: 20px 20px 0 0;
    border: 4px solid ${COLOR.standardBlue[100]};

    // #embeddedIframe {
    //   pointer-events: none;
    // }
  }

  .box-shadow {
    box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.3);
  }
`;

export const StyledCreateGroupDialog = styled(Dialog)`
  .dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  .label-input {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;

    span {
      color: ${COLOR.error.base};
    }
  }

  .action {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding: 24px 0px 0 24px;

    .button {
      min-width: 120px;
    }
  }

  .tabs {
    margin: 12px 0px;
    min-height: unset;

    .tab {
      padding: 6px 16px;
      min-width: 160px;
      min-height: unset;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .tab-panel {
    margin-bottom: 12px;
  }

  .content {
    display: flex;
    gap: 16px;
    flex-direction: column;
    min-width: 550px;
  }

  .text-area {
    padding: 8.5px 14px;
    border-radius: 24px;
    border: 1px solid ${COLOR.border.default};

    :hover {
      border-color: ${COLOR.border.hover};
    }
  }

  .error {
    border-color: ${COLOR.error.base};
    margin-bottom: 2px;

    :hover {
      border-color: ${COLOR.error.base};
    }
  }

  .error-text {
    margin-left: 15px;
  }
`;

export const StyledCategorySelect = styled(Box)`
  .category {
    display: flex;
    align-items: center;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid ${COLOR.darkBlue[4]};
    color: ${COLOR.darkestBlue[100]};
    padding: 8px 12px !important;
    gap: 12px;
    width: 100%;
    height: 41px !important;
    justify-content: flex-start;

    &:hover {
      cursor: pointer;
      background-color: ${COLOR.darkBlue[4]};
      border-bottom: 1px solid ${COLOR.darkBlue[32]};
    }
  }

  .active {
    background-color: ${COLOR.darkBlue[8]};
    border-bottom: 1px solid ${COLOR.darkBlue[32]};
  }

  .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    white-space: nowrap;
  }

  .active {
    background-color: ${COLOR.darkBlue[8]};
  }
`;
