import React from 'react';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { COLOR } from '@src/styles/color';

const CustomerDrawer = ({ open, onClose, title, children }) => (
  <Drawer anchor="right" open={open} onClose={onClose}>
    <Box width={500}>
      <Box
        display="flex"
        justifyContent="space-between"
        paddingX={3}
        paddingY={1}
        alignItems="center"
        bgcolor={COLOR.blueGray[16]}
      >
        <Typography>{title}</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box padding={3}>{children}</Box>
    </Box>
  </Drawer>
);
export default CustomerDrawer;
