// Add dot separate number, for example: 1000 => 1.000
export const formatNumber = (num) => {
  if (!num) return 0;
  const THREE_DIGITS_REGEX = /(\d)(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(THREE_DIGITS_REGEX, '$1.');
};

export const formatPhoneNumber = (phoneNumber = '') => {
  const firstThree = phoneNumber.substring(0, 4);
  const secondThree = phoneNumber.substring(4, 7);
  const lastThree = phoneNumber.substring(7, 11);
  return `${firstThree} ${secondThree} ${lastThree}`;
};
