import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, TextField, Typography, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { ArrowBackRounded } from '@mui/icons-material';
import { handleUploadImage, replaceImageByS3Link } from '@src/services/upload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faFloppyDisk,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import {
  IMAGE_TYPE_FILE,
  IMAGE_ACCEPT_FILE,
  MAX_FILE_SIZE_MEGABYTE,
  MAX_LENGTH_ARTICLE,
  ARTICLE_STATUS,
} from '@src/constants';
import { useSearchParams } from '@src/hooks';
import { COLOR } from '@src/styles/color';
import FileDropzone from '@src/components/FileDropzone';
import apis from '@src/apis';
import RickTextEditor from '../../components/RickTextEditor';
import AddAppPopup from './AddAppPopup';
import PreviewPopup from './PreviewPopup';
import { StyledArticle } from './index.style';

const singleAppContent = (app = {}) => `
  <table style="border-collapse:collapse;width: 100%;">
    <tbody>
        <tr>
            <td style="width: 100%; border-color: transparent;">
              <div style="display: flex; align-items: center; padding: 12px; padding-left: 24px; padding-right: 24px; border-radius: 24px; background-color: rgba(0, 67, 144, 0.08); cursor: pointer; position: relative;">
              <div style="margin-right: 20px; display: flex; align-items: center;">
                <img src="${
                  app.latestPublishedAppVersion?.appImageUrl
                }" alt="Your Image" width="80" height="80" style="width: 80px; height: 80px; border-radius: 8px;">
              </div>
              <div>
                <div style="font-weight: 510; font-size: 16px !important;">${
                  app.name || '--'
                }</div>
                <div style="font-size: 14px !important; color: rgba(60, 60, 67, 0.80);">${
                  app.subTitle || app.primaryCategory?.name || '--'
                }</div>
              </div>
              <div style="font-size: 12px !important; background-color: transparent; border: 1px solid #ccc; padding: 10px; border-radius: 50px; margin-left: auto; color: rgba(60, 60, 67, 0.64);">SẮP RA MẮT</div>
              <a style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; cursor: pointer;" target="_blank" href="https://dev-aihub.vbee.ai/apps/${
                app.id
              }"></a>
            </div>            
            </td>
        </tr>
    </tbody>
  </table>
`;

const WEBSITE_IMAGE_SIZE = {
  minWidth: 960,
  minHeight: 540,
};

const Article = () => {
  const [content, setContent] = useState('');
  const [header, setHeader] = useState('');
  const [sapo, setSapo] = useState('');
  const [error, setError] = useState({});
  const [images, setImage] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { params, replaceNewParams } = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { articleId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const handleBack = () => history.goBack();
  const [appPopup, setAppPopup] = useState(false);
  const [previewPopup, setPreviewPopup] = useState({
    display: false,
  });

  const { status } = params;

  const { minWidth, minHeight } = WEBSITE_IMAGE_SIZE;

  const maxSize = MAX_FILE_SIZE_MEGABYTE.THREE_MB;

  const isValid = () => {
    const errorData = {};
    let goToError;

    if (!header) {
      errorData.header = t('headerRequired');
      goToError = document.getElementById('box-header');
    }

    if (!sapo) {
      errorData.sapo = t('sapoRequired');
      if (!goToError) goToError = document.getElementById('box-sapo');
    }

    if (!images) {
      errorData.images = t('imagesArticleRequired');
      if (!goToError) goToError = document.getElementById('box-images');
    }
    if (!content) {
      errorData.content = t('contentRequired');
      if (!goToError) goToError = document.getElementById('box-content');
    }
    if (Object.keys(errorData).length) {
      setError(errorData);
      if (goToError)
        goToError.scrollIntoView({ behavior: 'smooth', block: 'start' });

      return false;
    }

    return true;
  };

  const handleChangeContent = async (c) => {
    const newContent = await replaceImageByS3Link(c);
    setError({ ...error, content: '' });
    setContent(newContent);
  };

  const handleAddSingleApp = (app) => {
    const newContent = content?.replace(
      `<span style="display: none">placeholder</span>`,
      singleAppContent(app),
    );
    setContent(newContent);
  };

  const handleAddMultipleApp = () => {
    // TODO add multiple apps
  };

  const handleAddApp = (apps) => {
    if (apps.length === 1) {
      handleAddSingleApp(apps[0]);
    } else {
      handleAddMultipleApp(apps);
    }
  };

  const handleChangeHeader = (e) => {
    setError({ ...error, header: '' });
    if (e.target.value.length > MAX_LENGTH_ARTICLE.HEADER) {
      setHeader(e.target.value.slice(0, MAX_LENGTH_ARTICLE.HEADER));
      return;
    }
    setHeader(e.target.value);
  };

  const handleChangeSapo = (e) => {
    setError({ ...error, sapo: '' });

    if (e.target.value.length > MAX_LENGTH_ARTICLE.SAPO) {
      setSapo(e.target.value.slice(0, MAX_LENGTH_ARTICLE.SAPO));
      return;
    }
    setSapo(e.target.value);
  };

  const handleChangeImage = (value) => {
    setError({ ...error, images: '' });
    setImage(value);
  };

  const customButtons = [
    'link',
    {
      name: 'Thêm ứng dụng',
      exec: async (editor) => {
        const { selection } = editor;
        await selection.insertHTML(
          `<span style="display: none">placeholder</span>`,
        );

        setAppPopup(true);
      },
    },
  ];
  const handleCloseAppPopup = (apps) => {
    if (!apps.length)
      setContent(
        content.replace(`<span style="display: none">placeholder</span>`, ``),
      );
    setAppPopup(false);
  };

  const fetchArticle = async () => {
    if (!articleId) return;
    try {
      const res = await apis.article.getArticle(articleId);
      if (!res?.status) {
        throw new Error();
      }
      const {
        content: contentApi,
        title,
        sapo: sapoApi,
        images: imagesApi = {},
      } = res?.result || {};
      setContent(contentApi);
      setHeader(title);
      setSapo(sapoApi);
      setImage(imagesApi?.default);
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };
  const createNewArticle = async () => {
    if (!isValid()) return;
    try {
      setIsSubmitting(true);
      const res = await apis.article.createArticle(articleId, {
        content,
        title: header,
        sapo,
        images: {
          default: await handleUploadImage(images, enqueueSnackbar, t),
        },
      });

      if (res?.status) {
        enqueueSnackbar('Phát hành bài viết thành công', {
          variant: 'success',
        });
        fetchArticle();
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateArticle = async () => {
    if (!isValid()) return;
    try {
      setIsSubmitting(true);
      const res = await apis.article.updateArticle(articleId, {
        content,
        title: header,
        sapo,
        images: {
          default: await handleUploadImage(images, enqueueSnackbar, t),
        },
      });

      if (res?.status) {
        enqueueSnackbar('Phát hành bài viết thành công', {
          variant: 'success',
        });
        fetchArticle();
        replaceNewParams({ ...params, status: ARTICLE_STATUS.PUBLISHED });
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };
  const checkArticleId = async () => {
    if (!articleId) {
      createNewArticle();
    }
    updateArticle();
  };
  const saveDraftArticle = async () => {
    if (!header) {
      enqueueSnackbar('Tiêu đề không được để trống', { variant: 'error' });
      return;
    }
    try {
      setIsSubmitting(true);
      const res = await apis.article.saveDraftArticle(articleId, {
        content,
        title: header,
        sapo,
        images: {
          default: await handleUploadImage(images, enqueueSnackbar, t),
        },
      });

      if (res?.status) {
        enqueueSnackbar('Lưu nháp bài viết thành công', { variant: 'success' });
        fetchArticle();

        replaceNewParams({ ...params, status: ARTICLE_STATUS.DRAFT });
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    fetchArticle();
  }, []);
  const Title = () => {
    if (!articleId) {
      return <Typography variant="h3">Tạo bài viết mới</Typography>;
    }
    return <Typography variant="h3">Chi tiết bài viết</Typography>;
  };

  return (
    <StyledArticle>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box display="flex" flexWrap="nowrap" alignItems="center" gap={1}>
          <ArrowBackRounded
            cursor="pointer"
            size="small"
            color="primary"
            onClick={handleBack}
          />
          <Title />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <Typography color="initial">Trạng thái:</Typography>
          <Box
            borderRadius="100px"
            backgroundColor={COLOR.black[4]}
            width="128px"
            padding="4px 12px"
            gap={4}
          >
            <Typography textAlign="center">{t(status)}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className="box-content" id="box-header">
        <Box>
          <Typography variant="h6" className="label-input">
            Tiêu đề <span>*</span>
          </Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="Nhập tiêu đề bài viết"
            value={header}
            error={!!error.header}
            helperText={error.header}
            onChange={handleChangeHeader}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {header.length}/{MAX_LENGTH_ARTICLE.HEADER}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box className="box-content" id="box-sapo">
        <Box>
          <Typography className="label-input" variant="h6">
            Giới thiệu <span>*</span>
          </Typography>
          <Box className={`text-area ${!!error.sapo && 'error'}`}>
            <TextField
              size="small"
              variant="standard"
              multiline
              rows={5}
              value={sapo}
              onChange={handleChangeSapo}
              fullWidth
              placeholder={t('inputDescription')}
            />
            <Typography textAlign="end" color="secondary" variant="subtitle2">
              {sapo.length}/{MAX_LENGTH_ARTICLE.SAPO}
            </Typography>
          </Box>
          {!!error.sapo && (
            <Typography color="error" variant="caption1" className="error-text">
              {error.sapo}
            </Typography>
          )}
        </Box>
      </Box>
      <Box className="box-content" id="box-images">
        <Box>
          <Typography variant="h6" className="label-input">
            Ảnh nổi bật <span>*</span>
          </Typography>
          <FileDropzone
            fileUrl={images}
            boxSizing="border-box"
            padding={2}
            width="100%"
            fileType={IMAGE_TYPE_FILE}
            acceptFile={IMAGE_ACCEPT_FILE}
            onAddFile={handleChangeImage}
            onDeleteFile={handleChangeImage}
            maxSize={maxSize * 1024 * 1024}
            minWidth={minWidth}
            minHeight={minHeight}
            isError={!!error.images}
            noteContent={
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                flexDirection="column"
              >
                <Typography variant="body3" color={COLOR.black[100]}>
                  Ảnh nền cỡ lớn
                </Typography>
                <Typography variant="caption" width="600px">
                  {t('imageArticleDesc', {
                    types: IMAGE_TYPE_FILE.slice(0, 5)
                      .map((type) => type.toUpperCase())
                      .join(', ')
                      .replace(/,([^,]*)$/, ' hoặc$1'),
                    maxSize,
                    minHeight,
                    minWidth,
                  })}
                </Typography>
              </Box>
            }
          />
          {!!error.images && (
            <Typography color="error" variant="caption1" className="error-text">
              {error.images}
            </Typography>
          )}
        </Box>
      </Box>

      <Box className="box-content" id="box-detail-content">
        <Box>
          <Typography className="label-input" variant="h6">
            Nội dung <span>*</span>
          </Typography>
          <RickTextEditor
            content={content}
            onChangeContent={handleChangeContent}
            customButtons={customButtons}
            height="calc(100vh - 240px)"
            placeholder=" "
          />
        </Box>
        {!!error.content && (
          <Typography color="error" variant="caption1" className="error-text">
            {error.content}
          </Typography>
        )}
      </Box>

      <AddAppPopup
        open={appPopup}
        onClose={handleCloseAppPopup}
        onAddApp={handleAddApp}
      />

      <PreviewPopup
        open={previewPopup.display}
        onClose={() => setPreviewPopup((prev) => ({ ...prev, display: false }))}
        content={content}
        header={header}
      />

      <Box
        display="flex"
        gap={1}
        justifyContent="flex-end"
        sx={{ marginY: 2, marginX: 0 }}
        padding="16px"
        variant="h6b"
      >
        <LoadingButton
          variant="outlined"
          sx={{
            minWidth: '150px',
            padding: '8px 16px',
            justifyContent: 'space-evenly',
          }}
          loading={isSubmitting}
          onClick={() => setPreviewPopup({ display: true })}
        >
          <FontAwesomeIcon icon={faEye} />
          <Typography variant="h6b">{t('Xem trước')}</Typography>
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          sx={{
            minWidth: '150px',
            padding: '8px 16px',
            justifyContent: 'space-evenly',
          }}
          loading={isSubmitting}
          onClick={() => checkArticleId()}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
          <Typography variant="h6b" marginLeft="8px">
            {t('Lưu & Phát hành')}
          </Typography>
        </LoadingButton>
        <LoadingButton
          variant="contained"
          sx={{
            minWidth: '150px',
            padding: '8px 16px',
            justifyContent: 'space-evenly',
          }}
          loading={isSubmitting}
          onClick={() => saveDraftArticle()}
        >
          <FontAwesomeIcon icon={faFloppyDisk} />
          <Typography variant="h6b"> {t('Lưu nháp')}</Typography>
        </LoadingButton>
      </Box>
    </StyledArticle>
  );
};

export default Article;
