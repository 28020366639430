import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Divider,
  Typography,
  Popover,
  MenuList,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { COLOR } from '@src/styles/color';
import { formatPhoneNumber } from '@src/utils/formatNumber';
import { ReactComponent as ArrowBack } from '@src/assets/icons/arrow-left.svg';
import { ReactComponent as ArrowForward } from '@src/assets/icons/arrow-right.svg';
import ROUTES from '@src/constants/route';
import { handleReceivingTokens } from '@src/services/auth';
import actions from '@src/redux/actions';
import { StyledBottom, StyledMenuItem } from './index.style';

const stylesText = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 100,
};

const stylesFlex = { display: 'flex', alignItems: 'center' };

const Bottom = ({ isCollapse, setIsCollapse }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.user);

  const { name = '', username } = userInfo;

  const handleClickOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleReceivingTokens({ token: '', refreshToken: '' });
    dispatch(actions.user.updateUser({}));
    setAnchorEl(null);
    history.push(ROUTES.LOGIN);
  };

  return (
    <StyledBottom>
      <Box position="absolute" bottom={0} left={0} width="100%">
        {!isCollapse && <Divider />}
        <Box
          padding={2}
          gap={1.5}
          {...stylesFlex}
          flexDirection={isCollapse ? 'column' : 'row'}
        >
          <Avatar onClick={handleClickOpenPopover} className="avatar">
            {name.slice(0, 1).toUpperCase()}
          </Avatar>
          {!isCollapse && (
            <Box>
              <Typography
                variant="subtitle1"
                color={COLOR.darkestBlue[80]}
                {...stylesText}
              >
                {name || username}
              </Typography>
              {userInfo?.phoneNumber && (
                <Typography
                  variant="caption"
                  color={COLOR.blueGray[64]}
                  {...stylesText}
                >
                  {formatPhoneNumber(userInfo?.phoneNumber)}
                </Typography>
              )}
            </Box>
          )}
          <Box
            marginLeft="auto"
            width={40}
            height={40}
            bgcolor={COLOR.darkestBlue[8]}
            borderRadius="50%"
            {...stylesFlex}
            justifyContent="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsCollapse(!isCollapse)}
          >
            {isCollapse ? <ArrowForward /> : <ArrowBack />}
          </Box>
        </Box>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <MenuList>
            <StyledMenuItem onClick={handleLogout}>
              <LogoutIcon />
              <Typography variant="body2">{t('logout')}</Typography>
            </StyledMenuItem>
          </MenuList>
        </Popover>
      </Box>
    </StyledBottom>
  );
};

export default Bottom;
