import React, { useState } from 'react';

import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  DATE_TIME_PICKER_TYPES,
  START_TIME_DEFAULT,
  REPORT_END_TIME_DEFAULT,
} from '@src/constants';
import useSearchParams from '@src/hooks/useSearchParams';
import apis from '@src/apis';
import { downloadExcel } from '@src/services/download';
import { CustomDatePickerRange } from '@src/components/CustomDatePickerRange';
import OrganizationSearch from '@src/components/OrganizationSearch';
import ReportTable from './ReportTable';
import ReportApp from '../ReportApp';

const Report = ({ tableMaxHeight }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { params, addParams } = useSearchParams();
  const {
    appName = '',
    startTime = START_TIME_DEFAULT,
    endTime = REPORT_END_TIME_DEFAULT,
    appId,
    organizationId,
  } = params;

  if (appId) return <ReportApp />;

  const handleExportReport = async () => {
    try {
      setDownloadLoading(true);
      const res = await apis.report.exportReport({
        appName,
        startTime,
        endTime,
        organizationId,
      });
      if (res?.data)
        downloadExcel(
          res.data,
          `Baocao_${moment(startTime).format('DD-MM-YYYY')}_${moment(
            endTime,
          ).format('DD-MM-YYYY')}`,
        );
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h3" sx={{ whiteSpace: 'nowrap' }}>
          Báo cáo
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <TextField
            size="small"
            variant="outlined"
            className="input-search"
            placeholder="Nhập tên ứng dụng"
            value={appName}
            onChange={(e) => addParams({ appName: e.target.value, page: 1 })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ width: '270px' }}>
            <OrganizationSearch />
          </Box>
          <Box maxWidth={340}>
            <CustomDatePickerRange
              startDate={startTime}
              endDate={endTime}
              type={DATE_TIME_PICKER_TYPES.DATE}
              handleChangeStartDate={(value) =>
                addParams({ startTime: value, page: 1 })
              }
              handleChangeEndDate={(value) =>
                addParams({ endTime: value, page: 1 })
              }
              isRefresh={false}
              hasFromText={false}
              maxDate={REPORT_END_TIME_DEFAULT}
              disableHighlightToday
            />
          </Box>
          <LoadingButton
            variant="contained"
            sx={{ whiteSpace: 'nowrap' }}
            onClick={handleExportReport}
            loading={downloadLoading}
          >
            Xuất báo cáo
          </LoadingButton>
        </Box>
      </Box>
      <ReportTable maxHeight={tableMaxHeight} />
    </>
  );
};

export default Report;
