import { Box } from '@mui/material';
import Sidebar from '@src/components/Sidebar';
import React from 'react';
import { useSelector } from 'react-redux';
import Unauthorized from '../Unauthorized';

const Layout = ({ children }) => {
  const { userInfo } = useSelector((state) => state.user) || {};
  const { active } = userInfo?.systemUser || {};

  return (
    <Box sx={{ display: 'flex', width: 'calc(100% - 4px)' }}>
      <Sidebar />
      <Box padding={4} paddingBottom={0} marginX="auto" width="100%">
        {active ? children : <Unauthorized />}
      </Box>
    </Box>
  );
};

export default Layout;
