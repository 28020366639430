import { SERVICE } from '@src/constants';
import api from './api';

export const createSpecialGroup = async (data) => {
  const res = await api({
    method: 'POST',
    url: '/admin/special-groups',
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getSpecialGroups = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/admin/special-groups',
    source: SERVICE.API_MANAGEMENT,
    params,
  });
  return res;
};

export const getSpecialGroup = async (groupId) => {
  const res = await api({
    method: 'GET',
    url: `/admin/special-groups/${groupId}`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const updateSpecialGroups = async (params, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/special-groups`,
    data,
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const updateSpecialGroup = async (groupId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/special-groups/${groupId}`,
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
