import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { ROLE_TYPE } from '@src/constants/role';
import { BORDER } from '@src/styles/config';
import camelcase from 'camelcase';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddCircle, Edit } from '@mui/icons-material';
import { COLOR } from '@src/styles/color';
import DrawerCreateRole from './DrawerCreateRole';

const RoleTabItem = ({ item, roleSelected, handleSelectRole, setRoles }) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);

  const handleCloseEditDrawer = () => {
    setOpenEditDrawer(false);
    setIsHover(false);
  };

  const isSelected = roleSelected.id === item.id;

  const renderUnit = () =>
    item?.isMasterRole ? (
      <Typography>{t('allPermission')}</Typography>
    ) : (
      <Typography>
        {(item.permissions && item.permissions.length) || 0}
        {t('permissionUnit')}
      </Typography>
    );

  return (
    <>
      <Box
        key={item.id}
        onClick={() => handleSelectRole(item)}
        px={2}
        py={1}
        mt={1}
        display="flex"
        justifyContent="space-between"
        sx={{
          cursor: 'pointer',
          '&:hover': !isSelected && {
            backgroundColor: COLOR.ink[4],
          },
        }}
        borderLeft={isSelected && `3px solid ${COLOR.primary.base}`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        bgcolor={isSelected ? COLOR.standardBlue[8] : 'inherit'}
      >
        <Typography>{item.name || t('other')}</Typography>
        {isHover ? (
          <Edit onClick={() => setOpenEditDrawer(true)} />
        ) : (
          renderUnit()
        )}
      </Box>
      <DrawerCreateRole
        open={openEditDrawer}
        handleClose={handleCloseEditDrawer}
        item={item}
        setRoles={setRoles}
      />
    </>
  );
};

const ListRole = ({
  tab,
  handleChangeTab,
  roles,
  roleSelected,
  handleSelectRole,
  setRoles,
  setPermissionsChange,
}) => {
  const { t } = useTranslation();
  const [openCreateRole, setOpenCreateRole] = useState(false);

  return (
    <Grid item xs={3}>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        sx={{ borderBottom: BORDER }}
      >
        {Object.values(ROLE_TYPE).map((value) => (
          <Tab
            value={value}
            label={t(camelcase(value))}
            key={value}
            sx={{ width: '50%' }}
          />
        ))}
      </Tabs>
      {roles.map((item) => (
        <RoleTabItem
          key={item.id}
          item={item}
          roleSelected={roleSelected}
          handleSelectRole={handleSelectRole}
          setRoles={setRoles}
        />
      ))}
      <Box mx={3} my={2.5}>
        <Button
          variant="contained"
          fullWidth
          startIcon={<AddCircle />}
          onClick={() => setOpenCreateRole(true)}
        >
          {t('addRole')}
        </Button>
      </Box>
      <DrawerCreateRole
        open={openCreateRole}
        handleClose={() => setOpenCreateRole(false)}
        setRoles={setRoles}
        tab={tab}
        setPermissionsChange={setPermissionsChange}
      />
    </Grid>
  );
};

export default ListRole;
