import { SERVICE } from '@src/constants';
import api from './api';

export const getTransactions = async (params) => {
  const res = await api({
    method: 'GET',
    url: `/admin/transactions`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
