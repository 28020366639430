import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Chip } from '@mui/material';
import CustomTable from '@src/components/CustomTable';
import Popup from '@src/components/Popup';
import { ALL, INIT_PAGINATION, DATE_TIME_FORMAT } from '@src/constants';
import { COLOR } from '@src/styles/color';
import apis from '@src/apis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@src/components/Tooltip';
import CreateCspPopup from './CreateCspPopup';
import FilterCSP from './FilterCSP';

const StatusComponent = ({
  id,
  active,
  name,
  handleCallApiUpdate,
  activeTextContent,
  inactiveTextContent,
  inactiveNotificationSuccess,
  activeNotificationSuccess,
  fetchData,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState(active);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  const handleChangeStatus = async (e) => {
    e.stopPropagation();
    try {
      const res = await handleCallApiUpdate(id, { active: !status });
      if (res?.status === 1) {
        setStatus(!status);
        fetchData();
      }
      if (inactiveNotificationSuccess) {
        const message = status
          ? inactiveNotificationSuccess
          : activeNotificationSuccess;

        enqueueSnackbar(t(message, { name }), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleOpenConfirmPopup = (e) => {
    e.stopPropagation();
    setOpenConfirmPopup(true);
  };

  const handleCloseConfirmPopup = (e) => {
    e.stopPropagation();
    setOpenConfirmPopup(false);
  };

  useEffect(() => {
    setStatus(active);
  }, [active]);

  return (
    <>
      <Tooltip title={t(status ? 'lock' : 'unlock')} arrow placement="top">
        <FontAwesomeIcon
          icon={status ? faLock : faLockOpen}
          color={status ? COLOR.error.base : COLOR.darkestBlue[64]}
          size="lg"
          onClick={handleOpenConfirmPopup}
        />
      </Tooltip>
      <Popup
        open={openConfirmPopup}
        onClose={handleCloseConfirmPopup}
        title={t('confirm')}
        content={t(status ? inactiveTextContent : activeTextContent, { name })}
        okMessage={t('confirm')}
        cancelMessage={t('cancel')}
        onOk={handleChangeStatus}
        titleColor={COLOR.warning.base}
      />
    </>
  );
};

const ListCSP = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [openDetail, setOpenDetail] = useState(false);
  const [detail, setDetail] = useState({});
  const [filter, setFilter] = useState({ inputText: '', active: ALL });
  const [data, setData] = useState({
    listCsp: [],
    pagination: INIT_PAGINATION,
  });
  const [loading, setLoading] = useState(false);

  const heads = [
    {
      label: t('index'),
      valueName: 'index',
      align: 'center',
    },
    {
      label: t('nameOrganization'),
      valueName: 'name',
      align: 'left',
    },
    {
      label: t('ownerEmailOrPhoneNumber'),
      valueName: 'emailPhone',
      align: 'left',
    },
    {
      label: t('numApps'),
      valueName: 'totalApp',
      align: 'center',
    },
    {
      label: t('createdAt'),
      valueName: 'createdAt',
      align: 'center',
    },
    {
      label: t('status'),
      valueName: 'status',
      align: 'center',
    },
    {
      label: t('actions'),
      valueName: 'action',
      align: 'center',
    },
  ];

  const fetchData = async (page) => {
    try {
      setLoading(true);
      const params = {
        inputText: filter.inputText,
        active: filter.active === ALL ? null : filter.active,
        pageNum: page || data.pagination.page,
      };
      const res = await apis.csp.getOrganizations(params);
      if (res)
        setData((prevData) => ({
          ...prevData,
          listCsp: res?.result?.data || [],
          pagination: {
            page: res?.result?.pager?.currentPageNum || 1,
            limit: res?.result?.pager?.limit || 10,
            total: res?.result?.pager?.totalCount || 0,
            totalPages: res?.result?.pager?.lastPageNum || 1,
          },
        }));
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const formatData = data?.listCsp.map((el) => ({
    ...el,
    status: (
      <Chip
        label={el.active ? t('active') : t('deactive')}
        color={el.active ? 'success' : 'error'}
      />
    ),
    emailPhone: el.owner?.email || el.owner?.phoneNumber,
    createdAt: moment(el.createdAt).format(DATE_TIME_FORMAT),
    action: (
      <StatusComponent
        active={el.active}
        id={el.id}
        name={el.name}
        handleCallApiUpdate={apis.csp.updateOrganizationStatus}
        activeTextContent="activeCsp"
        inactiveTextContent="inactiveCsp"
        inactiveNotificationSuccess="inactiveCspSuccess"
        activeNotificationSuccess="activeCspSuccess"
        fetchData={fetchData}
      />
    ),
  }));

  const handleClickRow = (item) => {
    setDetail(item);
    setOpenDetail(true);
  };

  const handleChangePage = (page) => {
    fetchData(page);
  };

  useEffect(() => {
    fetchData(1);
  }, [filter]);

  return (
    <>
      <FilterCSP filter={filter} setFilter={setFilter} fetchData={fetchData} />
      <CustomTable
        items={formatData}
        heads={heads}
        pagination={data.pagination}
        onChangePagination={handleChangePage}
        onClickRow={handleClickRow}
        loading={loading}
        isStickyHeader
        maxHeight="calc(100vh - 200px)"
      />
      {openDetail && (
        <CreateCspPopup
          open={openDetail}
          onClose={() => setOpenDetail(false)}
          item={detail}
          fetchData={fetchData}
        />
      )}
    </>
  );
};

export default ListCSP;
