const moment = require('moment');

const formatDateExport = (date) => {
  if (moment(date, 'YYYY-MM-DD', true).isValid()) {
    return moment(date).format(`DD/MM/YYYY`);
  }
  if (moment(date, 'YYYY-[w]WW', true).isValid()) {
    return `Tuần bắt đầu từ thứ 2, ${moment(date, 'YYYY-[w]WW')
      .startOf('isoWeek')
      .format('DD/MM')}`;
  }
  if (moment(date, 'YYYY-MM', true).isValid()) {
    return `Tháng ${moment(date).format(`MM / YYYY`)}`;
  }

  return date;
};

const formatDate = (date) => {
  if (moment(date, 'YYYY-MM-DD', true).isValid()) {
    return moment(date).format(`DD/MM`);
  }
  if (moment(date, 'YYYY-[w]WW', true).isValid()) {
    return moment(date, 'YYYY-[w]WW').startOf('isoWeek').format('DD/MM');
  }
  if (moment(date, 'YYYY-MM', true).isValid()) {
    return moment(date).format(`MM/YYYY`);
  }

  return date;
};

export { formatDateExport, formatDate };
