import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import apis from '@src/apis';
import ROUTES from '@src/constants/route';
import { StyleEndUserDetail } from './index.style';
import ListApp from './ListApp';
import AppDetail from './AppDetail';

const EndUserDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState({});
  const [app, setApp] = useState({});

  const fetchUser = async () => {
    try {
      const res = await apis.user.getUser(userId);
      if (res?.status) setUser(res?.result);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleBack = () => history.push(ROUTES.USER);

  return (
    <StyleEndUserDetail>
      <Box display="flex" alignItems="center" marginBottom={2}>
        <IconButton onClick={handleBack}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h3" mr="auto">
          {t('userInfo')}
        </Typography>
      </Box>
      <Box className="user-info">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography>{t('email')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{user.email || '-'}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{t('phoneNumber')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{user.phoneNumber || '-'}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{t('name')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{user.name || '-'}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{t('status')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Chip
              label={user.active ? t('active') : t('deactive')}
              color={user.active ? 'success' : 'error'}
            />
          </Grid>
        </Grid>
      </Box>
      {Object.keys(app).length ? (
        <AppDetail userId={userId} app={app} setApp={setApp} />
      ) : (
        <ListApp userId={userId} setApp={setApp} />
      )}
    </StyleEndUserDetail>
  );
};

export default EndUserDetail;
