import { SERVICE } from '@src/constants';
import api from './api';

export const getPages = async (params) => {
  const res = await api({
    method: 'GET',
    url: '/admin/pages',
    source: SERVICE.API_MANAGEMENT,
    params,
  });
  return res;
};

export const updatePage = async (pageId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/admin/pages/${pageId}`,
    data,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
