import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)`
  ${(props) => props.width && `width: ${props.width} !important;`}

  & .MuiOutlinedInput-root {
    padding-right: 0px;
  }

  .icon-input {
    cursor: pointer;
    margin-right: 16px;
  }
`;
