import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, ReplayRounded, Search } from '@mui/icons-material';
import debounce from '@src/utils/debounce';
import { ALL } from '@src/constants';
import { StyleFilter } from './index.style';
import CreateCspPopup from './CreateCspPopup';

const ButtonCreateCSP = ({ fetchData, owners, setOwners, setFilter }) => {
  const { t } = useTranslation();
  const [showPopupCreateAdmin, setShowPopupCreateAdmin] = useState(false);

  return (
    <Box marginLeft="auto">
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setShowPopupCreateAdmin(true)}
      >
        {t('add')}
      </Button>
      <CreateCspPopup
        open={showPopupCreateAdmin}
        onClose={() => setShowPopupCreateAdmin(false)}
        fetchData={fetchData}
        owners={owners}
        setOwners={setOwners}
        setFilter={setFilter}
      />
    </Box>
  );
};

const FilterCSP = ({ filter, setFilter, fetchData, owners, setOwners }) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState(filter.inputText);

  const handleRefresh = () => {
    setFilter({ inputText: '', active: ALL });
    setInputText('');
  };

  const handleChangeInputText = (e) => {
    setInputText(e.target.value);
    debounce(() => setFilter({ ...filter, inputText: e.target.value }))();
  };

  useEffect(() => {
    setInputText(filter.inputText);
  }, [filter.inputText]);

  return (
    <StyleFilter>
      <ButtonCreateCSP
        fetchData={fetchData}
        owners={owners}
        setOwners={setOwners}
        setFilter={setFilter}
      />
      <TextField
        select
        label={filter.active === null ? t('status') : ''}
        variant="outlined"
        size="small"
        className="select"
        value={filter.active}
        onChange={(e) => setFilter({ ...filter, active: e.target.value })}
        InputLabelProps={{ shrink: false }}
      >
        <MenuItem value={ALL}>{t('all')}</MenuItem>
        <MenuItem value>{t('active')}</MenuItem>
        <MenuItem value={false}>{t('deactive')}</MenuItem>
      </TextField>
      <TextField
        size="small"
        variant="outlined"
        className="input-search"
        placeholder={t('inputOrganizationName')}
        value={inputText}
        onChange={handleChangeInputText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="primary" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <IconButton className="reload-button" onClick={handleRefresh}>
        <ReplayRounded color="primary" />
      </IconButton>
    </StyleFilter>
  );
};

export default FilterCSP;
