import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Autocomplete,
  Box,
  Button,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import apis from '@src/apis';
import { regexNumber } from '@src/constants/regex';
import { MenuRounded, Search } from '@mui/icons-material';
import { StyledDialog } from './index.style';

const AddApp = ({
  open,
  onClose,
  onAddApp,
  rankDefault,
  // categoryId = ''
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [appSelected, setAppSelected] = useState({});
  const [apps, setApps] = useState([]);
  const [errors, setErrors] = useState({});
  const [isFirst, setIsFirst] = useState(true);

  const { t } = useTranslation();

  const handleCloseCreatePopup = () => {
    onClose();
    setAppSelected({});
    setErrors({});
  };

  const isValid = () => {
    const errorData = {};

    if (!appSelected?.id) errorData.app = t('appRequired');

    if (!appSelected?.rank) errorData.rank = t('positionRequired');

    if (
      !regexNumber.test(appSelected?.rank) ||
      Number(appSelected?.rank) === 0 ||
      Number(appSelected?.rank) > rankDefault
    )
      errorData.rank = t('positionInvalid');

    if (Object.keys(errorData).length) {
      setErrors(errorData);
      return false;
    }
    return true;
  };

  const handleAdd = async () => {
    if (!isValid()) return;
    await onAddApp(appSelected);
    handleCloseCreatePopup();
  };

  const handleChangeApp = (value) => {
    setErrors({ ...errors, app: '' });
    setAppSelected((prevData) => ({
      ...value,
      rank: prevData?.rank || rankDefault,
    }));
  };

  const handleChangeRank = (e) => {
    const { value } = e.target;
    setErrors({ ...errors, rank: '' });
    setAppSelected((prevData) => ({
      ...prevData,
      rank: value,
    }));
  };

  const fetchApps = async (params = {}) => {
    try {
      // eslint-disable-next-line no-param-reassign
      // if (categoryId) params.categoryId = categoryId;
      const response = await apis.app.getApps({
        ...params,
        available: true,
        limit: 5,
      });
      if (response?.status) {
        setApps(response?.result?.data || []);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleChangeFilterAppsInputText = (newInputValue) => {
    if (isFirst) {
      setIsFirst(false);
      return;
    }
    fetchApps({ inputText: newInputValue });
  };

  setTimeout(async () => {
    // Take the Reference of Close Button
    const close = document.getElementsByClassName(
      'MuiAutocomplete-clearIndicator',
    )[0];

    // Add a Click Event Listener to the button
    if (close)
      close.addEventListener('click', () => {
        fetchApps();
      });
  }, 100);

  useEffect(() => {
    if (open) {
      setAppSelected({ rank: rankDefault });
    }
  }, [open]);

  return (
    <StyledDialog open={open} onClose={handleCloseCreatePopup}>
      <div className="title">{t('addApp')}</div>
      <DialogContent className="content">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className="label-input" variant="h6">
              {t('app')}
              <span>*</span>
            </Typography>
            <Autocomplete
              value={appSelected || ''}
              size="small"
              options={apps}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name || ''}
              onChange={(event, newValue) => {
                handleChangeApp(newValue);
              }}
              onFocus={() => {
                fetchApps();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(event) => {
                    handleChangeFilterAppsInputText(event.target.value);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Search fontSize="small" sx={{ paddingLeft: '5px' }} />
                    ),
                  }}
                  placeholder={t('selectApp')}
                  error={!!errors?.app}
                  helperText={errors?.app}
                />
              )}
              noOptionsText={t('noData')}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className="label-input" variant="h6">
              {t('position')}
              <span>*</span>
            </Typography>
            <TextField
              size="small"
              value={appSelected.rank || ''}
              onChange={handleChangeRank}
              fullWidth
              InputProps={{
                startAdornment: <MenuRounded fontSize="small" />,
              }}
              placeholder={t('inputPosition')}
              error={!!errors?.rank}
              helperText={errors.rank}
            />
          </Grid>
        </Grid>
        <Box className="action">
          <Button
            onClick={handleCloseCreatePopup}
            variant="outlined"
            className="button"
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleAdd}
            autoFocus
            variant="contained"
            className="button"
          >
            {t('add')}
          </Button>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default AddApp;
