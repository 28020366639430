import React from 'react';
import {
  faBuilding,
  faHouse,
  faMicrochip,
  faUserTie,
  faDumpsterFire,
  faLayerGroup,
  faClockRotateLeft,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ROUTES from '@src/constants/route';

export const data = [
  {
    key: 'Home',
    label: 'Trang chủ',
    icon: <FontAwesomeIcon icon={faHouse} size="lg" />,
    path: ROUTES.HOME,
  },
  {
    key: 'App',
    label: 'appManage',
    icon: <FontAwesomeIcon icon={faDumpsterFire} size="lg" />,
    path: ROUTES.APP,
  },
  {
    key: 'Admin',
    label: 'adminManage',
    icon: <FontAwesomeIcon icon={faUserTie} size="lg" />,
    path: ROUTES.ADMIN,
  },
  {
    key: 'CSP',
    label: 'cspManage',
    icon: <FontAwesomeIcon icon={faBuilding} size="lg" />,
    path: ROUTES.CSP,
  },
  {
    key: 'System',
    label: 'systemManage',
    icon: <FontAwesomeIcon icon={faMicrochip} size="lg" />,
    disabled: false,
    subMenu: [
      {
        key: 'RoleManage',
        label: 'roleManage',
        path: ROUTES.ROLE_MANAGE,
      },
      {
        key: 'GroupPermissionManage',
        label: 'groupPermissionManage',
        path: ROUTES.GROUP_PERMISSION_MANAGE,
      },
    ],
  },
  {
    key: 'ContentManage',
    label: 'contentManage',
    icon: <FontAwesomeIcon icon={faLayerGroup} size="lg" />,
    path: ROUTES.CONTENT_MANAGEMENT,
  },
  {
    key: 'HistoryTransaction',
    label: 'historyTransaction',
    icon: <FontAwesomeIcon icon={faClockRotateLeft} size="lg" />,
    path: ROUTES.HISTORY_TRANSACTION,
  },
  {
    key: 'EndUser',
    label: 'endUser',
    icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
    path: ROUTES.USER,
  },
];
