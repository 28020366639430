import { COLOR } from '@src/styles/color';
import React from 'react';
import Chart from 'react-apexcharts';

const DetailStackBarChart = ({
  series,
  yaxisFormatter,
  xaxisFormatter,
  group,
  id,
}) => {
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: { show: false },
      zoom: { enabled: false },
      id,
      group,
    },
    xaxis: {
      axisTicks: { show: false },
      labels: {
        show: true,
        formatter: (value) => (xaxisFormatter ? xaxisFormatter(value) : value),
      },
    },
    yaxis: {
      min: 0,
      labels: {
        show: true,
        formatter: (value) => (yaxisFormatter ? yaxisFormatter(value) : value),
      },
    },
    grid: {
      borderColor: COLOR.black[4],
      xaxis: { lines: { show: false } },
    },
    dataLabels: { enabled: false },
    legend: {
      showForSingleSeries: true,
      onItemClick: { toggleDataSeries: false },
    },
    tooltip: { shared: true, intersect: false },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width="100%"
      height="100%"
    />
  );
};

export default DetailStackBarChart;
