/* eslint-disable no-param-reassign */
export const getByPath = (obj, path) => {
  path = path.replace(/\[/g, '.').replace(/\]/g, '').split('.');
  // eslint-disable-next-line no-restricted-syntax
  for (const level of path) {
    obj = obj[level];
    if (!obj) break;
  }
  return obj || '';
};

export const getChangedFields = (
  oldObj = {},
  newObj = {},
  keysNotCheck = [],
) => {
  const dataChange = {};

  Object.keys(newObj).forEach((key) => {
    if (keysNotCheck.includes(key)) return;
    if (oldObj[key] !== newObj[key]) {
      dataChange[key] = newObj[key];
    }
  });

  return dataChange;
};
