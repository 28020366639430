import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledCustomPagination = styled(Box)`
  margin: 15px 0;

  .pagination {
    .Mui-selected {
      color: ${COLOR.ink[100]};
      background: ${COLOR.blue[16]};
      border: 1px solid ${COLOR.blue[100]};

      &:hover {
        background: ${COLOR.blue[32]};
      }
    }
  }
`;
