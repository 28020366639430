import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { StyledNoData } from './index.style';

const NoData = ({ width = 320, height = 320 }) => {
  const { t } = useTranslation();

  return (
    <StyledNoData>
      <Box className="notify-container">
        <img
          src="/no-data.svg"
          alt="no_data_picture"
          width={width}
          height={height}
        />
        <Typography variant="subtitle2">{t('noData')}</Typography>
      </Box>
    </StyledNoData>
  );
};

export default NoData;
