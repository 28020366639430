import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomTable from '@src/components/CustomTable';
import CustomPermissionTypeTag from '../CustomPermissionTypeTag';

const ListPermission = ({
  permissions = [],
  onUpdatePermission,
  onDeletePermission,
}) => {
  const { t } = useTranslation();

  const permissionHeads = [
    {
      label: t('permissionName'),
      valueName: 'nameDisplay',
      align: 'left',
    },
    {
      label: t('path'),
      valueName: 'uri',
      align: 'left',
    },
    {
      label: t('method'),
      valueName: 'method',
      align: 'left',
    },
    {
      label: t('category'),
      valueName: 'typeDisplay',
      align: 'left',
    },
    {
      label: t('actions'),
      valueName: 'actions',
      align: 'center',
    },
  ];

  const actions = [
    {
      icon: <EditIcon />,
      onClick: (item) => onUpdatePermission(item),
    },
    {
      icon: <DeleteIcon className="delete-icon" />,
      onClick: (item) => onDeletePermission(item),
    },
  ];

  return (
    <CustomTable
      items={permissions.map((el) => ({
        ...el,
        nameDisplay: (
          <Typography className="permission-name">{el.name}</Typography>
        ),
        typeDisplay: <CustomPermissionTypeTag type={el.type} />,
      }))}
      heads={permissionHeads}
      actions={actions}
      disablePagination
    />
  );
};

export default ListPermission;
