import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Button, Grid } from '@mui/material';
import { DEFAULT_CATEGORY_IMAGE } from '@src/constants';

import { StyledCategorySelect } from './index.style';

const CategorySelect = ({
  onSelectCategory = () => {},
  categories = [],
  categoryId,
  disabled,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectCategory = (category) => {
    if (disabled) {
      enqueueSnackbar(t('changeBlockedAction'), { variant: 'warning' });
      return;
    }
    onSelectCategory(category);
  };

  return (
    <StyledCategorySelect>
      <Grid container spacing={2} id="category-selected">
        {categories?.map((category) => (
          <Grid item xs={3} lg={2} key={category.id}>
            <Button
              className={`category ${
                categoryId === category.id ? 'active' : ''
              }`}
              onClick={() => handleSelectCategory(category)}
            >
              <img
                src={category.imageUrl || DEFAULT_CATEGORY_IMAGE}
                height="20px"
                width="20px"
                layout="fill"
                loading="lazy"
                alt="banner"
              />
              <div className="name">{category.name}</div>
            </Button>
          </Grid>
        ))}
      </Grid>
    </StyledCategorySelect>
  );
};

export default CategorySelect;
