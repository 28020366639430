import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledNoData = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .notify-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 16px 0;
    border-bottom: 1px solid ${COLOR.white[16]};

    .notify-detail {
      color: ${COLOR.white[80]};
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
