import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REPORT_GROUP_OPTION } from '@src/constants';
import { COLOR } from '@src/styles/color';
import { Box, Popover, Typography } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const SelectPopover = ({
  value,
  options,
  onSubmit,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box onClick={handleClick}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6b" className="nowrap">
            {t(value || REPORT_GROUP_OPTION.DAY)}
          </Typography>
          <Box sx={{ color: COLOR.primary.dark, pt: 0.6 }}>
            {!open ? <ArrowDropDown /> : <ArrowDropUp />}
          </Box>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        sx={{ '.MuiPopover-paper': { margin: '4px', borderRadius: '16px' } }}
      >
        <Box>
          {options.map((option) => (
            <Box
              key={option}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
                p: 2,
                cursor: 'pointer',
                '&:hover': { backgroundColor: COLOR.blueGray[4] },
                width: '128px',
              }}
              onClick={() => {
                onSubmit(option);
                handleClose();
              }}
            >
              <Typography variant="h6b">{t(option)}</Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default SelectPopover;
