import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import apis from '@src/apis';
import ROUTES from '@src/constants/route';
import { Box, Button, Switch, TextField, Typography } from '@mui/material';
import {
  Add,
  ArrowBackRounded,
  AutorenewRounded,
  Delete,
  EditRounded,
  SaveRounded,
  Search,
} from '@mui/icons-material';
import CustomTable from '@src/components/CustomTable';
import Popup from '@src/components/Popup';
import { compareStringToSearch } from '@src/utils/string';
import { COLOR } from '@src/styles/color';
import { DATE_TIME_FORMAT, ARTICLE_STATUS } from '@src/constants';
import { StyledArticleGroupDetail } from './index.style';
import EditGroup from './EditGroup';

const ArticleGroupDetail = () => {
  const { t } = useTranslation();

  const { articleGroupId } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [articleGroup, setArticleGroup] = useState({});
  const [openEditGroupPopup, setOpenEditGroupPopup] = useState(false);
  const [articles, setArticles] = useState([]);
  const [articlesApi, setArticlesApi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchArticleName, setSearchArticleName] = useState('');
  const [openConfirmUpdatePopup, setOpenConfirmUpdatePopup] = useState(false);
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] = useState(false);
  const [deleteArticle, setDeleteArticle] = useState({});

  const handleChangeSearchArticleName = (e) =>
    setSearchArticleName(e.target.value);

  const handleBack = () => history.push(ROUTES.CONTENT_MANAGEMENT);

  const fetchArticleGroup = async () => {
    try {
      setLoading(true);
      const res = await apis.articleGroup.getArticleGroup(articleGroupId);

      if (!res.status) {
        handleBack();
        throw new Error(res.message);
      }

      const { articles: articlesData = [] } = res.result;

      setArticleGroup(res.result);
      setArticles(JSON.parse(JSON.stringify(articlesData)));
      setArticlesApi(JSON.parse(JSON.stringify(articlesData)));
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenEditGroup = () => {
    setOpenEditGroupPopup(true);
  };

  const handleCloseEditPopup = () => {
    setOpenEditGroupPopup(false);
  };

  const handleUpdateArticleGroup = async (dataUpdate) => {
    try {
      const response = await apis.articleGroup.updateArticleGroup(
        articleGroup.id,
        dataUpdate,
      );

      if (!response?.status) throw new Error();

      const { articles: articlesData = [] } = response.result;

      setArticlesApi(articlesData);

      setArticleGroup(response.result);

      enqueueSnackbar(t('updateArticleGroupSuccess'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  const updateArticles = async (articlesUpdate) => {
    await handleUpdateArticleGroup({ articles: articlesUpdate });
    fetchArticleGroup();
  };

  const confirmUpdateArticles = async () => {
    const articlesUpdate = articles.map((item) => ({
      id: item.id,
      rank: Number(item.rank),
      display: !!item.display,
    }));
    await updateArticles(articlesUpdate);
  };

  const handleRemoveArticle = async () => {
    const articlesUpdate = articles.map((item) => ({
      id: item.id,
      rank: Number(item.rank),
      display: !!item.display,
    }));

    const index = articlesUpdate.findIndex(
      (item) => item.id === deleteArticle.id,
    );
    articlesUpdate.splice(index, 1);

    await updateArticles(articlesUpdate);
    setOpenConfirmDeletePopup(false);
    setDeleteArticle({});
  };

  const handleReorder = (items) => {
    const copyListItems = items.map(({ id }, index) => {
      const item = articles.find((el) => el.id === id);
      return { ...item, rank: index + 1 };
    });
    setArticles(copyListItems);
  };

  const checkArticleInfoValid = (article) => {
    const { title, sapo, images, content } = article;
    const isValid = title && sapo && images?.default && content;

    return isValid;
  };

  const handleChangePublish = (value, index, article) => {
    if (searchArticleName) return;
    if (checkArticleInfoValid(article)) {
      const copyListItems = [...articles];
      copyListItems[index].display = value;
      setArticles(copyListItems);
      return;
    }

    enqueueSnackbar(t('checkPublishArticleFail'), { variant: 'error' });
  };

  useEffect(() => {
    fetchArticleGroup();
  }, []);

  const heads = [
    {
      label: t('position'),
      valueName: 'rank',
      align: 'center',
    },
    {
      label: t('title'),
      valueName: 'title',
      align: 'left',
    },
    {
      label: t('createdAt'),
      valueName: 'createdAt',
      align: 'left',
    },
    {
      label: t('createdBy'),
      valueName: 'createdBy',
      align: 'left',
    },
    {
      label: t('publish'),
      valueName: 'display',
      align: 'left',
    },
    {
      label: t('action'),
      valueName: 'actions',
      align: 'center',
    },
  ];

  const formatData = articles
    .filter((el) => compareStringToSearch(el.title, searchArticleName))
    .map((el, index) => ({
      ...el,
      createdBy: el.createdBy?.name || '--',
      createdAt: moment(el.createdAt).format(DATE_TIME_FORMAT),
      display: (
        <Switch
          checked={el.display || false}
          onChange={(event) =>
            handleChangePublish(event.target.checked, index, el)
          }
        />
      ),
      status: el.display ? ARTICLE_STATUS.PUBLISHED : ARTICLE_STATUS.DRAFT,
    }));

  const handleClickDeleteArticle = (pkg) => {
    setDeleteArticle(pkg);
    setOpenConfirmDeletePopup(true);
  };

  const handleCloseConfirmPopup = () => {
    setDeleteArticle({});
    setOpenConfirmDeletePopup(false);
  };

  const isChangeArticleGroups = () => {
    for (let i = 0; i < articles.length; i += 1) {
      if (articles[i]?.id !== articlesApi[i]?.id) return true;
      if (!!articles[i]?.display !== !!articlesApi[i]?.display) return true;
    }
    return false;
  };

  const actions = [
    {
      icon: (
        <Delete color={`${isChangeArticleGroups() ? 'inherit' : 'error'}`} />
      ),
      onClick: handleClickDeleteArticle,
      disable: isChangeArticleGroups(),
    },
  ];

  const handleResetArticles = () => {
    setArticles(JSON.parse(JSON.stringify(articlesApi)));
  };

  const handleClickUpdate = () => {
    setOpenConfirmUpdatePopup(true);
  };

  const handleCloseUpdate = () => {
    setOpenConfirmUpdatePopup(false);
  };

  const handleClickArticle = (article) => {
    const path = `${ROUTES.ARTICLE.replace(':articleId', article.id)}?status=${
      article.status
    }`;
    history.push(path);
  };
  const handleClickCreateArticle = () => {
    const path = ROUTES.CREATE_ARTICLE.replace(
      ':articleGroupId',
      articleGroup.id,
    );
    history.push(path);
  };

  return (
    <StyledArticleGroupDetail>
      <Box>
        <Box className="title-article-box">
          <Box className="flex-row-8">
            <ArrowBackRounded
              className="pointer"
              size="small"
              color="primary"
              onClick={handleBack}
            />
            <Typography variant="h3" className="title-article">
              {articleGroup.name}
            </Typography>
            <EditRounded
              className="pointer"
              size="small"
              color="primary"
              onClick={handleOpenEditGroup}
            />
          </Box>
          <Box className="flex-row-8">
            <TextField
              fullWidth
              size="small"
              placeholder={t('inputArticleName')}
              InputProps={{
                startAdornment: <Search fontSize="small" />,
              }}
              disabled={isChangeArticleGroups()}
              value={searchArticleName}
              onChange={handleChangeSearchArticleName}
              className="search-input"
            />
            <Button
              variant="contained"
              className="fit-content-button"
              disabled={isChangeArticleGroups()}
              onClick={handleClickCreateArticle}
            >
              <Add />
              {t('createNewArticle')}
            </Button>
          </Box>
        </Box>
        <Typography variant="subtitle2">{articleGroup.description}</Typography>
        <Box className="body">
          <CustomTable
            items={formatData}
            heads={heads}
            disablePagination
            loading={loading}
            reorderable={!searchArticleName}
            onReorder={handleReorder}
            isStickyHeader
            maxHeight="calc(100vh - 250px)"
            actions={actions}
            onClickRow={handleClickArticle}
          />
        </Box>
        <Box className="footer-card">
          <Button
            variant="outlined"
            color="primary"
            className="button"
            onClick={handleResetArticles}
            disabled={!isChangeArticleGroups()}
          >
            <AutorenewRounded fontSize="small" sx={{ rotate: '45deg' }} />
            {t('reset')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="button"
            onClick={handleClickUpdate}
            disabled={!isChangeArticleGroups()}
          >
            <SaveRounded fontSize="small" />
            {t('save')}
          </Button>
        </Box>
      </Box>

      <EditGroup
        name={articleGroup.name}
        description={articleGroup.description}
        onClose={handleCloseEditPopup}
        open={openEditGroupPopup}
        onUpdate={handleUpdateArticleGroup}
      />

      <Popup
        open={openConfirmUpdatePopup}
        onClose={handleCloseUpdate}
        title={t('confirm')}
        content={t('updateConfirm')}
        okMessage={t('yes')}
        cancelMessage={t('no')}
        onOk={confirmUpdateArticles}
      />

      <Popup
        open={openConfirmDeletePopup}
        onClose={handleCloseConfirmPopup}
        title={t('confirmDeleteArticleFromGroup')}
        content={t('removeArticleFromGroupConfirm', {
          name: deleteArticle?.title,
        })}
        okMessage={t('yes')}
        cancelMessage={t('no')}
        onOk={handleRemoveArticle}
        titleColor={COLOR.warning.base}
      />
    </StyledArticleGroupDetail>
  );
};

export default ArticleGroupDetail;
