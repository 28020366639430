const validFile = (fileName, types) => {
  const fileType = fileName.split('.').pop();
  return types.includes(fileType);
};

const validSize = (size, maxSize) => size <= maxSize;

const validAspectRatio = (width, height, aspectRatios) =>
  aspectRatios.includes(width / height);

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};
const validateEmail = (email) => {
  const re =
    /^[^0-9][a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[@][a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,4}$/;
  return re.test(String(email).toLowerCase());
};

export {
  validFile,
  validSize,
  isValidHttpUrl,
  validateEmail,
  validAspectRatio,
};
