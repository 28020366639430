import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Chip,
  Box,
  InputAdornment,
  TextField,
  IconButton,
  MenuItem,
} from '@mui/material';
import CustomTable from '@src/components/CustomTable';
import { EditRounded, ReplayRounded, SearchRounded } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DATE_TIME_FORMAT,
  DATE_TIME_PICKER_TYPES,
  INIT_PAGINATION,
} from '@src/constants';
import { CustomDatePickerRange } from '@src/components/CustomDatePickerRange';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { COLOR } from '@src/styles/color';
import apis from '@src/apis';
import debounce from '@src/utils/debounce';
import Tooltip from '@src/components/Tooltip';
import Popup from '@src/components/Popup';
import ChangePasswordPopup from '@src/components/Popup/ChangePassword';
import { MASTER_ROLE, ROLE_TYPE } from '@src/constants/role';
import { StyleFilter } from './index.style';

const ALL = 'all';
const INIT_FILTER = { inputText: '', startTime: '', endTime: '', active: ALL };

const ListOwner = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState(INIT_FILTER);
  const [data, setData] = useState({
    listOwner: [],
    pagination: INIT_PAGINATION,
  });
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState(filter.inputText);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [ownerSelected, setOwnerSelected] = useState({});

  const handleOpenConfirmPopup = (e, item) => {
    e.stopPropagation();
    setOwnerSelected(item);
    setOpenConfirmPopup(true);
  };

  const handleCloseConfirmPopup = (e) => {
    e.stopPropagation();
    setOpenConfirmPopup(false);
    setOwnerSelected({});
  };

  const handleOpenChangePassword = (e, item) => {
    e.stopPropagation();
    setOwnerSelected(item);
    setOpenChangePassword(true);
  };

  const handleClose = () => {
    setOpenChangePassword(false);
    setOwnerSelected({});
  };

  const heads = [
    {
      label: t('index'),
      valueName: 'index',
      align: 'center',
    },
    {
      label: t('ownerName'),
      valueName: 'name',
      align: 'left',
    },
    {
      label: t('emailOrPhoneNumber'),
      valueName: 'email',
      align: 'left',
    },
    {
      label: t('createdAt'),
      valueName: 'createdAt',
      align: 'center',
    },
    {
      label: t('numCSPs'),
      valueName: 'organizationCount',
      align: 'center',
    },
    {
      label: t('status'),
      valueName: 'status',
      align: 'center',
    },
    {
      label: t('actions'),
      valueName: 'action',
      align: 'center',
    },
  ];

  const formatData = data?.listOwner.map((el) => ({
    ...el,
    id: el?.id,
    email: el?.email || el?.phoneNumber,
    name: el?.name,
    status: (
      <Chip
        label={el?.active ? t('active') : t('deactive')}
        color={el?.active ? 'success' : 'error'}
      />
    ),
    createdAt: moment(el?.createdAt).format(DATE_TIME_FORMAT),
    action:
      el?.role?.key === MASTER_ROLE[ROLE_TYPE.SYSTEM] ? (
        <></>
      ) : (
        <Box display="flex" gap={2} alignItems="center" justifyContent="center">
          <Tooltip
            title={t(el?.active ? 'lock' : 'unlock')}
            arrow
            placement="top"
          >
            <FontAwesomeIcon
              icon={el?.active ? faLock : faLockOpen}
              color={el?.active ? COLOR.error.base : COLOR.darkestBlue[64]}
              size="lg"
              onClick={(event) => handleOpenConfirmPopup(event, el)}
            />
          </Tooltip>
          <Tooltip title={t('changePassword')} arrow placement="top">
            <EditRounded
              sx={{ color: COLOR.darkestBlue[64] }}
              onClick={(event) => handleOpenChangePassword(event, el)}
            />
          </Tooltip>
        </Box>
      ),
  }));

  const handleRefresh = () => {
    setFilter(INIT_FILTER);
    setInputText('');
  };

  const fetchData = async (page) => {
    try {
      setLoading(true);
      const params = {
        inputText: filter?.inputText,
        startTime: filter?.startTime || undefined,
        endTime: filter?.endTime || undefined,
        pageNum: page || data.pagination.page,
        active: filter.active === ALL ? undefined : filter.active,
      };
      const res = await apis.csp.getOwners(params);
      if (res)
        setData((prevData) => ({
          ...prevData,
          listOwner: res?.result?.data || [],
          pagination: {
            page: res?.result?.pager?.currentPageNum || 1,
            limit: res?.result?.pager?.limit || 10,
            total: res?.result?.pager?.totalCount || 0,
            totalPages: res?.result?.pager?.lastPageNum || 1,
          },
        }));
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    fetchData(page);
  };

  const handleChangeInputText = (e) => {
    setInputText(e.target.value);
    debounce(() => setFilter({ ...filter, inputText: e.target.value }))();
  };

  const handleChangeStartDate = (value) =>
    setFilter((prevState) => ({ ...prevState, startTime: value }));

  const handleChangeEndDate = (value) =>
    setFilter((prevState) => ({ ...prevState, endTime: value }));

  const handleChangeStatus = async (e) => {
    e.stopPropagation();
    try {
      const res = await apis.csp.updateOwner(ownerSelected?.id, {
        active: !ownerSelected?.active,
      });
      if (!res?.status) {
        throw new Error();
      }

      setOwnerSelected({});
      fetchData();
      const message = ownerSelected?.active
        ? 'inactiveAccountSuccess'
        : 'activeAccountSuccess';
      enqueueSnackbar(t(message), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleChangePassword = async (dataUpdate) => {
    try {
      const { password } = dataUpdate;
      const res = await apis.csp.updateOwner(ownerSelected?.id, {
        password,
      });
      if (!res?.status) {
        throw new Error();
      }

      setOwnerSelected({});
      enqueueSnackbar(t('changePasswordSuccess'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [filter]);

  return (
    <>
      <StyleFilter>
        <Box className="right-filter">
          <TextField
            select
            label={filter.active === null ? t('status') : ''}
            variant="outlined"
            size="small"
            className="select"
            value={filter.active}
            onChange={(e) => setFilter({ ...filter, active: e.target.value })}
            InputLabelProps={{ shrink: false }}
          >
            <MenuItem value={ALL}>{t('all')}</MenuItem>
            <MenuItem value>{t('active')}</MenuItem>
            <MenuItem value={false}>{t('deactive')}</MenuItem>
          </TextField>
          <TextField
            size="small"
            variant="outlined"
            className="input-search"
            placeholder={t('inputOwnerNameOrEmail')}
            value={inputText}
            onChange={handleChangeInputText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded color="primary" fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          <CustomDatePickerRange
            startDate={filter.startTime}
            endDate={filter.endTime}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            type={DATE_TIME_PICKER_TYPES.DATE}
            isRefresh={false}
            hasFromText={false}
            hasToText={false}
            placeholderFrom={t('startDay')}
            placeholderTo={t('endDay')}
            width="180px"
          />
          <IconButton className="reload-button" onClick={handleRefresh}>
            <ReplayRounded color="primary" />
          </IconButton>
        </Box>
      </StyleFilter>
      <CustomTable
        items={formatData}
        heads={heads}
        pagination={data.pagination}
        onChangePagination={handleChangePage}
        loading={loading}
        isStickyHeader
        maxHeight="calc(100vh - 300px)"
      />
      <Popup
        open={openConfirmPopup}
        onClose={handleCloseConfirmPopup}
        title={t('confirm')}
        content={t(
          ownerSelected?.active ? 'inactiveAccount' : 'activeAccount',
          {
            name: ownerSelected?.name,
          },
        )}
        okMessage={t('confirm')}
        cancelMessage={t('cancel')}
        onOk={handleChangeStatus}
        titleColor={COLOR.warning.base}
      />
      <ChangePasswordPopup
        open={openChangePassword}
        onClose={handleClose}
        onOk={handleChangePassword}
        name={ownerSelected?.name}
        email={ownerSelected?.email || ownerSelected?.phoneNumber}
      />
    </>
  );
};

export default ListOwner;
