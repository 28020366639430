import { Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledDialog = styled(Dialog)`
  width: 100%;
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    padding: 24px 24px 0 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 500px;
  }

  .MuiDialogContent-root {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${COLOR.blueGray[16]};
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${COLOR.blueGray[4]};
      border-radius: 4px;
    }
  }

  .action {
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    .button {
      min-width: 120px;
    }
  }

  .pointer {
    cursor: pointer;
  }
  .text-area {
    padding: 8.5px 14px;
    border-radius: 24px;
    border: 1px solid ${COLOR.border.default};

    :hover {
      border-color: ${COLOR.border.hover};
    }
  }

  .apps-list {
    height: 40vh;

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${COLOR.blueGray[16]};
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${COLOR.blueGray[4]};
      border-radius: 4px;
    }
  }
`;

export const StyledArticle = styled(Box)`
  .text-area {
    padding: 8.5px 14px;
    border-radius: 24px;
    border: 1px solid ${COLOR.border.default};

    :hover {
      border-color: ${COLOR.border.hover};
    }
  }

  .error {
    border-color: ${COLOR.error.base};
    margin-bottom: 2px;

    :hover {
      border-color: ${COLOR.error.base};
    }
  }

  .error-text {
    margin-left: 15px;
  }

  .label-input {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;

    span {
      color: ${COLOR.error.base};
    }
  }
  .box-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 8px;
  }
`;
