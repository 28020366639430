import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { HelpRounded } from '@mui/icons-material';
import FileDropzone from '@src/components/FileDropzone';
import apis from '@src/apis';
import {
  DEVICE_TYPE,
  IMAGE_ACCEPT_FILE,
  IMAGE_TYPE_FILE,
  MAX_FILE_SIZE_MEGABYTE,
} from '@src/constants';
import Tooltip from '@src/components/Tooltip';
import { regexUrl } from '@src/constants/regex';
import { uploadBase64Image } from '@src/services/upload';
import { getChangedFields } from '@src/utils/object';
import { StyledCreateGroupDialog } from './index.style';

const TABS = {
  PHONE: 0,
  WEBSITE: 1,
};

const PHONE_IMAGE_SIZE = {
  minWidth: 320,
  minHeight: 50,
  maxWidth: 768,
  maxHeight: 120,
};

const WEBSITE_IMAGE_SIZE = {
  minWidth: 728,
  minHeight: 90,
  maxWidth: 1280,
  maxHeight: 158,
};

const CreateBanner = ({
  fetchBlocks,
  onChangeSearchBlockName,
  onChangeType,
  onClose,
  open,
  pageKey,
  bannerId,
}) => {
  const INITIAL_BANNER_DATA = {
    name: '',
    images: {
      default: '',
      mobile: '',
    },
    url: '',
  };

  const [bannerData, setBannerData] = useState(INITIAL_BANNER_DATA);
  const [bannerDataApi, setBannerDataApi] = useState(INITIAL_BANNER_DATA);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({});
  const [device, setDevice] = useState(TABS.PHONE);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const minWidth =
    device === TABS.PHONE
      ? PHONE_IMAGE_SIZE.minWidth
      : WEBSITE_IMAGE_SIZE.minWidth;
  const minHeight =
    device === TABS.PHONE
      ? PHONE_IMAGE_SIZE.minHeight
      : WEBSITE_IMAGE_SIZE.minHeight;
  const maxWidth =
    device === TABS.PHONE
      ? PHONE_IMAGE_SIZE.maxWidth
      : WEBSITE_IMAGE_SIZE.maxWidth;
  const maxHeight =
    device === TABS.PHONE
      ? PHONE_IMAGE_SIZE.maxHeight
      : WEBSITE_IMAGE_SIZE.maxHeight;
  const maxSize = MAX_FILE_SIZE_MEGABYTE.THREE_MB;

  const tabs = [
    {
      type: DEVICE_TYPE.PHONE,
      label: t('phone'),
    },
    {
      type: DEVICE_TYPE.WEBSITE,
      label: t('desktop'),
    },
  ];

  const handleCloseCreatePopup = () => {
    onClose();
    setDevice(TABS.PHONE);
    setBannerData(INITIAL_BANNER_DATA);
    setError({});
  };

  const onChangeBannerData = (key, value) => {
    setError({ ...error, [key]: '' });
    setBannerData({ ...bannerData, [key]: value });
  };

  const isValid = () => {
    const { name, url, images = {} } = bannerData;
    const desktop = images.default;
    const { mobile } = images;
    const errorData = {};

    if (!name?.trim()) errorData.name = t('nameRequired');

    if (!mobile) {
      errorData.image = t('pleaseUploadImage', {
        device: t('phone').toLowerCase(),
      });
      setDevice(TABS.PHONE);
    }

    if (!desktop && !errorData.image) {
      errorData.image = t('pleaseUploadImage', {
        device: t('desktop').toLowerCase(),
      });
      setDevice(TABS.WEBSITE);
    }

    if (url?.trim() && !regexUrl.test(url)) errorData.url = t('urlIsInvalid');

    if (Object.keys(errorData).length) {
      setError(errorData);
      return false;
    }
    return true;
  };

  const handleUploadImage = async (file) => {
    try {
      if (!file) return '';
      if (typeof file === 'string') {
        return file;
      }
      const presignedUrl = await uploadBase64Image(file);
      if (presignedUrl) {
        return presignedUrl;
      }
      return null;
    } catch (e) {
      enqueueSnackbar(t(e.message), { variant: 'error' });
      return null;
    }
  };

  const updateData = () => {
    const data = getChangedFields(bannerDataApi, bannerData, ['images']);

    const { images } = bannerData;
    const { images: imagesApi } = bannerDataApi;

    if (
      images.default !== imagesApi.default ||
      images.mobile !== imagesApi.mobile
    )
      data.images = images;

    return data;
  };

  const handleSave = async () => {
    if (!isValid()) return;

    try {
      setLoading(true);

      let response;
      const { name, url, images = {} } = bannerData;
      const desktop = images.default;
      const { mobile } = images;

      if (bannerId) {
        const data = updateData();

        if (!Object.keys(data)?.length) {
          enqueueSnackbar(t('noChangeData'), { variant: 'warning' });
          return;
        }

        if (data?.images)
          data.images = {
            default: await handleUploadImage(desktop),
            mobile: await handleUploadImage(mobile),
          };

        response = await apis.banner.updateBanner(bannerId, data);
      } else {
        const data = {
          name,
          images: {
            default: await handleUploadImage(desktop),
            mobile: await handleUploadImage(mobile),
          },
          url,
          pageKey,
        };

        response = await apis.banner.createBanner(data);
      }

      if (!response?.status) throw new Error();

      enqueueSnackbar(
        t(bannerId ? 'updateBannerSuccess' : 'createBannerSuccess'),
        { variant: 'success' },
      );
      handleCloseCreatePopup();
      onChangeSearchBlockName('');
      onChangeType('');
      fetchBlocks();
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeDevice = (event, newValue) => {
    setError({ ...error, image: '' });
    setDevice(newValue);
  };

  const handleChangeImage = (value) => {
    setError({ ...error, image: '' });
    setBannerData({
      ...bannerData,
      images: {
        ...bannerData.images,
        [device === TABS.PHONE ? 'mobile' : 'default']: value,
      },
    });
  };

  const fetchBanner = async () => {
    try {
      setLoading(true);
      const response = await apis.banner.getBanner(bannerId);
      if (!response?.status) throw new Error();
      const { name = '', images = {}, url = '' } = response?.result || {};
      setBannerData({
        name,
        images,
        url,
      });
      setBannerDataApi({
        name,
        images,
        url,
      });
      setLoading(false);
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (open && bannerId) {
      fetchBanner();
    }
  }, [open]);

  return (
    <StyledCreateGroupDialog
      className="dialog"
      open={open}
      onClose={handleCloseCreatePopup}
    >
      <DialogTitle className="dialog-title">{t('createBanner')}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography className="label-input" variant="h6">
            {t('bannerName')}
            <span>*</span>
          </Typography>
          <TextField
            autoFocus
            size="small"
            value={bannerData.name}
            onChange={(e) => onChangeBannerData('name', e.target.value)}
            fullWidth
            error={!!error.name}
            helperText={error.name}
            placeholder={t('inputBannerName')}
          />
        </Box>
        <Tabs value={device} onChange={handleChangeDevice} className="tabs">
          {tabs.map((tab, index) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={index}
              className="tab"
            />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <div key={tab.type} role="tabpanel" hidden={device !== index}>
            <Box
              display={device === index ? 'inherit' : 'none'}
              className="tab-panel"
            >
              <FileDropzone
                fileType={IMAGE_TYPE_FILE}
                acceptFile={IMAGE_ACCEPT_FILE}
                fileUrl={
                  device === TABS.PHONE
                    ? bannerData?.images?.mobile
                    : bannerData?.images?.default
                }
                onAddFile={handleChangeImage}
                onDeleteFile={handleChangeImage}
                maxSize={maxSize * 1024 * 1024}
                minWidth={minWidth}
                maxWidth={maxWidth}
                minHeight={minHeight}
                maxHeight={maxHeight}
                width="552px"
                noteContent={
                  <Typography variant="body2" width="440px">
                    {t('imageBannerDesc', {
                      types: IMAGE_TYPE_FILE.map((type) => type.toUpperCase())
                        .join(', ')
                        .replace(/,([^,]*)$/, ' hoặc$1'),
                      maxSize,
                      minWidth,
                      minHeight,
                      maxWidth,
                      maxHeight,
                    })}
                  </Typography>
                }
                isError={error.image}
                // disabled={disabled}
              />
            </Box>
          </div>
        ))}
        <Box>
          <Typography className="label-input" variant="h6">
            {t('urlSupport')}
            <Tooltip title={t('urlSupportDesc')} placement="top" arrow>
              <HelpRounded fontSize="small" />
            </Tooltip>
          </Typography>
          <TextField
            size="small"
            value={bannerData.url}
            onChange={(e) => onChangeBannerData('url', e.target.value)}
            fullWidth
            error={!!error.url}
            helperText={error.url}
            placeholder={t('inputUrl')}
          />
        </Box>
        <Grid item xs={12} className="action">
          <Button
            onClick={handleCloseCreatePopup}
            variant="outlined"
            className="button"
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleSave}
            autoFocus
            variant="contained"
            className="button"
            disabled={
              !!(loading || (bannerId && !Object.keys(updateData())?.length))
            }
          >
            {bannerId ? t('save') : t('create')}
          </Button>
        </Grid>
      </DialogContent>
    </StyledCreateGroupDialog>
  );
};

export default CreateBanner;
