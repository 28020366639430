import React, { useRef, useState, useEffect } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { regexNumber } from '@src/constants/regex';
import { StyledTextField } from './index.style';

const CustomNumberInput = ({
  value = '',
  onChange,
  error,
  helperText,
  placeholder,
  isHideIcon = false,
  isAllowNegative = false,
}) => {
  const [number, setNumber] = useState(value);

  const inputRef = useRef(null);

  const increase = () => {
    const inputValue = Number(number || 0) + 1;
    setNumber(inputValue);
    onChange(inputValue);
  };

  const decrease = () => {
    const inputValue = number > 0 || isAllowNegative ? number - 1 : 0;
    setNumber(inputValue);
    onChange(inputValue);
  };

  const handleChange = (event) => {
    const { value: inputValue } = event.target;
    if (!regexNumber.test(inputValue)) return;
    setNumber(inputValue);
    onChange(inputValue);
  };

  useEffect(() => {
    if (value !== number) setNumber(value);
  }, [value]);

  return (
    <StyledTextField
      size="small"
      variant="outlined"
      fullWidth
      inputRef={inputRef}
      value={number}
      onChange={handleChange}
      placeholder={placeholder}
      InputProps={{
        endAdornment: !isHideIcon && (
          <InputAdornment position="start">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src="/icons/arrow-up.svg"
                className="icon-input"
                alt=""
                onClick={increase}
                width="8px"
              />
              <img
                src="/icons/arrow-down.svg"
                className="icon-input"
                alt=""
                onClick={decrease}
                width="8px"
              />
            </Box>
          </InputAdornment>
        ),
      }}
      error={error}
      helperText={helperText}
    />
  );
};

export default CustomNumberInput;
