import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomSearchInput from '@src/components/CustomInput/CustomSearchInput';
import { Delete, Save } from '@mui/icons-material';
import Popup from '@src/components/Popup';
import apis from '@src/apis';

const ActionRole = ({
  search,
  handleSearchChange,
  roleSelected,
  permissionsChange,
  setRoles,
  permissionSelectedDefault,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [showConfirmDeleteRole, setShowConfirmDeleteRole] = useState(false);

  const handleSave = async () => {
    try {
      const res = await apis.role.updateRolePermissions(roleSelected.id, {
        permissionIds: permissionsChange,
      });
      if (!res) throw new Error();
      setRoles((prev) => ({
        ...prev,
        data: prev.data.map((el) =>
          el.id === roleSelected.id
            ? { ...el, permissions: roleSelected.permissions }
            : el,
        ),
      }));
      enqueueSnackbar(t('updateSuccess'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('updateFail'), { variant: 'error' });
    }
  };

  const handleConfirmDeleteRole = async () => {
    try {
      const res = await apis.role.deleteRole(roleSelected.id);
      if (!res) throw new Error();
      enqueueSnackbar(t('deleteRoleSuccess'), { variant: 'success' });
      setShowConfirmDeleteRole(false);
      setRoles((prev) => ({
        ...prev,
        data: prev.data.filter((el) => el.id !== roleSelected.id),
        selected:
          prev.data.length > 1
            ? prev.data.filter((el) => el.id !== roleSelected.id)[0]
            : {},
      }));
    } catch (error) {
      enqueueSnackbar(t('deleteRoleFail'), { variant: 'error' });
    }
  };

  const hasChangePermissionSelected =
    permissionsChange?.length !== permissionSelectedDefault?.length ||
    !permissionsChange?.every((el) =>
      permissionSelectedDefault?.some((item) => item === el),
    );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <CustomSearchInput
        value={search}
        onChange={handleSearchChange}
        placeholder={t('searchRole')}
      />
      {!roleSelected?.isMasterRole && (
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setShowConfirmDeleteRole(true)}
            disabled={!roleSelected?.id}
            startIcon={<Delete />}
          >
            {t('deleteRole')}
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!hasChangePermissionSelected}
            startIcon={<Save />}
          >
            {t('saveChange')}
          </Button>
        </Box>
      )}
      <Popup
        open={showConfirmDeleteRole}
        onClose={() => setShowConfirmDeleteRole(false)}
        onOk={handleConfirmDeleteRole}
        okMessage={t('deleteRole')}
        title={t('areYouSureDeleteRole', {
          role: roleSelected && roleSelected.name,
        })}
      />
    </Box>
  );
};

export default ActionRole;
