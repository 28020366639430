import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import CustomPasswordInput from '@src/components/CustomInput/CustomPasswordInput';
import { regexPassword } from '@src/constants/regex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { COLOR } from '@src/styles/color';
import { StyleCreateUser, StyledPopup } from './index.style';

const INIT_DATA = {
  password: '',
  confirmPassword: '',
};

const ChangePasswordPopup = (props) => {
  const { t } = useTranslation();
  const { open, onOk = () => {}, onClose = () => {}, name, email } = props;

  const [data, setData] = useState(INIT_DATA);
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    setData(INIT_DATA);
    setErrors({});
    onClose();
  };

  const handleChange = (e, field) => {
    const { value } = e.target;
    setData({ ...data, [field]: value });
    setErrors({ ...errors, [field]: '' });
  };

  const isValid = () => {
    const newErrors = {};
    const { password, confirmPassword } = data;
    if (!password) {
      newErrors.password = t('newPasswordRequired');
    }

    if (password && !regexPassword.test(password)) {
      newErrors.password = t('newPasswordInvalid');
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = t('confirmPasswordRequired');
    }

    if (confirmPassword && password !== confirmPassword) {
      newErrors.confirmPassword = t('passwordNotMatch');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleOk = () => {
    if (!isValid()) return;
    onOk(data);
    handleClose();
  };

  return (
    <StyledPopup
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Typography variant="h3">{t('editPassword')}</Typography>
      <DialogContent className="dialog-content">
        {(name || email) && (
          <StyleCreateUser gridTemplateColumns="repeat(2, 1fr)">
            {name && (
              <TextField
                variant="outlined"
                fullWidth
                className="textfield-input"
                value={name}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon
                        icon={faUser}
                        color={COLOR.darkBlue[100]}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {email && (
              <TextField
                variant="outlined"
                fullWidth
                className="textfield-input"
                placeholder={t('email')}
                value={email}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        color={COLOR.darkBlue[100]}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </StyleCreateUser>
        )}

        <Typography variant="h6">
          {t('newPassword')}
          <span>*</span>
        </Typography>
        <CustomPasswordInput
          value={data.password}
          onChange={(e) => handleChange(e, 'password')}
          error={!!errors?.password}
          helperText={t(errors.password)}
          placeholder={t('password')}
        />
        <Typography variant="h6">
          {t('confirmPassword')}
          <span>*</span>
        </Typography>
        <CustomPasswordInput
          value={data.confirmPassword}
          onChange={(e) => handleChange(e, 'confirmPassword')}
          error={!!errors?.confirmPassword}
          helperText={t(errors.confirmPassword)}
          placeholder={t('confirmPassword')}
        />
      </DialogContent>
      <DialogActions className="dialog-action">
        <Button onClick={handleClose} variant="outlined" className="button">
          {t('cancel')}
        </Button>
        <Button onClick={handleOk} variant="contained" className="button">
          {t('save')}
        </Button>
      </DialogActions>
    </StyledPopup>
  );
};

export default ChangePasswordPopup;
